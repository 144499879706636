import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { useCreateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useCreateResource';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { useCallback, useMemo } from 'react';

const page = { size: 1 };

export const useTransferOwnership = (fromUser: string, toUser: string) => {
  const [workspaceId] = useCurrentWorkspace();

  const projects = useResourceList__ALPHA({
    resourceType: 'workspace',
    resourceId: workspaceId,
    edgeType: 'project',
    edgeLabel: 'projects',
    params: {
      filter: { last_admin: fromUser },
      page
    }
  });

  const shareLinks = useResourceList__ALPHA({
    resourceType: 'workspace',
    resourceId: workspaceId,
    edgeType: 'share',
    edgeLabel: 'shares',
    params: {
      filter: { owner: fromUser },
      page
    }
  });

  const reviewLinks = useResourceList__ALPHA({
    resourceType: 'workspace',
    resourceId: workspaceId,
    edgeType: 'share',
    edgeLabel: 'shares',
    params: {
      filter: { owner: fromUser, intent: 'review' },
      page
    }
  });

  const fileRequests = useResourceList__ALPHA({
    resourceType: 'workspace',
    resourceId: workspaceId,
    edgeType: 'share',
    edgeLabel: 'shares',
    params: {
      filter: { owner: fromUser, intent: 'request' },
      page
    }
  });
  const projectsCount = projects.pageCounts?.itemCount || 0;
  const shareLinksCount = shareLinks.pageCounts?.itemCount || 0;
  const reviewLinksCount = reviewLinks.pageCounts?.itemCount || 0;
  const fileRequestsCount = fileRequests.pageCounts?.itemCount || 0;
  const hasAnyShares =
    shareLinksCount > 0 || reviewLinksCount > 0 || fileRequestsCount > 0;

  const cacheInvalidator = useCallback(
    () => [
      {
        type: 'workspace' as const,
        id: workspaceId,
        relationship: 'projects'
      },
      {
        type: 'workspace' as const,
        id: workspaceId,
        relationship: 'shares'
      }
    ],
    [workspaceId]
  );
  const [transferProjects, transferProjectsStatus, transferProjectsError] =
    useCreateResource__ALPHA({
      resourceType: 'transfer-ownership',
      ancestor: { type: 'project', id: '' },
      cacheInvalidator
    });
  const [transferShares, transferSharesStatus, transferSharesError] =
    useCreateResource__ALPHA({
      resourceType: 'transfer-ownership',
      ancestor: { type: 'share', id: '' },
      cacheInvalidator
    });

  const onTransferOwnnership = useCallback(() => {
    if (!fromUser || !toUser) return;
    const config = {
      type: 'transfer-ownership' as const,
      relationships: {
        from: {
          data: {
            type: 'account',
            id: fromUser
          }
        },
        to: {
          data: {
            type: 'account',
            id: toUser
          }
        }
      }
    };
    if (projectsCount > 0) {
      transferProjects(config);
    }
    if (hasAnyShares) {
      transferShares(config);
    }
  }, [
    fromUser,
    toUser,
    projectsCount,
    hasAnyShares,
    transferProjects,
    transferShares
  ]);

  const loading =
    projects.loading ||
    shareLinks.loading ||
    reviewLinks.loading ||
    fileRequests.loading;

  return useMemo(() => {
    return {
      onTransferOwnnership,
      projectsCount,
      shareLinksCount,
      reviewLinksCount,
      fileRequestsCount,
      hasAnyShares,
      transferProjectsStatus,
      transferProjectsError,
      transferSharesStatus,
      transferSharesError,
      loading
    };
  }, [
    onTransferOwnnership,
    projectsCount,
    shareLinksCount,
    reviewLinksCount,
    fileRequestsCount,
    transferProjectsStatus,
    transferProjectsError,
    transferSharesStatus,
    transferSharesError,
    loading
  ]);
};
