import { Related, ResourceBase } from '../../resource-types';
import { RawModel } from '../schema';

export interface TransferOwnership extends ResourceBase {
  relationships: {
    from: Related<'account'>;
    to: Related<'account'>;
  };
}

export const transferOwnership: RawModel = {
  type: 'transfer-ownership',
  plural: 'transfer-ownership',
  useWorker: true,
  JSONApi: true,
  attributes: {
    id: 'UUID'
  },
  relationships: {
    from: {
      type: 'account',
      binding: ['one', 'many'],
      reverse: 'transfer-ownership'
    },
    to: {
      type: 'account',
      binding: ['one', 'many'],
      reverse: 'transfer-ownership'
    }
  }
};
