import React, { useCallback } from 'react';
//
import { Slider } from '@material-ui/core';
//

import { useStyles } from './VideoPreviewControls';
import { Tooltip } from '@cube3/cubicle/src/core/atoms/Tooltip/Tooltip';
import { Button } from '@cube3/cubicle/src/core/atoms/Button/Button';

interface Props {
  muted: boolean;
  volume: number;
  toggleMute(): void;
  setVolume(value: number): void;
  shortCutTip?: string;
}

export const VolumeControls: React.FC<Props> = React.memo((props) => {
  const { muted, volume, toggleMute, setVolume, shortCutTip } = props;

  const classes = useStyles(props);
  const handleClick = useCallback(() => toggleMute(), [toggleMute]);

  return (
    <>
      {muted || volume === 0 ? (
        <Tooltip
          content={shortCutTip && `Unmute (${shortCutTip})`}
          placement="bottom"
          useArrow={false}
        >
          <Button
            aria-label={'unmute'}
            buttonStyle="ghost"
            background="secondary"
            iconLeft={'volume_none'}
            onClick={handleClick}
          />
        </Tooltip>
      ) : (
        <Tooltip
          content={shortCutTip && `Mute (${shortCutTip})`}
          placement="bottom"
          useArrow={false}
        >
          <Button
            aria-label={'mute'}
            buttonStyle="ghost"
            background="secondary"
            iconLeft={volume < 50 ? 'volume_down' : 'volume_up'}
            onClick={handleClick}
          />
        </Tooltip>
      )}

      <Slider
        onChange={(event: React.ChangeEvent<{}>, value: any) => {
          setVolume(value);
        }}
        classes={{
          root: classes.volumeSlider,
          track: classes.sliderTrack,
          thumb: classes.thumb,
          rail: classes.rail
        }}
        value={muted ? 0 : volume}
      />
    </>
  );
});
