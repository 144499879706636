import React from 'react';
import {
  shortCutZoomIn,
  shortCutZoomOut,
  shortCutZoomReset
} from './hooks/useImagePreviewKeyboardShortCuts';
import { PanZoomContext } from './PanZoomContext';
import { makeTip } from './ImagePreviewControls';
import { Tooltip } from '@cube3/cubicle/src/core/atoms/Tooltip/Tooltip';
import { Button } from '@cube3/cubicle/src/core/atoms/Button/Button';

export const ZoomButtons = () => {
  const { zoomIn, zoomOut, zoomReset, canZoomIn, canZoomOut, canFitZoom } =
    React.useContext(PanZoomContext);

  return (
    <>
      <Tooltip content={makeTip(shortCutZoomOut)} placement="bottom">
        <Button
          aria-label="zoom out"
          iconLeft={'zoom_out'}
          background="secondary"
          buttonStyle="ghost"
          disabled={!canZoomOut}
          onClick={zoomOut}
        />
      </Tooltip>
      <Tooltip content={makeTip(shortCutZoomIn)} placement="bottom">
        <Button
          aria-label="zoom in"
          iconLeft={'zoom_in'}
          background="secondary"
          buttonStyle="ghost"
          disabled={!canZoomIn}
          onClick={zoomIn}
        />
      </Tooltip>
      <Tooltip content={makeTip(shortCutZoomReset)} placement="bottom">
        <Button
          aria-label="zoom reset"
          iconLeft={'zoom_reset'}
          background="secondary"
          buttonStyle="ghost"
          disabled={!canFitZoom}
          onClick={zoomReset}
        />
      </Tooltip>
    </>
  );
};
