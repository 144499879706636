import { createStyles, Theme, makeStyles } from '@material-ui/core';

import React, { useRef } from 'react';
import { isIOS, isMobile } from 'react-device-detect';
import Image from '../../images/Image';
import DetailviewFullscreenControls from '../DetailviewFullscreenControls';
import { PreviewHOCProps } from '../PreviewWrapper';
import ImagePreviewControls from './ImagePreviewControls';
import PanZoom from './PanZoom';
import { PanZoomProvider } from './PanZoomContext';
import { Typography } from '@cube3/cubicle/src/core/atoms/Typography/Typography';
import { SizeVariants } from '@cube3/cubicle/src/theme/themes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flex: '1',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      height: '100%',
      width: '100%'
    },
    containerMobile: {
      alignSelf: 'center',
      width: '100%'
    },
    controls: {
      zIndex: 2
    },
    viewport: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0
    },
    title: {
      height: '20px',
      left: '28px',
      right: '28px',
      top: '21px',
      color: theme.customPalette.primary.contrastColor,
      position: 'absolute',
      zIndex: 1,
      ...theme.typographyStyles.heading2,
      letterSpacing: '1px'
    }
  })
);

interface ImagePreviewProps extends PreviewHOCProps {
  id: string;
  asset_variant: string;
  name: string;
  display_name: string;
  src: string;
  thumbnail?: string;
  dimensions?: {
    width: number;
    height: number;
  };
  gotoNextSibling?: () => void;
  gotoPrevSibling?: () => void;
  siblings: boolean;
}

const ImagePreview: React.FC<ImagePreviewProps> = (props) => {
  const mainContainerRef = useRef<HTMLDivElement>();
  const {
    src,
    thumbnail,
    dimensions,
    toggleFullscreenAction,
    fullscreen,
    gotoNextSibling,
    gotoPrevSibling,
    display_name,
    siblings
  } = props;

  const classes = useStyles();

  return isMobile ? (
    <div
      onClick={() => !isIOS && toggleFullscreenAction()}
      className={fullscreen ? classes.containerMobile : ''}
    >
      {fullscreen && (
        <div className={classes.title}>
          <Typography
            weight="label"
            variant={SizeVariants.lg}
            colorVariant="01"
            color="dark"
          >
            {display_name}
          </Typography>
        </div>
      )}
      <Image
        imagePath={src}
        background="checkerboard"
        placeholder={thumbnail}
        fullSize={true}
        floatingEffect={false}
      />
    </div>
  ) : (
    <div className={classes.container} ref={mainContainerRef}>
      <PanZoomProvider
        useWheel={true}
        key={fullscreen ? 'fullscreen' : 'regular'}
      >
        <div className={classes.viewport}>
          <PanZoom
            offset={{ bottom: '4rem', right: 0, left: 0, top: 0 }}
            data-testid="image-preview"
          >
            <Image
              imagePath={src}
              background="checkerboard"
              placeholder={thumbnail}
              dimensions={dimensions}
              fullSize={true}
              floatingEffect={fullscreen ? false : false}
            />
          </PanZoom>
        </div>
        <div className={classes.controls}>
          {
            <ImagePreviewControls
              mainContainerRef={mainContainerRef.current}
              toggleFullscreenAction={toggleFullscreenAction}
              fullscreen={fullscreen}
              // moving={moving}
              renderFullscreenControls={() => (
                <DetailviewFullscreenControls
                  gotoNextSibling={gotoNextSibling}
                  gotoPrevSibling={gotoPrevSibling}
                  assetTitle={display_name}
                  siblings={siblings}
                />
              )}
            />
          }
        </div>
      </PanZoomProvider>
    </div>
  );
};

export default ImagePreview;
