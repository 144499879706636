import { ApiEndpoint, AuthService } from './new-client';
import { AxiosRequestConfig, AxiosAdapter } from 'axios';

import { RequestMaker } from './request-maker';
import { buildMapping } from './build-endpoint-mapping';
import { mergeInterceptors } from './interceptors/mergeInterceptors';
import { JSONApiInterceptors } from './interceptors/JSONApi-interceptors';
import { apiRoot } from '../config';
import { acceptCheckinterceptors } from './interceptors/acceptCheckInterceptors';
import { schema } from '@cube3/common/model/schema';
import { sentryInterceptor } from './interceptors/sentryInterceptor';
import { webWorkerInterceptor } from './interceptors/webWorkerInterceptor';
import { preventTagCategorySlowdownInterceptor } from './interceptors/preventTagCategorySlowdownInterceptor';
// import { retryInterceptors } from './retryInterceptors';

interface FactoryConfig extends AxiosRequestConfig {
  apiRoot: string;
  adapter?: AxiosAdapter;
}

const factory = ({ apiRoot, ...config }: FactoryConfig) => {
  // map normalized rest calls to the Method names of the generated clients\
  const defaults = {
    apiRoot,
    ...config
  };

  const { ...endpointDefaults } = defaults;

  const authService = new AuthService({
    ...defaults,
    endpoints: {
      login: 'auth/login',
      logout: 'auth/logout',
      register: '/auth/registration',
      legacyToken: '/auth/tokens/user-hash/',
      activeSessions: 'sessions',
      magicLink: 'auth/magic-link'
    }
  });
  const { interceptors: authInterceptors } = authService;

  const mergedInterceptors = mergeInterceptors(
    [
      preventTagCategorySlowdownInterceptor,
      authInterceptors,
      JSONApiInterceptors,
      acceptCheckinterceptors,
      sentryInterceptor,
      webWorkerInterceptor
      // retryInterceptors
    ].filter(Boolean)
  );

  const endpoints = schema.resources
    .map((r) => ({
      key: r.type,
      config: {
        noEndpoint: r.noEndpoint,
        JSONApi: r.JSONApi,
        useWorker: r.useWorker,
        canonicalPath: r.canonicalPath || r.plural
      }
    }))
    .reduce((acc, val) => {
      if (val.config.noEndpoint) {
        return acc;
      }
      acc[val.key] = new ApiEndpoint({
        ...endpointDefaults,
        ...mergedInterceptors,
        ...val.config
      });
      return acc;
    }, {});

  const mapping = buildMapping(authService, endpoints);

  return new RequestMaker(mapping);
};

export const defaultConfig = {
  apiRoot
};

const defaultClient = factory(defaultConfig);

// for use in testing
declare global {
  interface Window {
    cubeClient: RequestMaker;
  }
}
window.cubeClient = defaultClient;

export { factory };
export default defaultClient;
