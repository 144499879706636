import React from 'react';
import { iconFiles } from './files';
import { makeCSS } from '../../../utils/makeCSS';
import { cx } from '@emotion/css';
import { SizeVariants, Theme } from '../../../theme/themes';
import { Color } from 'csstype';
import { stackColors } from '../../../theme/utils/stackColors';

/**The icons that can be used inside Cube. Those icons can have different sizes and different colors. The colors are changing according to the theme that the user has enable. */

export interface IconProps {
  /**Name of the SVG icon: */
  icon?: keyof typeof iconFiles;
  /**Different sizes that the icon can have */
  size?:
    | SizeVariants.xs
    | SizeVariants.sm
    | SizeVariants.md
    | SizeVariants.lg
    | SizeVariants.xl
    | (typeof SizeVariants)['2xl']
    | (typeof SizeVariants)['3xl']
    | (typeof SizeVariants)['4xl']
    | (typeof SizeVariants)['5xl']
    | (typeof SizeVariants)['6xl']
    | (typeof SizeVariants)['7xl'];

  /**The color of the icons */
  color?: 'default' | 'light' | 'dark' | 'warning' | 'error';
  colorVariant?: '01' | '02' | '03';
  /**If we want to use a different color */
  customColor?: Color[] | Color | ((theme: Theme) => Color | Color[]);
}

const useCSS = makeCSS(
  ({
    theme,
    colorVariant = '01',
    customColor
  }: IconProps & { theme: Theme }) => {
    const unit = parseInt(theme.spacing['0,5']);
    return {
      root: {
        display: 'inline-block',
        flexShrink: 0,
        lineHeight: '1em',
        verticalAlign: 'middle',
        fill: ''
      },
      'size-xs': {
        width: theme.spacing[3],
        height: theme.spacing[3]
      },
      'size-sm': {
        width: theme.spacing[4],
        height: theme.spacing[4]
      },
      'size-md': {
        width: theme.spacing[5],
        height: theme.spacing[5]
      },
      'size-lg': {
        width: theme.spacing[6],
        height: theme.spacing[6]
      },
      'size-xl': {
        width: theme.spacing[7],
        height: theme.spacing[7]
      },
      'size-2xl': {
        width: unit * 17,
        height: unit * 17
      },
      'size-3xl': {
        width: theme.spacing[10],
        height: theme.spacing[10]
      },
      'size-4xl': {
        width: theme.spacing[12],
        height: theme.spacing[12]
      },
      'size-5xl': {
        width: theme.spacing[14],
        height: theme.spacing[14]
      },
      'size-6xl': {
        width: theme.spacing[16],
        height: theme.spacing[16]
      },
      'size-7xl': {
        width: theme.spacing[20],
        height: theme.spacing[80]
      },
      /**
       * Colors */
      'color-default': {
        fill: theme.color.icon[colorVariant]
      },
      'color-light': {
        fill: theme.color.icon.light[colorVariant]
      },
      'color-dark': {
        fill: theme.color.icon.dark[colorVariant]
      },
      'color-warning': {
        fill: theme.color.background.warning
      },
      'color-error': {
        fill: theme.color.background.critical
      },
      customColor: {
        fill: stackColors(
          (typeof customColor === 'function'
            ? customColor(theme)
            : customColor) || theme.color.background.gray.main
        )
      }
    };
  }
);

/** We have the logic for this component,implemented in GeneralSVG, but it should be rewritten  */
export const Icon = (props: IconProps) => {
  const {
    icon: name,
    size = SizeVariants.lg,
    color = 'default',
    customColor = ''
  } = props;

  const classes = useCSS(props);

  const Icon = iconFiles[name];
  if (!Icon) {
    return null;
  }
  return (
    <Icon
      className={cx(
        classes.root,
        classes[`size-${size}`],
        customColor != '' ? classes.customColor : classes[`color-${color}`]
      )}
      focusable={false}
    />
  );
};

/** @tokenString: looks like: `color.background.gray.main` */
export const getThemeColor = (tokenString: string) => (theme) => {
  if (!tokenString) return '';
  const tokens = tokenString.split('.');
  return tokens.reduce((acc, key) => acc[key], theme);
};
