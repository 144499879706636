import { Recipient } from '@cube3/common/model/schema/resources/recipient';
import { Share } from '@cube3/common/model/schema/resources/share';
import { Button } from '@cube3/cubicle/src/core/atoms/Button';
import { Divider } from '@cube3/cubicle/src/core/atoms/Divider/Divider';
import { InputField } from '@cube3/cubicle/src/core/atoms/InputField/InputField';
import { Select } from '@cube3/cubicle/src/core/atoms/Select/Select';
import { Typography } from '@cube3/cubicle/src/core/atoms/Typography';
import { ContextMenuListItem } from '@cube3/cubicle/src/core/molecules/ContextMenu/ContextMenuListItem';
import {
  Free,
  HBox,
  Item,
  VBox
} from '@cube3/cubicle/src/core/templates/layout/Flex';
import { useGlobalSubmit } from '@cube3/cubicle/src/helpers/hooks/useGlobalSubmit';
import {
  CubicleFallbackProvider,
  FallbackModes
} from '@cube3/cubicle/src/utils/CubicleFallback';
import { useCurrentUser } from '@cube3/state/src/redux/components/Administration/withCurrentUserAccount';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { useMappedId__ALPHA } from '@cube3/state/src/redux/components/Hooks/useCreateResource';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { uuidv4 } from '@cube3/state/src/utils/uuid';
import { useModalContainer } from '@cube3/ui/src/Modal/ModalManagerUI';
import { ModalMenuUI } from '@cube3/ui/src/Modal/ModalMenuUI';
import { ModalNoticeUI } from '@cube3/ui/src/Modal/ModalNoticeUI';
import { usePreventDirtyClose } from '@cube3/ui/src/Prefabs/shareLink/Modals/LinkSettings/hooks/usePreventDirtyClose';
import React, { useCallback, useMemo, useRef } from 'react';
import { useModalActions } from '../Modals/modalActions';
import { useSharesForm } from './hooks/useSharesForm';
import { useShareSubmit } from './hooks/useShareSubmit';
import { CheckboxRow } from './prefabs/CheckboxRow';
import { DownloadPresetsPicker } from './prefabs/DownloadPresetsPicker';
import { ExpirationDatePicker } from './prefabs/ExpirationDatePicker';
import { PasswordRow } from './prefabs/PasswordRow';
import { RecipientsPicker } from './prefabs/RecipientsPicker';
import {
  intentMapping,
  ShareSettingsTitle
} from './prefabs/ShareSettingsTitle';

interface Props {
  modalContext: {
    shareId?: string;
    nodeId?: string;
    intent?: Share['intent'];
    first?: boolean;
  };
}

export const ShareSettingsModal = (props: Props) => {
  const {
    modalContext: { shareId, intent, nodeId, first }
  } = props;

  const { closeAllModals, openModal, previousModal, goBackModals } =
    useModalActions();

  const tempId = useRef(uuidv4()).current;
  const newShareId = useMappedId__ALPHA(tempId);
  const share = useResource__ALPHA({
    resourceType: 'share',
    resourceId: shareId || newShareId
  });

  const node = useResource__ALPHA({
    resourceType: 'content-tree-node',
    resourceId: nodeId || share.resource?.relationships.node.id
  });

  const [workspaceId] = useCurrentWorkspace();

  const params = useMemo(() => {
    return nodeId || share.resource?.relationships.node.id
      ? {
          filter: {
            asset_ids: nodeId || share.resource?.relationships.node.id
          }
        }
      : undefined;
  }, [nodeId, share]);

  const { resources: workspacePresets, loading: workspacePresetsLoading } =
    useResourceList__ALPHA({
      resourceId: workspaceId,
      resourceType: 'workspace',
      edgeType: 'render-preset',
      edgeLabel: 'render-presets',
      params
    });
  const { resources: customPresets, loading: customPresetsLoading } =
    useResourceList__ALPHA({
      edgeType: 'render-preset',
      edgeLabel: 'render-presets',
      params
    });

  const renderPresets = useMemo(() => {
    return workspacePresets && customPresets
      ? [...workspacePresets, ...customPresets]
      : [];
  }, [workspacePresets, customPresets]);

  const { fieldProps, values, valid, dirty, errors } = useSharesForm({
    share: share.resource,
    intent: intent || share.resource?.intent,
    renderPresets,
    node: node.resource
  });

  const {
    commitShare,
    createShareInProgress,
    createShareStatus,
    mutateShareStatus
  } = useShareSubmit({ fieldProps, shareId, tempId, nodeId, intent });

  const handleDeleteClick = useCallback(() => {
    openModal('sharelink_delete', { shareId });
  }, [openModal, shareId]);

  const handleCancel = useCallback(() => {
    if (first) {
      goBackModals(2);
    } else {
      previousModal();
    }
  }, [first, goBackModals, previousModal]);

  React.useEffect(() => {
    // when we create a share we will recieve updates on createShareStatus
    // when successfull, go to next page.
    if (createShareStatus === statuses.SUCCESS && share.first) {
      openModal('confirm_link_sent', {
        token: share.first.token,
        password: fieldProps['set_password'].value
          ? fieldProps['password'].value
          : undefined,
        intent: intent
      });
    }
  }, [createShareStatus, intent]);

  React.useEffect(() => {
    // when we mutate a share we will recieve updates on mutateShareStatus
    // when successfull, go to next page.
    if (mutateShareStatus === statuses.SUCCESS) {
      closeAllModals();
    }
  }, [mutateShareStatus]);

  const { extraModalProps, promptElement } = usePreventDirtyClose({
    dirty,
    handleCloseEvent: handleCancel,
    promptMessage: `This ${intentMapping[intent]} has not been ${
      shareId ? 'saved' : 'created'
    }. Closing will discard current changes.`,
    promptTitle: `Cancel ${intentMapping[intent]} ${
      shareId ? 'modification' : 'creation?'
    }`
  });

  const loading =
    workspacePresetsLoading ||
    customPresetsLoading ||
    (shareId && !share.resource) ||
    (nodeId && !node.resource) ||
    createShareInProgress;

  const submitDisabled = !valid;
  const disabledCheck = React.useCallback(() => {
    return (
      submitDisabled ||
      loading ||
      (document.activeElement.tagName === 'INPUT' &&
        (document.activeElement.ariaAutoComplete === 'list' ||
          !!(document.activeElement as HTMLInputElement)?.dataset
            ?.preventSubmitWhenFocused)) // this is the downshift email suggestions dropdown
    );
  }, []);

  useGlobalSubmit(commitShare, disabledCheck);

  const container = useModalContainer();

  const handleShowLinkClick = useCallback(() => {
    openModal('confirm_link_sent', {
      token: share.resource.token,
      password: undefined,
      intent: intent,
      notNew: true
    });
  }, [intent, share]);

  const [, user] = useCurrentUser();
  const ownEmail = useMemo(() => {
    return [
      {
        email_address: user.email_address,
        display_name: user.full_name,
        id: user.id
      } as Recipient
    ];
  }, [user]);

  if (!container) {
    return null;
  }

  if (
    createShareStatus === statuses.FAILED ||
    mutateShareStatus === statuses.FAILED
  ) {
    return (
      <ModalNoticeUI
        title="Error saving Share"
        footerRightComponent={<Button onClick={closeAllModals}>Close</Button>}
      >
        <Typography color="warning">
          Share could not be saved... please try again later
        </Typography>
      </ModalNoticeUI>
    );
  }

  if (!shareId && !nodeId) {
    return (
      <ModalNoticeUI
        title="Share settings unavailable"
        footerRightComponent={<Button onClick={closeAllModals}>Close</Button>}
      >
        <Typography color="warning">
          Nothing has been selected. Select a Folder or Asset to share.
        </Typography>
      </ModalNoticeUI>
    );
  }

  return (
    <CubicleFallbackProvider initialFallbackMode={FallbackModes.NEVER_FALLBACK}>
      <ModalMenuUI
        extraModalProps={extraModalProps}
        title={
          (
            <ShareSettingsTitle
              intent={share.resource?.intent || intent}
              action={shareId ? 'edit' : 'create'}
            />
          ) as unknown as string
        }
        loading={loading}
        multiplyContentSpace={10}
        onCloseEvent={handleCancel}
        footerLeftComponent={
          shareId && (
            <HBox gap={8}>
              <Button
                onClick={handleDeleteClick}
                background="critical"
                buttonStyle="outline"
                disabled={loading}
              >
                Delete
              </Button>
              <Button
                onClick={handleShowLinkClick}
                background="secondary"
                buttonStyle="outline"
                disabled={loading}
              >
                Show link
              </Button>
            </HBox>
          )
        }
        footerRightComponent={
          <HBox gap={8}>
            <Button
              onClick={handleCancel}
              background="secondary"
              buttonStyle="outline"
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              onClick={shareId && !dirty ? handleCancel : commitShare}
              disabled={submitDisabled || loading}
              background="primary"
              buttonStyle="solid"
            >
              {!shareId ? 'Send' : dirty ? 'Save' : 'Done'}
            </Button>
          </HBox>
        }
      >
        {/* <Panel
        padding={4}
        style={{
          width: 800,
          height: 'auto',
          maxHeight: `calc(100vh - 36px)`,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 1,
          overflow: 'hidden'
        }}
        backgroundColor={(theme) =>
          stackColors([
            theme.color.background.neutral,
            theme.color.overlay['01']
          ])
        }
      > */}

        {(!shareId || share.resource) && (
          <VBox gap={16} style={{ marginBottom: 24 }}>
            {/* <Divider /> */}

            <Free>
              <VBox gap={16}>
                {fieldProps['display_name'] && (
                  <>
                    <ShareSettingSection title={'Share as'}>
                      <VBox gap={16}>
                        <Item>
                          <InputField
                            fullwidth={true}
                            messageColor="critical"
                            messageLabel={
                              fieldProps['display_name'].errors?.[0]?.message
                            }
                            multiline={1}
                            messageIcon="error"
                            label="Title..."
                            showMessage={
                              !!fieldProps['display_name'].errors?.length &&
                              fieldProps['display_name'].touched
                            }
                            value={fieldProps['display_name'].value}
                            onChange={fieldProps['display_name'].onChange}
                          />
                        </Item>
                      </VBox>
                    </ShareSettingSection>
                    <Divider />
                  </>
                )}

                <ShareSettingSection
                  title={'Who can access'}
                  dividerBottom={true}
                  dividerTop={false}
                >
                  <VBox gap={16}>
                    <Item>
                      <Select
                        value={fieldProps['who_can_access'].value}
                        onChange={fieldProps['who_can_access'].onChange}
                      >
                        <ContextMenuListItem
                          value="everyone"
                          name="Anyone with this link"
                          leftIcon="link"
                          showChevron={false}
                        />
                        <ContextMenuListItem
                          value="recipients_only"
                          name="Only invited people"
                          leftIcon="lock"
                          showChevron={false}
                        />
                      </Select>
                    </Item>

                    {fieldProps['require_verification'] && (
                      <CheckboxRow {...fieldProps['require_verification']} />
                    )}

                    {fieldProps['recipients'] && (
                      <Item>
                        <RecipientsPicker
                          value={fieldProps['recipients'].value}
                          onChange={fieldProps['recipients'].onChange}
                          locked={ownEmail}
                        />
                      </Item>
                    )}

                    {fieldProps['add_message'] &&
                      fieldProps['add_message']?.value !== true && (
                        <Item>
                          <Button
                            onClick={() =>
                              fieldProps['add_message'].onChange(
                                !fieldProps['add_message'].value
                              )
                            }
                            buttonStyle="outline"
                            background="secondary"
                            iconLeft="add"
                          >
                            Add Message
                          </Button>
                        </Item>
                      )}

                    {fieldProps['message'] && (
                      <Item>
                        <InputField
                          label={'Email message...'}
                          fullwidth={true}
                          messageColor="critical"
                          messageLabel={
                            fieldProps['message'].errors?.[0]?.message
                          }
                          messageIcon="error"
                          showMessage={
                            !!fieldProps['message'].errors?.length &&
                            fieldProps['message'].touched
                          }
                          showCount={false}
                          multiline={4}
                          value={fieldProps['message'].value}
                          onChange={fieldProps['message'].onChange}
                          iconRight="close"
                          onRightIconClick={() => {
                            fieldProps['message'].onChange(undefined);
                            fieldProps['add_message'].onChange(false);
                          }}
                        />
                      </Item>
                    )}
                  </VBox>
                </ShareSettingSection>
                <Divider />

                <ShareSettingSection
                  title={'Additional security'}
                  dividerBottom={true}
                >
                  <VBox gap={16}>
                    <CheckboxRow {...fieldProps['set_password']} />

                    {fieldProps['password'] && (
                      <PasswordRow {...fieldProps['password']} />
                    )}

                    <CheckboxRow {...fieldProps['expires']} />

                    {fieldProps['expires_at'] && (
                      <ExpirationDatePicker
                        value={fieldProps['expires_at'].value}
                        onChange={fieldProps['expires_at'].onChange}
                      />
                    )}
                  </VBox>
                </ShareSettingSection>

                {intent === 'share' && (
                  <>
                    <Divider />
                    <ShareSettingSection
                      title={'Options'}
                      dividerBottom={false}
                    >
                      <VBox gap={16}>
                        {fieldProps['watermark_videos'] && (
                          <CheckboxRow {...fieldProps['watermark_videos']} />
                        )}
                        <CheckboxRow {...fieldProps['allow_downloads']} />

                        {fieldProps['download_presets'] && (
                          <Item>
                            <DownloadPresetsPicker
                              share={share.resource}
                              watermark={fieldProps['watermark_videos']?.value}
                              value={fieldProps['download_presets'].value}
                              onChange={fieldProps['download_presets'].onChange}
                            />
                          </Item>
                        )}
                      </VBox>
                    </ShareSettingSection>
                  </>
                )}
              </VBox>
            </Free>

            {/* <Divider /> */}
          </VBox>
        )}

        {/* </Panel> */}
      </ModalMenuUI>
      {promptElement}
    </CubicleFallbackProvider>
  );
};

const ShareSettingSection = (props) => {
  const { title } = props;
  return (
    <section>
      <VBox gap={16}>
        <Typography variant="md" weight="label">
          {title}
        </Typography>
        <Free>{props.children}</Free>
      </VBox>
    </section>
  );
};
