import React, { useCallback, useState } from 'react';
import { WithAnnotationPlaybackSliderProps } from './PlaybackSlider';
import { useGroupReviewComments } from './hooks/useGroupReviewComments';
import { AvatarList } from '../../Workspace/subComponents/AvatarList';
import { Comment } from '@cube3/common/model/schema/resources/comment';
import { Grow, Slide, makeStyles } from '@material-ui/core';
import { Avatar } from '@cube3/cubicle/src/core/atoms/Avatar/Avatar';
import { SizeVariants } from '@cube3/cubicle/src/theme/themes';
import { Typography } from '@cube3/cubicle/src/core/atoms/Typography/Typography';
import Time from '@cube3/cubicle/src/core/utility/Time';

const useStyles = makeStyles((theme) => ({
  counter: {
    background: '#565656',
    borderColor: '#565656',
    fontWeight: 600,
    position: 'relative'
  },
  popUp: {
    position: 'absolute',
    background: 'rgb(28, 28, 28)', // same color as drawing bar
    border: `1px solid ${theme.customPalette.primary.lineWhiteLowEmphasis}`,
    borderRadius: 16,
    padding: 8
  },
  comment: {
    height: 20,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 8,
    padding: '8px',
    whiteSpace: 'nowrap',
    '& > :last-child': {
      paddingLeft: 16
    },
    '&:hover': {
      background: theme.customPalette.backgroundAccent.whiteAccent1
    }
  },
  active: {
    background: 'rgba(255,255,255,0.08)'
  }
}));

interface Props extends WithAnnotationPlaybackSliderProps {
  duration: number;
  sliderWidth: number;
  frameRate?: number;
}
export const CommentsGroup: React.FC<Props> = (props) => {
  const {
    comments,
    duration,
    sliderWidth,
    frameRate = 25,
    onClickComment
  } = props;

  const [activeGroup, setActiveGroup] = useState(undefined);
  const [toggle, setToggle] = useState(false);

  const groupedComments = useGroupReviewComments(comments, 1 / frameRate);
  const classes = useStyles();

  const onClickCounter = useCallback((start: string) => {
    return () => {
      setToggle((prev) => !prev);
      setActiveGroup(start);
    };
  }, []);

  const onClickUser = useCallback(
    (start: string) => {
      if (groupedComments[start].length === 1) {
        onClickComment(groupedComments[start][0]); // set active comment
      } else {
        setToggle((prev) => !prev);
      }
      setActiveGroup(start);
    },
    [groupedComments, onClickComment]
  );

  return (
    <React.Fragment data-pw="playback-slider-comments">
      {duration && groupedComments && Object.keys(groupedComments).length ? (
        <>
          {Object.keys(groupedComments).map((start, idx) => (
            <Grow appear={true} enter={true} key={start} in={true}>
              <div
                key={start}
                style={{
                  cursor: 'pointer',
                  position: 'absolute',
                  top: '-20px',
                  left: (Number(start) / duration) * sliderWidth - 10
                }}
                data-pw={`playback-slider-comment-at-${start}`}
              >
                <AvatarList
                  key={start + idx}
                  users={groupedComments[start].map((c: Comment) => ({
                    full_name: c.author_name
                  }))}
                  maxCount={1}
                  withPlus={false}
                  disableTooltip={true}
                  counterStyles={classes.counter}
                  onClickUser={(e) => {
                    e.stopPropagation();
                    onClickUser(start);
                  }}
                  onClickCounter={onClickCounter(start)}
                />
                {/** overflow comments */}
                {activeGroup === start &&
                toggle &&
                groupedComments[start].length > 1 ? (
                  <div
                    className={classes.popUp}
                    style={{
                      left: '-16px',
                      top: -36 * (groupedComments[start].length || 1) - 28
                    }}
                  >
                    {groupedComments[start].map((c: Comment) => (
                      <div
                        key={c.id}
                        className={classes.comment}
                        onClick={(e) => {
                          e.stopPropagation();
                          onClickComment(c);
                        }}
                      >
                        <Avatar
                          size={SizeVariants.sm}
                          userInitial={c.author_name}
                          tooltip={c.author_name}
                        />
                        <div style={{ width: 4 }}></div>
                        <Typography colorVariant="01" color="dark">
                          {c.author_name}
                        </Typography>
                        <Typography colorVariant="03">
                          <Time
                            date={new Date(c.created_at)}
                            noTime={true}
                            relativeTime={true}
                            toolTip={true}
                            placement="top"
                          />
                        </Typography>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            </Grow>
          ))}
        </>
      ) : null}
    </React.Fragment>
  );
};
