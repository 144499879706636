import Button from '../Buttons/Button';
import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { Typography } from '../typography/Typography';
import TruncatedText from '../human/Truncate';
import { Avatar } from '@cube3/cubicle/src/core/atoms/Avatar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    row: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '10px 8px',
      '&:hover': {
        backgroundColor: theme.customPalette.surfaceAccent.onBase.hover1,
        borderRadius: theme.surfaceCorners.mediumEmphasis
      }
    },
    memberInfo: {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
      '& > div': {
        paddingRight: 10
      }
    },
    name: { flexGrow: 1 },
    email: { marginLeft: '10px' }
  })
);

export const ManageTeamMemberRow = ({ member, onClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.row} key={member.id}>
      <div className={classes.memberInfo}>
        <Avatar
          userInitial={member.full_name}
          imageUrl={member.profile_picture}
        />
        <Typography typographyStyle={'body2'} className={classes.name}>
          <TruncatedText
            str={member.full_name}
            suffixComponent={
              <Typography
                color="contrast2"
                typographyStyle={'body2'}
                className={classes.email}
              >
                {member.email_address}
              </Typography>
            }
          />
        </Typography>
      </div>

      <Button text="Remove" colorVariant="danger" onClick={onClick(member)} />
    </div>
  );
};
