import React from 'react';

import {
  createStyles,
  WithStyles,
  withStyles,
  Theme,
  makeStyles
} from '@material-ui/core';

import Image from '../../images/Image';
import fileImage from '../../assets/images/Library/file.svg?url';
import Surface from '../../Surface/Surface';
import DetailviewFullscreenControls from '../DetailviewFullscreenControls';
// Fallback component for obscure filetypes in detailview
import { PreviewHOCProps } from '../PreviewWrapper';
import { Typography } from '@cube3/cubicle/src/core/atoms/Typography/Typography';
import { Thumbnail } from '@cube3/cubicle/src/core/atoms/Thumbnail/Thumbnail';
import { SizeVariants } from '@cube3/cubicle/src/theme/themes';
import { FullscreenButton } from '../VideoPreview/FullscreenButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      height: '100%',
      display: 'flex',
      backgroundColor: 'unset'
    },
    centered: {
      display: 'flex',
      margin: 'auto',
      flexFlow: 'column nowrap',
      alignItems: 'center'
    },
    feedbackTxt: {
      marginTop: '20px',
      margin: 'auto'
    },
    fullscreenButton: {
      position: 'absolute',
      bottom: 20,
      right: 24
    }
  })
);

interface ComponentProps extends PreviewHOCProps {
  id: string;
  asset_variant: string;
  name: string;
  display_name: string;
  src: string;
  siblings: boolean;
  gotoNextSibling?: () => void;
  gotoPrevSibling?: () => void;
  thumbnail?: string;
  status?: string;
  loading?: boolean;
}

const getMessage = (status) => {
  switch (status) {
    case 'composing':
    case 'processing':
      return 'No preview available yet.';

    default:
      return 'No preview available.';
  }
};
export const FallbackPreview = (props: ComponentProps) => {
  const {
    thumbnail,
    fullscreen,
    toggleFullscreenAction,
    display_name,
    gotoNextSibling,
    gotoPrevSibling,
    siblings,
    loading,
    status
  } = props;

  const message = getMessage(status);

  const classes = useStyles();

  return (
    <Surface className={classes.container}>
      <div className={classes.centered}>
        {!loading && thumbnail ? (
          <Thumbnail src={thumbnail} size={SizeVariants.lg} />
        ) : (
          <Image imagePath={fileImage} />
        )}
        {!loading && (
          <div className={classes.feedbackTxt}>
            <Typography>{message}</Typography>
          </div>
        )}
        {fullscreen && (
          <FullscreenButton
            fullscreen={fullscreen}
            toggleFullscreenAction={toggleFullscreenAction}
          />
        )}
        {fullscreen && (
          <DetailviewFullscreenControls
            gotoNextSibling={gotoNextSibling}
            gotoPrevSibling={gotoPrevSibling}
            assetTitle={display_name}
            siblings={siblings}
          />
        )}
      </div>
    </Surface>
  );
};
