import React from 'react';
import { isMobile } from 'react-device-detect';

// UI
import { createStyles, Theme, makeStyles } from '@material-ui/core';
import { Divider } from '@cube3/cubicle/src/core/atoms/Divider/Divider';
import { Scrollbar } from '@cube3/cubicle/src/core/atoms/Scrollbar/Scrollbar';
import Loader from '@cube3/cubicle/src/core/atoms/Loader/Loader';

/// @Author: Simon @AmbassadorsLab (original component made by michiel)
/// <Summary>
/// Template layout component for detailview.
/// </Summary>

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mobile: {},
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%'
    },
    commandBar: {
      display: 'flex',
      padding: '8px 16px',
      alignItems: 'center',
      boxSizing: 'border-box',
      width: '100%',
      height: '64px'
    },
    contents: {
      display: 'flex',
      height: '100%',
      margin: ({ disableContentMargin }: Props) =>
        disableContentMargin ? 0 : 24,
      '$mobile &': {
        margin: theme.customSpacing.margin[16]
      }
    },
    loading: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%'
    },
    noMetadata: {
      margin: 24
    }
  })
);

interface Props {
  loading: boolean;
  renderPropsContents: () => JSX.Element;
  renderPropsCommandBar?: () => JSX.Element;
  disableContentMargin?: boolean;
}

const DetailViewTabTemplate: React.ComponentType<Props> = (props) => {
  const { renderPropsContents, renderPropsCommandBar, loading } = props;

  const classes = useStyles(props);

  return (
    <div className={`${classes.root} ${isMobile ? classes.mobile : ''}`}>
      {renderPropsCommandBar && (
        <>
          <div className={classes.commandBar}>{renderPropsCommandBar()}</div>
          <Divider color="line3" />
        </>
      )}
      {loading ? (
        <div className={classes.loading}>
          <Loader />
        </div>
      ) : (
        <div className={classes.contents}>
          <Scrollbar>{renderPropsContents()}</Scrollbar>
        </div>
      )}
    </div>
  );
};

export default DetailViewTabTemplate;
