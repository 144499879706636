import * as React from 'react';

import { FileRequestItem } from '../SubComponents/FileRequestItemSmall';

// ui
import { FileRequestOverviewModalUI } from '@cube3/ui/src/Prefabs/FileRequests/Modals/FileRequestOverviewModalUI';
import { ModalReceiverProps } from '../../../layout/Modals/ModalManager';

// helper class
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import { ModalNoticeUI } from '@cube3/ui/src/Modal/ModalNoticeUI';
import { useModalActions } from '../../../layout/Modals/modalActions';
import { useCurrentAccount } from '@cube3/state/src/redux/components/Administration/withCurrentUserAccount';

interface PublicProps {}

type Properties = PublicProps & ModalReceiverProps;

function FileRequestOverviewModal(props: Properties): JSX.Element {
  const modalActions = useModalActions();
  const [tookLong, setTookLong] = React.useState(false);
  const isProject = props.modalContext.type === 'project';

  const project = useResource__ALPHA({
    resourceType: isProject ? 'project' : undefined,
    resourceId: props.modalContext.id
  });

  const id = isProject
    ? project.resource?.relationships.node.id
    : props.modalContext.id;
  const type = isProject ? 'folder' : props.modalContext.type;

  const [accountId] = useCurrentAccount(true);
  const retrievedFilerequests = useResourceList__ALPHA(
    React.useMemo(
      () => ({
        resourceType: type,
        resourceId: id,
        edgeType: 'share',
        edgeLabel: 'shares',
        strategy: 'fetch-on-mount',
        params: {
          filter: {
            owner: accountId,
            intent: 'request'
          }
        }
      }),
      [type, id, accountId]
    )
  );

  const loading =
    retrievedFilerequests.status &&
    retrievedFilerequests.status !== statuses.SUCCESS &&
    retrievedFilerequests.status !== statuses.FAILED;

  /** @first: create the first file-request for this folder */
  const openFileRequestNewModal = React.useCallback(
    (first = false) => {
      modalActions.openModal('ShareSettings', {
        intent: 'request',
        nodeId: id,
        first
      });
    },
    [id, modalActions]
  );

  React.useEffect(() => {
    if (
      retrievedFilerequests.status === statuses.SUCCESS &&
      retrievedFilerequests.resources?.length === 0
    ) {
      openFileRequestNewModal(true);
    }
  }, [retrievedFilerequests, openFileRequestNewModal]);

  React.useEffect(() => {
    if (loading) {
      const timeout = setTimeout(() => {
        setTookLong(true);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [loading, setTookLong]);

  return retrievedFilerequests.resources?.length > 0 ? (
    <FileRequestOverviewModalUI
      loading={loading || !id}
      onClickNewFileRequest={openFileRequestNewModal} // pass the folder id (root of the file request)
      onCloseEvent={() => modalActions.previousModal()}
    >
      {retrievedFilerequests.resources?.map((item) => {
        return (
          <div key={item.id}>
            <FileRequestItem request={item} />
          </div>
        );
      })}
    </FileRequestOverviewModalUI>
  ) : tookLong ? (
    <ModalNoticeUI loading={true}>
      <div style={{ height: 60 }} />
    </ModalNoticeUI>
  ) : null;
}

/** Retrieves file requests for the passed folder and displays them in a modal and dispays a 'new file request button' */
export { FileRequestOverviewModal };
