import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';

import { Typography } from '../../typography/Typography';
import Thumbnail from '../../thumbnails/Thumbnail';
import { ContractTileUI } from '../../Prefabs/contracts/ContractTileUI';
import { getStatusColor } from '../../StatusBlip/getStatusColor';
import { Avatar } from '@cube3/cubicle/src/core/atoms/Avatar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    suggestionTextContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      flexGrow: 1,
      marginLeft: 12
    },
    thumbnail: {
      width: 48,
      flexGrow: 0,
      overflow: 'hidden'
    }
  })
);

interface ResourceSuggestionItemProps {
  resource: any;
  primaryText: string;
  secondaryText?: string;
  imageUrl: string;
  disabledText?: string;
  imageType?: 'human' | 'asset';
}

export const ResourceSuggestionItem: React.FunctionComponent<
  ResourceSuggestionItemProps
> = (props) => {
  const {
    primaryText,
    secondaryText,
    imageUrl,
    disabledText,
    imageType,
    resource
  } = props;
  const classes = useStyles({});
  const { suggestionTextContainer } = classes;

  const isHuman = imageType ? imageType === 'human' : resource.type === 'user';
  const isAsset = imageType
    ? imageType === 'asset'
    : resource.type === 'asset' ||
      resource.type === 'project' ||
      resource.type === 'folder';

  const TypeComponent = components[resource?.type];

  if (TypeComponent) {
    return <TypeComponent resource={resource} disabledText={disabledText} />;
  }

  return (
    <>
      {isHuman && (
        <Avatar userInitial={primaryText} hideTooltip imageUrl={imageUrl} />
      )}
      {isAsset && (
        <div className={classes.thumbnail}>
          <Thumbnail
            showCheckerboard={false}
            ratio="fourToThree"
            cover={true}
            src={imageUrl}
          />
        </div>
      )}
      <div className={suggestionTextContainer}>
        <Typography color="contrast1" typographyStyle={'body2'}>
          {primaryText}
        </Typography>
        {secondaryText && (
          <Typography color="contrast2" typographyStyle={'body2'}>
            {secondaryText}
          </Typography>
        )}
      </div>
      {disabledText && (
        <Typography color="contrast2" typographyStyle={'body2'}>
          {disabledText}
        </Typography>
      )}
    </>
  );
};

const ContractSuggestion = (props) => {
  const { resource: contract } = props;

  return (
    <ContractTileUI
      name={contract.display_name}
      type={contract.__contractType}
      showAttachments={false}
      statusColor={getStatusColor(contract)}
      showDate={true}
      startsDate={contract.starts_at}
      expiresDate={contract.expires_at}
    />
  );
};

const components = {
  contract: ContractSuggestion
};
