import React, { useCallback, useMemo } from 'react';
import { compose } from '../../../../../../../utils/component-helpers';
import { withResource } from '@cube3/state/src/redux/components/withResource';
import { Account } from '@cube3/common/model/schema/resources/account';
import { Workspace } from '@cube3/common/model/schema/resources/workspace';
import { User } from '@cube3/common/model/schema/resources/user';
import { Role } from '@cube3/common/model/schema/resources/role';
import { useModalActions } from '../../../../Modals/modalActions';

// ui
import WorkspaceMemberRow from '@cube3/ui/src/Workspace/WorkSpaceMemberRow';
import { withCurrentAccount } from '@cube3/state/src/redux/components/Administration/account';
import { useProjectMembershipsList } from '@cube3/state/src/redux/components/Hooks/useProjectMembershipsList';
import withCurrentWorkspace from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import {
  MenuItemsRenderer,
  MenuItemType
} from '@cube3/ui/src/Workspace/subComponents/MenuItemsRenderer';
import { withResourceEdges } from '@cube3/state/src/redux/components/withResourceEdges';

interface WorkspaceMembersRowSmartProps {
  id: string;
  workspace: Workspace;
  account?: Account;
  role?: Role;
  disableEdit: boolean;
  loading: boolean;
  roleId: string;
  userId: string;
  user: User;
  currentAccountId: string;
  roles: Role[];
}

function WorkspaceMembersRowSmart(props: WorkspaceMembersRowSmartProps) {
  const {
    disableEdit,
    account,
    role = { display_name: 'Member' },
    workspace,
    loading,
    user,
    currentAccountId,
    roles
  } = props;
  const modalActions = useModalActions();

  const isCurrentAccount = account.id === currentAccountId;

  /** Available projects in this workspace:
   * - Requester is a Project Admin in that project
   * - Project is not deleted
   */
  const { count: allProjectsCount } = useProjectMembershipsList({
    accountId: currentAccountId,
    projectRole: 'Admin',
    projectStatus: 'All',
    countOnly: true
  });

  const disableAddToProjects = allProjectsCount === 0;

  const handleChangeWorkspaceRole = useCallback(() => {
    modalActions.openModal('workspaceMembers/changeWorkspaceRole', {
      account: account,
      user: user,
      roles: roles,
      currentRole: role,
      isCurrentAccount: isCurrentAccount
    });
  }, [account, role, roles, isCurrentAccount, modalActions, user]);

  const handleAddToProjects = useCallback(() => {
    modalActions.openModal('workspaceMembers/addToProjects', {
      userName: user.full_name,

      workspaceId: workspace.id,
      accountId: account.id
    });
  }, [modalActions, user, workspace, account]);

  const handleRemoveWorkspaceMember = useCallback(() => {
    modalActions.openModal('workspaceMembers/removeMemberPrompt', {
      accountId: account.id
    });
  }, [account.id, user, modalActions]);

  const menuItems: MenuItemType[] = useMemo(
    () => [
      {
        disableMenu: disableEdit,
        disableTooltip: !disableEdit,
        tooltip: `You are not allowed to change ${
          isCurrentAccount ? 'your' : "this person's"
        }  workspace role.`,
        text: 'Change workspace role...',
        onClick: handleChangeWorkspaceRole
      },
      {
        hidden: isCurrentAccount,
        disableTooltip: !disableAddToProjects,
        disableMenu: disableAddToProjects || isCurrentAccount,
        tooltip:
          'This option is not available because you’re not an admin in any projects',
        text: 'Add to all projects',
        onClick: handleAddToProjects
      },
      {
        hidden: disableEdit,
        disableTooltip: true,
        disableMenu: disableEdit,
        text: isCurrentAccount ? 'Leave' : 'Remove',
        colorVariant: 'danger',
        onClick: handleRemoveWorkspaceMember
      }
    ],
    [
      disableAddToProjects,
      disableEdit,
      handleAddToProjects,
      handleChangeWorkspaceRole,
      handleRemoveWorkspaceMember,
      isCurrentAccount
    ]
  );

  return (
    <WorkspaceMemberRow
      emailAddress={user?.email_address}
      full_name={user && user.full_name}
      display_image={user && user.profile_picture}
      display_name={user && user.full_name}
      role={role.display_name}
      workspaceName={workspace.display_name}
      loading={loading}
      isCurrentAccount={isCurrentAccount}
      roles={roles}
      menuItems={<MenuItemsRenderer menuItems={menuItems} />}
    />
  );
}

export default compose(WorkspaceMembersRowSmart)(
  withCurrentAccount,
  withResource({
    resourceType: 'account',
    resourceId: (props: WorkspaceMembersRowSmartProps) => props.id,
    mapper: 'account'
    // strategy: 'fetch-on-mount'
  }),
  withResource({
    resourceType: 'user',
    resourceId: (props: WorkspaceMembersRowSmartProps) => props.userId,
    mapper: 'user'
    // strategy: 'fetch-on-mount'
  }),
  withResource({
    resourceType: 'role',
    resourceId: (props: WorkspaceMembersRowSmartProps) => props.roleId,
    mapper: 'role'
    // strategy: 'fetch-on-mount'
  }),
  withCurrentWorkspace,
  withResourceEdges({
    resourceType: 'workspace',
    resourceId: ({ currentWorkspaceId }) => currentWorkspaceId,
    edgeType: 'role',
    edgeLabel: 'roles',
    mapper: 'roles'
  })
);
