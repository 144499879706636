import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';

//UI
import { Typography } from '../typography/Typography';
import Cube3Button from '../Buttons/Cube3Button';
import Time from '../human/Time';
import { Avatar } from '@cube3/cubicle/src/core/atoms/Avatar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    userRow: {
      display: 'flex',
      alignItems: 'center',
      borderBottom: `1px solid ${theme.customPalette.line.onBase1.contrast2}`,
      height: '54px'
    },
    userDetails: {
      margin: '0px 22px 0px 16px',
      width: '190px',
      flexGrow: 1
    },
    button: {
      marginRight: theme.customSpacing.margin[16]
    },
    svg: {
      // marginRight: 5
    }
  })
);

interface WorkspacePendingInvitesRowProps {
  displayImage: string;
  fullName: string;
  invitationDate: string;
  emailAddress: string;
  revokeInvitation: Function;
  disableEdit: boolean;
}

function WorkspacePendingInvitesRow(props: WorkspacePendingInvitesRowProps) {
  const {
    fullName,
    displayImage,
    invitationDate,
    emailAddress,
    revokeInvitation,
    disableEdit
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.userRow}>
      <Avatar userInitial={fullName} imageUrl={displayImage} />
      <div className={classes.userDetails}>
        <Typography typographyStyle={'body2'}>{emailAddress}</Typography>
        <Typography color="contrast3" typographyStyle={'body2'}>
          Invited on{' '}
          <Time
            date={invitationDate}
            fullForm={false}
            toolTip={true}
            placement="bottom"
          />
        </Typography>
      </div>
      {/* <Cube3Button
        text="Resend invite"
        colorVariant="ghost1"
        variant="withIcon"
        pathLeftSvg={iconSend}
      /> */}

      {!disableEdit && (
        <Cube3Button
          text="Revoke"
          colorVariant="danger"
          marginCollapse="left"
          onClick={() => revokeInvitation()}
          className={classes.button}
          extraButtonProperties={{ 'data-cy': 'revokeInvitation' }}
        />
      )}
    </div>
  );
}

export default WorkspacePendingInvitesRow;
