import React, { useState, useEffect } from 'react';

export interface ScrollProperties {
  scrolled: boolean;
}

export const useDetectScroll = () => {
  const [scrolled, setScrolled] = useState(false);

  let timer;
  useEffect(() => {
    const setScroll = () => {
      setScrolled(true);
      clearTimeout(timer);
      timer = setTimeout(() => setScrolled(false), 150);
    };
    window.addEventListener('scroll', setScroll, true);
    return () => {
      window.removeEventListener('scroll', setScroll, true);
      clearTimeout(timer);
    };
  }, [timer]);
  return { scrolled, setScrolled };
};

/** Wrapper to use hook `UseDetectScroll` as a decorator*/
export const WithScrollWrapper =
  <P extends {}>(WrappedComponent: React.ComponentType<P>) =>
  (props: P) => {
    const { scrolled } = useDetectScroll();
    return <WrappedComponent {...props} scrolled={scrolled} />;
  };
