import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { ProjectActivityItemUI } from '@cube3/ui/src/Projects/SubComponents/ProjectActivityItemUI';
import { Activity } from '@cube3/common/model/schema/resources/activities';

import { ActivityItemLink } from './ActivityItemLink';
import { ActivityItemWithoutPath } from './ActivityItemWithoutPath';

import { getActivityImage } from '../utils/getActivityImage';
import { useActivityActor } from '../hooks/useActivityActor';
import { useActivitySubject } from '../hooks/useActivitySubject';
import { useActivityContext } from '../hooks/useActivityContext';

interface Properties {
  projectActivity: Activity;
  hideTargetLink?: boolean;
  onClickLink: () => void;
}

enum verbs {
  CREATED = 'CREATED',
  DELETED = 'DELETED',
  UPDATED = 'UPDATED',
  EXPORTSUCCESS = 'EXPORTSUCCESS',
  EXPORTFAILED = 'EXPORTFAILED',
  DOWNLOADED = 'DOWNLOADED'
}

const toCrud = (activity) => {
  for (const verb in verbs) {
    if (
      (activity.verb || activity.action)
        .toLowerCase()
        .includes(verb.toLowerCase())
    ) {
      return verb;
    }
  }
};

const toNiceSubject = (activity) => {
  return activity.relationships.subject.type;
};

const makeUnderTitlePrefix = (activity: Activity, actor: string): string => {
  switch (toCrud(activity)) {
    case verbs.CREATED:
      if (!actor) {
        return `This ${toNiceSubject(activity)} was created on `;
      }
      return `${actor} created this ${toNiceSubject(activity)} on `;

    case verbs.DELETED:
      if (!actor) {
        return `This ${toNiceSubject(activity)} was deleted on `;
      }
      return `${actor} deleted this ${toNiceSubject(activity)} on `;

    case verbs.UPDATED:
      if (!actor) {
        return `This ${toNiceSubject(activity)} was modified on `;
      }
      return `${actor} modified this ${toNiceSubject(activity)} on `;
    case verbs.EXPORTSUCCESS:
      if (!actor) {
        return `This ${toNiceSubject(activity)} was exported on `;
      }
      return `${actor} exported this ${toNiceSubject(
        activity
      )} successfully on `;
    case verbs.EXPORTFAILED:
      if (!actor) {
        return `This ${toNiceSubject(activity)} was exported on `;
      }
      return `${actor} failed to exported this ${toNiceSubject(activity)} on `;
    case verbs.DOWNLOADED:
      if (!actor) {
        return `This ${toNiceSubject(activity)} was downloaded on `;
      }
      return `${actor} downloaded this ${toNiceSubject(activity)} on `;
  }
};

const itemFromProps = (props) => {
  const { projectActivity, onClickLink } = props;
  switch (projectActivity.action) {
    case 'ExportSuccess':
    case 'ExportFailed':
    case 'AssetDownloaded':
      return (
        <ActivityItemWithoutPath {...{ projectActivity: projectActivity }} />
      );
    default:
      return (
        <ActivityItemLink
          {...{ projectActivity: projectActivity, onClickLink: onClickLink }}
        />
      );
  }
};

const isAlreadyVisiting = (pathname, subjectPath) => {
  return pathname.includes(subjectPath);
};

export const ActivityItem: React.FunctionComponent<Properties> = (props) => {
  const { projectActivity, hideTargetLink = false, onClickLink } = props;
  const history = useHistory();

  const {
    subject,
    actor,
    related_resources: context
  } = projectActivity.relationships;
  const { name: actorName } = useActivityActor(actor);
  const {
    name: subjectName,
    resource: subjectResource,
    pathname: subjectPath
  } = useActivitySubject(subject);

  const visiting = isAlreadyVisiting(history.location.pathname, subjectPath);

  const underTitle = makeUnderTitlePrefix(projectActivity, actorName);

  const component = hideTargetLink ? undefined : itemFromProps(props);

  const { resource: contextResource } = useActivityContext(context);

  const sourceImage = getActivityImage({
    resource: subjectResource || subject,
    context: contextResource || {},
    action: projectActivity.action
  });

  const visitSubject = useCallback(() => {
    history.push(subjectPath, { from: 'previous' });
    onClickLink();
  }, [onClickLink]);

  // TODO there is currently an issue with library items that are deleted, because their information cannot be retrieved with the current
  // api implementation. Also getting the deleted item resource is unforunately also not an option, because the id
  // of a deleted item is not the same as the id of an asset.

  return (
    <ProjectActivityItemUI
      occuredAt={projectActivity.created_at}
      sourceImage={sourceImage}
      subjectName={
        subjectName ||
        `A${subject.type[0].toLowerCase() === 'a' ? 'n' : ''} ${subject.type}`
      }
      underTitle={underTitle}
      onClickAsset={!visiting ? visitSubject : undefined}
      targetLinkComponent={component}
    />
  );
};
