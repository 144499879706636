import { Typography } from '@cube3/cubicle/src/core/atoms/Typography/Typography';
import React from 'react';

interface Props {
  playedTime: string;
  durationDateFormat: string;
}

export const PlaybackTimeDisplay: React.FC<Props> = React.memo((props) => {
  const { playedTime, durationDateFormat } = props;

  return (
    <>
      <Typography colorVariant="01">{playedTime}</Typography>
      <Typography colorVariant="01">/</Typography>
      <Typography colorVariant="01">{durationDateFormat}</Typography>
    </>
  );
});
