import React from 'react';
import Surface from '@cube3/ui/src/Surface/Surface';
import { useRouteMatch } from 'react-router-dom';
import { urlStructureProject } from '../../../routing/routingPaths';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { Padding } from './Padding';
import { useModalActions } from '../../Modals/modalActions';
import { Typography } from '@cube3/cubicle/src/core/atoms/Typography/Typography';

export interface ProjectUrlParams {
  projectId: string;
  workspaceId: string;
}

export const RelatedContractsNotice = () => {
  const { projectId } =
    useRouteMatch<ProjectUrlParams>(urlStructureProject)?.params || {};

  const contracts = useResourceList__ALPHA({
    resourceType: 'project',
    resourceId: projectId,
    edgeType: 'contract',
    edgeLabel: 'contracts'
  });

  const modalActions = useModalActions();

  if (!contracts.pageCounts?.itemCount) {
    return null;
  }

  return (
    <Padding>
      <Surface
        corners="rounded"
        cornerRadius="mediumEmphasis"
        background="transparent"
        border={true}
      >
        <Padding>
          <Typography colorVariant="02">
            {contracts.pageCounts.itemCount} contracts applied to the current
            project also{' '}
            {contracts.pageCounts.itemCount === 1 ? 'applies' : 'apply'} to this
            asset.
          </Typography>
          <div
            onClick={() =>
              modalActions.openModal('project_contracts', {
                type: 'project',
                id: projectId
              })
            }
            style={{ cursor: 'pointer' }}
          >
            <Typography textDecorator={true}>View project contracts</Typography>
          </div>
        </Padding>
      </Surface>
    </Padding>
  );
};
