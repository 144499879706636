import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core';
import { useClassName } from '../utils/useClassName';

interface MasterDetailProps {
  noticeElement?: React.ReactNode;
  listElement: React.ReactNode;
  detailElement: React.ReactNode;
  listHeaderElement?: React.ReactNode;
  classes?: {
    root?: string;
    master?: string;
    listHeader?: string;
    notice?: string;
    list?: string;
    detail?: string;
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    debug: {},
    root: {
      display: 'flex',
      flexFlow: 'row nowrap',
      height: '100%',
      width: '100%',
      '$debug &': {
        background: 'green'
      }
    },
    master: {
      display: 'flex',
      flexFlow: 'column nowrap',
      flexGrow: 0,
      flexShrink: 0,
      width: 360, // TODO get from theme
      '$debug &': {
        background: 'orange'
      },
      borderRight: `1px solid ${theme.customPalette.line.onBase1.contrast2}`,
      padding: 16
    },
    listHeader: {
      '$debug &': {
        background: 'teal'
      }
    },
    notice: {
      '$debug &': {
        background: 'purple'
      }
    },
    list: {
      flexGrow: 1,

      '$debug &': {
        background: 'blue'
      }
    },
    detail: {
      flexGrow: 1,
      '$debug &': {
        background: 'red'
      }
    }
  })
);

export const MasterDetail: React.FunctionComponent<MasterDetailProps> = (
  props
) => {
  const {
    listHeaderElement,
    noticeElement,
    listElement,
    detailElement,
    classes
  } = props;

  const classesLocal = useStyles(props);

  return (
    <div className={useClassName(classesLocal.root, classes && classes.root)}>
      <div className={classesLocal.master}>
        <header className={classesLocal.listHeader}>{listHeaderElement}</header>
        <aside className={classesLocal.notice}>{noticeElement}</aside>
        <nav
          className={useClassName(classesLocal.list, classes && classes.list)}
        >
          {listElement}
        </nav>
      </div>
      <div className={classesLocal.detail}>{detailElement}</div>
    </div>
  );
};
