import React from 'react';

import { IconButton } from '@material-ui/core';

import { UploadUIStates } from './types';
import { Icon } from '../Icon';
import { makeCSS } from '../../../utils/makeCSS';
import { Loader } from '../Loader/Loader';
import { SizeVariants } from '../../../theme/themes';

interface Props {
  uploadState: UploadUIStates;
  // eslint-disable-next-line:no-any
  progress?: number; // TODO consider removing upload because this object contains too much information for this simple component.
  gridview?: boolean;
  isHovered: boolean;
  onPopoverOpen(event: React.MouseEvent<HTMLButtonElement>): void;
  onPopoverClose(event: React.MouseEvent<HTMLButtonElement>): void;
}

const useCSS = makeCSS(({ theme }) => ({
  defaultText: {
    textAlign: 'left'
  },

  uploadTextError: {
    color: theme.color.foreground.critical,
    textAlign: 'left'
  },
  iconButton: {
    position: 'relative',
    zIndex: 1500,
    width: '30px',
    height: '30px',
    padding: 0,
    borderRadius: `${theme.borderRadius.full} !important` //`${theme.surfaceCorners.highEmphasis} !important`,
    // backgroundColor: theme.color.overlay.primary, // theme.customPalette.primary.main,
    // '&:hover': {
    //   backgroundColor: theme.color.overlay.primary //theme.customPalette.primary.main
    // }
  },
  error: {
    color: theme.color.foreground.critical // theme.customPalette.dangerError
  },
  warning: {
    color: theme.color.foreground.warning //theme.customPalette.warning
  }
}));

export const ProgressIconButton: React.FunctionComponent<Props> = (props) => {
  const {
    gridview,
    progress,
    uploadState,
    isHovered,
    onPopoverOpen,
    onPopoverClose
  } = props;

  const classes = useCSS(props);

  const processingFailed = uploadState === 'processing_failed' ? true : false;
  const uploadFailed =
    uploadState === 'upload_failed' ||
    uploadState === 'upload_initialization_failed'
      ? true
      : false;
  const uploadMissing = uploadState === 'upload_missing' ? true : false;

  switch (uploadState) {
    case 'processing_failed':
    case 'upload_initialization_failed':
    case 'upload_missing':
      return (
        <IconButton
          classes={{ root: classes.iconButton }}
          onClick={(e) => e.stopPropagation()}
          onDoubleClick={(e) => {
            if (uploadMissing || uploadFailed) {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
        >
          <Icon
            size={SizeVariants.md}
            icon="error"
            customColor={getIconColor(
              processingFailed ? 'warning' : 'critical'
            )}
          />
        </IconButton>
      );

    case 'upload_pending':
    case 'paused':
      return (
        <IconButton
          classes={{ root: classes.iconButton }}
          onMouseOver={(e) =>
            !gridview && (onPopoverOpen(e), e.stopPropagation())
          }
          onMouseLeave={(e) => !gridview && onPopoverClose(e)}
        >
          {isHovered ? (
            <Icon size={SizeVariants.md} icon="play" />
          ) : (
            <Loader size={SizeVariants.md} variant="determinate" />
          )}
        </IconButton>
      );

    case 'upload_failed':
      return (
        <IconButton
          classes={{ root: classes.iconButton }}
          onMouseOver={(e) =>
            !gridview && (onPopoverOpen(e), e.stopPropagation())
          }
          onMouseLeave={(e) => !gridview && onPopoverClose(e)}
        >
          {isHovered ? (
            <Icon size={SizeVariants.md} icon="refresh" />
          ) : (
            <Loader variant="determinate" />
          )}
        </IconButton>
      );

    default:
      return (
        <IconButton
          classes={{ root: classes.iconButton }}
          onMouseOver={(e) =>
            !gridview && (onPopoverOpen(e), e.stopPropagation())
          }
          onMouseLeave={(e) => !gridview && onPopoverClose(e)}
        >
          {isHovered && uploadState === 'uploading' ? (
            <Icon size={SizeVariants.md} icon="pause" />
          ) : (
            <Loader
              size={SizeVariants.md}
              value={uploadState === 'processing_pending' ? 100 : progress || 0}
              // because when uploading we want to show the uploadpgrogress we will set the variant
              // to static to show the progress value, but when in other states where we don't
              // have any backend progress yet (like processing), we will show the indeterminate state
              // to indicate the system is  working.
              variant={
                ['uploading', 'processing_pending', 'upload_pending'].indexOf(
                  uploadState
                ) > -1
                  ? 'determinate'
                  : 'indeterminate'
              }
            />
          )}
        </IconButton>
      );
  }
};

const getIconColor = (color) => (theme) => theme.color.background[color];
