import axios from 'axios';
import {
  actionCreators as sessionActionCreators,
  tokenScopeFromPath
} from '../../ducks/session';
import { actions as sessionActions } from '../../ducks/session';
import { actionCreators as reqActionCreators } from '../../ducks/request-status';
import { actionCreators as modalActionCreators } from '../../ducks/modals';

import { addFormId, formStates, hasFormId } from '../redux-form-middleware';

import Client, {
  hasError,
  LoginResponse,
  normalizePromise,
  RegisterResponse,
  Uploader
} from '../../../wrapped-cube-client';

import { serverErrorCodes as SERROR } from '../../../wrapped-cube-client';
import { createConsentSubscriber } from '../cookie-consent-middleware';
import { apiRoot } from '../../../config';
import { handleJSONApi } from './retrieve-middleware';

export const SHARELINK_PASSWORD_HEADER_MISSING =
  'SHARELINK_PASSWORD_HEADER_MISSING';
export const SHARELINK_PASSWORD_INCORRECT = 'SHARELINK_PASSWORD_INCORRECT';
export const SHARELINK_VERIFICATION_REQUIRED =
  'SHARELINK_VERIFICATION_REQUIRED';
export const SHARE_REQUEST_ACCESS = 'SHARE_REQUEST_ACCESS';
export const SHARE_NO_ACCESS = 'SHARE_NO_ACCESS';
export const SHARELINK_EXPIRED = 'SHARELINK_EXPIRED';

const magicLinkExp = /\/magic-link\/[^/]+/;
const shareLinkExp = /\/share-link\/[^/]+/;
const reviewLinkExp = /\/review(-link)?\/[^/]+/;
const fileRequestExp = /\/file-request\/[^/]+/;

export const createLoginMiddleware = ({ getState, dispatch }) => {
  let consent = false;

  const bc = new BroadcastChannel('CHANNEL:CUBE3/SESSION');
  let verifyAuthReq = null;

  bc.addEventListener('message', (ev) => {
    if (ev.origin === window.location.origin) {
      if (ev.data?.action === sessionActions.LOGOUT) {
        const userId = getState().session.user;
        if (userId === ev.data?.payload.userId) {
          Client.auth
            .getActiveSessions()
            .catch((e) => {
              console.error('Could not get active sessions');
              return null;
            })
            .then((res) => {
              const sessionId = res?.find((s) => s.meta.is_current)?.id;

              if (
                !ev.data?.payload.sessionId ||
                ev.data?.payload.sessionId === sessionId ||
                (userId && !sessionId)
              ) {
                dispatch(sessionActionCreators.reset({ allSessions: false }));

                Uploader.clearStorage();
              }
            });
        }
      }
    }
  });

  createConsentSubscriber(['functional'])(
    () => (consent = true),
    () => {
      consent = false;
      dispatch(sessionActionCreators.forget());
    }
  );

  Client.auth.handleAuthErrors({
    noAuth: () => {
      console.info('NO AUTHORIZATION');
      return dispatch(sessionActionCreators.verifyAuth());
    },
    noAccess: () => {
      console.info('NO ACCESS');
      return dispatch(sessionActionCreators.verifyAuth());
    },
    noBeta: () => {
      return dispatch(sessionActionCreators.logout({ allSessions: false }));
    }
  });

  return (next) => (action) => {
    const { meta, type, payload } = action;

    if (!meta || !meta.apiClient) {
      return next(action);
    } else {
      const params = meta.apiClient;

      const isMagicLink = magicLinkExp.test(window.location.pathname);
      const isShare = shareLinkExp.test(window.location.pathname);
      const isReview = reviewLinkExp.test(window.location.pathname);
      const isFileRequest = fileRequestExp.test(window.location.pathname);

      switch (type) {
        case sessionActions.SET_CURRENT_ACCOUNT:
          Uploader.clearStorage();
          Client.auth.setWorkspaceId(params.workspaceId);
          break;

        case sessionActions.LOGIN:
          if (getState().session.auth) {
            dispatch(sessionActionCreators.reset({ allSessions: false }));
          }

          dispatch(reqActionCreators.markInFlight(action));
          (
            Client.auth.login({
              creds: {
                username: payload.username,
                password: payload.password
              }
            }) as Promise<LoginResponse>
          )
            .then((res) => {
              dispatch(reqActionCreators.markSuccess(action));

              const successAction = sessionActionCreators.loginSuccess({
                userId: res.data.id_token,
                remember: consent && payload.remember,
                tokenScope: 'full'
              });

              return dispatch(
                hasFormId(action)
                  ? addFormId(action.meta.reduxForm.form, {
                      formState: formStates.SUCCESS
                    })(successAction)
                  : successAction
              );
            })
            .catch((err) => {
              let errors;
              switch (true) {
                case hasError(err, SERROR.LOGIN_ERROR):
                  errors = { _error: SERROR.LOGIN_ERROR };
                  break;
                case hasError(err, SERROR.LOGIN_ERROR_BETA):
                  errors = { _error: SERROR.LOGIN_ERROR_BETA };
                  break;
                default:
                  errors = { ...err };
                  break;
              }

              const failedAction = sessionActionCreators.loginFailed({
                errors: errors
              });

              dispatch(
                hasFormId(action)
                  ? addFormId(action.meta.reduxForm.form, {
                      formState: formStates.FAILED
                    })(failedAction)
                  : failedAction
              );
              dispatch(reqActionCreators.markFailed(action));
            });
          break;

        case sessionActions.LOGIN_MAGIC_LINK: {
          Client.auth
            .magicLinkLogin(action.meta.apiClient.email)
            .then((res) => {
              const successAction = sessionActionCreators.loginSuccess({
                userId: res.data.id_token,
                remember: false,
                tokenScope: tokenScopeFromPath(res.data.redirect_uri)
              });

              dispatch(successAction);
            })
            .catch((e) => console.error(e));
          break;
        }

        case sessionActions.ACTIVATE_MAGIC_LINK: {
          Client.auth
            .magicLinkActivate(action.meta.apiClient)
            .then((res) => {
              const startSession = () => {
                Client.auth.sso({ token: res.data.access_token });

                const sessionSuccessAction = sessionActionCreators.loginSuccess(
                  {
                    userId: res.data.id_token,
                    remember: false,
                    tokenScope: tokenScopeFromPath(res.data.redirect_uri)
                  }
                );
                dispatch(sessionSuccessAction);
                return new Promise((res) => setTimeout(res, 500));
              };
              const successAction = sessionActionCreators.magicLinkActivated({
                startSession,
                redirect_uri: res.data.redirect_uri
              });

              dispatch(successAction);
            })
            .catch((err) => {
              const faledAction = sessionActionCreators.magicLinkFailed({
                errors: err.message || 'Magic Link activation failed'
              });

              dispatch(faledAction);
            });
          break;
        }

        case sessionActions.REQUEST_SHARE_ACCESS: {
          Client.auth
            .requestShareAcccess({
              shareToken: action.meta.apiClient.shareToken,
              email: action.meta.apiClient.email,
              message: action.meta.apiClient.message
            })
            .catch((e) => {
              console.info('DEBUG error', e);

              e.response?.status === 409
                ? Promise.resolve()
                : Promise.reject(e);
            });
          break;
        }

        // single-sign-on request has already happened in an IFRAME,
        // now we just need to store the response
        case sessionActions.LOGIN_SSO: {
          Client.auth.sso({ token: action.meta.apiClient.token });

          const successAction = sessionActionCreators.loginSuccess({
            userId: action.meta.apiClient.userId,
            remember: consent && action.meta.apiClient.remember,
            tokenScope: 'full'
          });

          dispatch(
            hasFormId(action)
              ? addFormId(action.meta.reduxForm.form, {
                  formState: formStates.SUCCESS
                })(successAction)
              : successAction
          );

          break;
        }
        case sessionActions.TOKEN_LOGIN:
          dispatch(reqActionCreators.markInFlight(action));
          // first try with token
          Client.auth.setShareToken(payload.token);

          Client.list('share', {
            field: `shares/?filter[token]=${payload.token}`
          })
            .catch((err) => {
              // check password in share-link view
              const passwordError =
                hasError(err, SHARELINK_PASSWORD_HEADER_MISSING) ||
                hasError(err, SHARELINK_PASSWORD_INCORRECT);

              if (passwordError) {
                return Promise.reject(err);
              }

              const userVerficationError =
                hasError(err, SHARELINK_VERIFICATION_REQUIRED) ||
                hasError(err, SHARE_REQUEST_ACCESS) ||
                hasError(err, SHARE_NO_ACCESS);
              if (userVerficationError) {
                return Promise.reject(err);
              }

              // fallback to using auth from logged in  session
              Client.auth.setShareToken(undefined);
              return Client.list('share', {
                field: `shares/?filter[token]=${payload.token}`
              });
            })
            .then((res: any) => {
              handleJSONApi({
                res: res,
                dispatch: dispatch,
                config: {},
                action: action
              });
              dispatch(reqActionCreators.markSuccess(action));
              dispatch(
                sessionActionCreators.setCurrentSharelink(
                  res.data.primary[0].id
                )
              );
            })
            .catch((err) => {
              dispatch(reqActionCreators.markFailed(action, err));
            });

          break;

        case sessionActions.SET_SHARE_PASSWORD:
          Client.auth.setSharePassword(payload.password, payload.token);
          dispatch(
            sessionActionCreators.tokenLogin({
              token: payload.token as string
            })
          );

          break;

        case sessionActions.VERIFY_AUTH: {
          // only verify session if there is one set
          // ignore magiclinks, reviewlinks and filerequests for now
          // those can trigger 401 errors that are not indicative of a closed session, but just a session with a limited scope
          if (
            getState().session.auth &&
            !(isMagicLink || isReview || isFileRequest)
          ) {
            if (!verifyAuthReq) {
              verifyAuthReq = Client.auth.verify();
            }

            verifyAuthReq
              .then((s) => console.info('session ping'))
              .catch((e) => {
                dispatch(sessionActionCreators.reset({ allSessions: false }));
                setTimeout(() => {
                  // inside sharelinks,etc. we want to just reload the window to show the "magic-link" prompt instead of redirecting to the main login view
                  if (isShare || isReview || isFileRequest) {
                    window.location.reload();
                  } else {
                    dispatch(modalActionCreators.openModal('logged_out_alert'));
                  }
                }, 500);
              })
              .finally(() => {
                verifyAuthReq = undefined;
              });
          }
          return next(action);
        }
        case sessionActions.LOGOUT: {
          // if (
          //   // eslint-disable-next-line no-useless-escape
          //   window.location.pathname.match(/\/(?:share|review)(-link)?\/[^/]+/)
          // ) {
          //   // Don't log out users when they visit a sharelink that triggers 401, because that 401 is related to a different session
          //   return;
          // }

          const userId = getState().session?.user;

          if (getState().session.auth) {
            Client.auth
              .getActiveSessions()
              .catch((e) => {
                console.error('Could not get active sessions');
                return null;
              })
              .then((res) => {
                const sessionId = res?.find((s) => s.meta.is_current)?.id;
                // logout other windows as well
                bc.postMessage({
                  action: sessionActions.LOGOUT,
                  payload: {
                    userId: userId,
                    sessionId: action.payload.allSessions ? null : sessionId
                  }
                });

                dispatch(
                  sessionActionCreators.reset({
                    allSessions: action.payload.allSessions
                  })
                );
                Uploader.clearStorage();
              });
          }
          return next(action);
        }

        case sessionActions.RESET:
          Client.auth
            .logout(action.payload.allSessions)
            .catch((e) => console.error(e))
            .then(() => {
              Client.auth.clearStores();
              localStorage.clear(); // TODO find a way to save tus/uploader resumables data
              Uploader.clearStorage();
            });

          return next(action);

        case sessionActions.REGISTER_ACCOUNT:
          (
            Client.auth.registerAccount({
              ...params
            }) as Promise<RegisterResponse>
          )
            .then((res) => {
              const successAction = reqActionCreators.markSuccess(action);

              return dispatch(
                hasFormId(action)
                  ? addFormId(action.meta.reduxForm.form, {
                      formState: formStates.SUCCESS
                    })(successAction)
                  : successAction
              );
            })
            .catch((err) => {
              let errors;

              switch (true) {
                case hasError(err, SERROR.INVITE_TOKEN_INVALID_ERROR):
                  errors = { _error: SERROR.INVITE_TOKEN_INVALID_ERROR };
                  break;
                // case LOGIN_ERROR_NO_BETA:
                //   errors = { _error: LOGIN_ERROR_BETA };
                //   break;
                default:
                  errors = { ...err };
                  break;
              }

              const failedAction = reqActionCreators.markFailed(action, errors);

              dispatch(
                hasFormId(action)
                  ? addFormId(action.meta.reduxForm.form, {
                      formState: formStates.FAILED
                    })(failedAction)
                  : failedAction
              );
            });
          break;

        case sessionActions.REQUEST_TOKEN_RESET_PASSWORD:
          dispatch(reqActionCreators.markInFlight(action));
          axios
            .post(`${apiRoot}/forgot-password`, action.payload)
            .then((res) => {
              dispatch(reqActionCreators.markSuccess(action));
            })
            .catch((res) => {
              dispatch(reqActionCreators.markFailed(action));
            });
          break;

        case sessionActions.RESET_PASSWORD:
          dispatch(reqActionCreators.markInFlight(action));
          axios
            .put(`${apiRoot}/forgot-password`, action.payload)
            .then((res) => {
              dispatch(reqActionCreators.markSuccess(action));
            })
            .catch((res) => {
              dispatch(reqActionCreators.markFailed(action));
            });
          break;

        case sessionActions.CHANGE_EMAIL:
          dispatch(reqActionCreators.markInFlight(action));
          normalizePromise(
            Client.auth.request({
              method: 'POST',
              url: `/users/${action.meta.apiClient.userId}/update-email`,
              data: {
                email: action.payload.email,
                value: action.payload.email,
                password: action.payload.password
              }
            })
          )
            .then((res) => {
              dispatch(reqActionCreators.markSuccess(action));
            })
            .catch((res) => {
              dispatch(reqActionCreators.markFailed(action, res));
            });
          break;

        case sessionActions.CONFIRM_EMAIL:
          dispatch(reqActionCreators.markInFlight(action));
          normalizePromise(
            axios.request({
              method: 'POST',
              url: `${apiRoot}/confirm-email`,
              data: {
                token: action.payload.token
              }
            })
          )
            .then((res) => {
              dispatch(reqActionCreators.markSuccess(action));
            })
            .catch((res) => {
              dispatch(reqActionCreators.markFailed(action, res));
            });
          break;

        default:
          break;
      }
      return next(action);
    }
  };
};
