import { flip, offset, shift, arrow, computePosition } from '@floating-ui/dom';
import {
  Unstable_Popup as Popup,
  PopupContext
} from '@mui/base/Unstable_Popup';

import React, { useRef, useState } from 'react';
import { makeCSS } from '../../../utils/makeCSS';
import { Typography } from '../Typography';
import {
  FloatingArrow,
  useFloating,
  useFocus,
  useHover,
  useInteractions
} from '@floating-ui/react';

/**
 * A visual indicator that displays information about an element, such as a description, a time stamp or just
 * extra information.
 * The Tooltip has an option to display an arrow and can be placed in different positions around an element.
 */

type PlacementType =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';

export interface TooltipProps extends React.PropsWithChildren {
  /**The content that is displayed in the tooltip */
  content?: NonNullable<React.ReactNode>;
  /**The placement around the component that the tooltip will appear */
  placement?: PlacementType;
  /**Use or hide tooltip arrow*/
  useArrow?: boolean;
}

const useCSS = makeCSS(({ theme }) => {
  return {
    root: {
      width: 'fit-content',
      position: 'relative',
      display: 'inline-block'
    },
    tooltip: {
      boxSizing: 'border-box',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
      alignSelf: 'center',
      opacity: 0.8,
      height: 'auto',
      borderRadius: theme.borderRadius.sm,
      background: theme.color.black[80],
      paddingTop: theme.spacing[1],
      paddingBottom: theme.spacing[1],
      paddingLeft: theme.spacing[2],
      paddingRight: theme.spacing[2],
      //
      fontSize: theme.fontSize[0], //line-height won't work without it
      lineHeight: 'tight'
    }
  };
});

export const Tooltip = (props: TooltipProps) => {
  const { content, placement = 'bottom', useArrow = true, children } = props;

  const classes = useCSS();

  const customMargin = useArrow ? 16 : 8;
  // const childRef = useRef<HTMLDivElement | null>(null);

  // let childElement = children;
  // if (React.isValidElement(children)) {
  //   childElement = React.cloneElement<React.RefAttributes<HTMLElement>>(
  //     children,
  //     { ref: childRef }
  //   );
  // }

  const [isOpen, setIsOpen] = useState(false);
  const arrowRef = useRef(null);

  const { refs, floatingStyles, middlewareData, context } = useFloating({
    placement,
    open: isOpen,
    onOpenChange: setIsOpen,
    strategy: 'fixed',
    middleware: [
      flip(),
      shift(),
      offset(customMargin),
      arrow({
        element: arrowRef.current
      })
    ]
  });
  const hover = useHover(context, { move: false });
  /**
   * Opens the floating element while the reference element is focused
   * Might add in the future
   * const focus =useFocus(content, {})
   */

  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

  return (
    <React.Fragment>
      <div
        className={classes.root}
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        {children}
      </div>
      {isOpen && (
        <div
          className={classes.tooltip}
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
        >
          <Typography colorVariant="01" weight="body">
            {content}
          </Typography>

          {useArrow && (
            <FloatingArrow
              ref={arrowRef}
              context={context}
              width={12}
              height={8}
              fill="current"
              opacity={0.8}
              style={{ margin: -0.2 }} //when placed on the right the arrow has a small space for the tooltip
              tipRadius={2}
            />
          )}
        </div>
      )}
    </React.Fragment>
  );
};
