import React, { useMemo } from 'react';

import { useCreateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useCreateResource';
import { addFormId } from '@cube3/state/src/redux/middleware/redux-form-middleware';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';

export const withCreateProjectMember = (Wrapped) => (props) => {
  const [workspaceId] = useCurrentWorkspace();
  const config = useMemo(() => {
    return {
      resourceType: 'project-member' as 'project-member',
      ancestor: { type: 'project' as 'project', id: props.retrievedProject.id },
      relationship: 'members',
      actionDecorators: [
        addFormId('ProjectMemberPicker', {
          formState: undefined,
          useRequestStatus: true,
          delegate: true
        })
      ],
      cacheInvalidator: (res, anc, rel) => [
        {
          type: anc.type,
          id: anc.id,
          relationship: rel
        },
        {
          type: 'workspace',
          id: workspaceId,
          relationship: 'projects'
        }
      ]
    };
  }, [props.retrievedProject.id]);

  const [createResource, createResourceStatus] =
    useCreateResource__ALPHA(config);

  return (
    <Wrapped
      {...props}
      addMembers={createResource}
      addMembersStatus={createResourceStatus}
    />
  );
};
