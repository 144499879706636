export const fitStrings = ({
  maxWidth,
  ellipsis,
  getWidthOfText,
  required = '',

  fromStart = '',
  fromEnd = '',
  delimiters
}) => {
  // split into chunks using custom delimiters
  // NOTE: delimiters are added to the array grouped by repeating delimiter,
  // e.g. `---` would be one chunk, but `-_-` would be three
  const splitExp = new RegExp(
    `((?:${delimiters
      .map((d) => `${d === ' ' ? '[\\s]' : `[${d}]`}+`)
      .join('|')}))`
  );
  const startChunks = fromStart.split(splitExp).filter(Boolean);
  const endChunks = fromEnd.split(splitExp).filter(Boolean);

  let start = fromStart ?? ''; // mutable version of input string
  let end = fromEnd ?? ''; // mutable version of input string
  let startEllipsis = false; // show ellipsis at start
  let endEllipsis = false; // show ellipsis at end
  let from = 'start'; // start adding first word chunk
  let startChunksUsed = 0; // track how many chunks we can (almost) fit at the start
  let endChunksUsed = 0; // track how many chunks we can (almost) fit at the end

  // first try adding "word" chunks on either side
  while (
    getWidthOfText(
      `${
        startChunksUsed ? startChunks.slice(-startChunksUsed).join('') : ''
      }${required}${endChunks.slice(0, endChunksUsed).join('')}`
    ) < maxWidth &&
    (startChunksUsed !== startChunks.length ||
      endChunksUsed !== endChunks.length)
  ) {
    if (from === 'start') {
      // only if there's still chunks
      if (startChunksUsed < startChunks.length) {
        startChunksUsed += 1;
      }
      // switch which side we add the next chunk (could be tweaked to bias leading or trailing word)
      from = 'end';
    } else {
      // only if there's still chunks
      if (endChunksUsed < endChunks.length) {
        endChunksUsed += 1;
      }
      from = 'start';
    }
  }

  // now we have some strings that almost fit, we will prune them down
  start = startChunks.slice(-startChunksUsed).join('');
  end = endChunks.slice(0, endChunksUsed).join('');

  // we usually want to add ellipsis, unless
  // - the section length matches the input, so we didn't prune this part
  // - we've determined we cannot fit any chars, so we won't try adding the ellipsis
  // NOTE: this could be refined a bit, so we render ellipsis instead of the char if we can only fit 1 char
  startEllipsis = start.length > 0 && start.length < fromStart.length;
  endEllipsis = end.length > 0 && end.length < fromEnd.length;

  while (
    getWidthOfText(
      `${startEllipsis ? ellipsis : ''}${start}${required}${end}${
        endEllipsis ? ellipsis : ''
      }`
    ) > maxWidth &&
    start.length + end.length > 0 // make sure we stop when we cannot fit anything
  ) {
    if (from === 'start') {
      // we eat 1 char
      start = start?.length ? start.slice(1) : start;
      // we also trim leading whitespace
      start = start.trimStart();
      // determine wheter to show the ellipsis based on the change
      startEllipsis = start.length > 0 && start.length < fromStart.length;
    } else {
      end = end.length ? end.slice(0, -1) : end;
      // here we trim trailing whitespace
      end = end.trimEnd();
      endEllipsis = end.length > 0 && end.length < fromEnd.length;
    }
    // NOTE: here it makes sense to tweak based on difference between start and end strings
    // i.e. eat the longest string first, so the highlighted part stays centerd
    // this looks nice when resizing interactively
    from = start.length > end.length ? 'start' : 'end';
  }

  return `${startEllipsis ? ellipsis : ''}${start}${required}${end}${
    endEllipsis ? ellipsis : ''
  }`;
};
