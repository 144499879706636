import React from 'react';
import { Typography } from '../typography/Typography';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { TruncatedText } from '../human/Truncate';
import { useClassName } from '../utils/useClassName';
import { TeamDetailProps } from '../User/UserTypes';
import { Shimmer } from '@cube3/cubicle/src/core/atoms/Loader/Shimmer';
import { Avatar } from '@cube3/cubicle/src/core/atoms/Avatar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1
    },
    teamAvatar: {
      flexShrink: 0,
      borderRadius: 4
    },
    teamDetails: {
      flexGrow: 1,
      marginLeft: '16px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },

    badge: {
      padding: '0 4px',
      backgroundColor: theme.customPalette.backgroundAccent.whiteAccent2,
      borderRadius: '8px',
      height: '16px',
      lineHeight: '16px',
      margin: '0 8px'
    }
  })
);
interface Props {
  team: TeamDetailProps;
  styles?: { root?: string };
  membersCount?: number;
  loading?: boolean;
}

export const TeamName = ({ teamName, suffix = '', rootClass = '' }) => {
  const classes = useStyles();

  return (
    <Typography color="contrast1" typographyStyle="body1" className={rootClass}>
      <TruncatedText
        str={teamName}
        suffixComponent={
          suffix && (
            <Typography
              color="contrast2"
              typographyStyle="body3"
              className={classes.badge}
            >
              {suffix}
            </Typography>
          )
        }
      />
    </Typography>
  );
};

export const TeamDetail = (props: Props) => {
  const { team, membersCount = undefined, styles, loading = false } = props;
  const classes = useStyles();
  return (
    <div className={useClassName(classes.root, styles?.root)}>
      <Avatar
        userInitial={team?.display_name}
        imageUrl={team?.display_picture}
        avatarShape="square-shape"
      />
      <div className={classes.teamDetails}>
        <TeamName teamName={team?.display_name} suffix="Team" />

        {membersCount !== undefined &&
          (loading ? (
            <div style={{ height: '1em', width: '90px' }}>
              <Shimmer />
            </div>
          ) : (
            <Typography
              color="contrast2"
              typographyStyle="body2"
            >{`${membersCount.toString()} ${
              membersCount === 1 ? 'member' : 'members'
            }`}</Typography>
          ))}
      </div>
    </div>
  );
};
