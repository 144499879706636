import React, { useCallback } from 'react';
import { Tooltip } from '@cube3/cubicle/src/core/atoms/Tooltip/Tooltip';
import Button from '@cube3/cubicle/src/core/atoms/Button/Button';
import { Typography } from '@cube3/cubicle/src/core/atoms/Typography/Typography';
import { makeCSS } from '@cube3/cubicle/src/utils/makeCSS';

interface Props {
  activeFrame: number;
  canTakeSnapshot: boolean;
  showSnapshotsList: boolean;
  shortCutTips?: any;
  stepFrame: (step: number) => void;
  onToggle: () => void;
}

const useStyles = makeCSS(({ theme }) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing['1']
  }
}));

export const FrameControls: React.FunctionComponent<Props> = React.memo(
  (props) => {
    const {
      activeFrame,
      canTakeSnapshot = false,
      showSnapshotsList = false,
      shortCutTips,
      onToggle,
      stepFrame
    } = props;
    const classes = useStyles();
    const onPreviousFrame = useCallback(() => stepFrame(-1), [stepFrame]);
    const onNextFrame = useCallback(() => stepFrame(1), [stepFrame]);

    return (
      <div className={classes.container}>
        <Tooltip
          content={shortCutTips && `Go back 1 frame (${shortCutTips.back})`}
          placement="bottom"
          useArrow={false}
        >
          <Button
            aria-label="frame backwards"
            buttonStyle="ghost"
            background="secondary"
            iconLeft={'chevron_left'}
            onClick={onPreviousFrame}
          />
        </Tooltip>

        <Typography color="dark" colorVariant="01">
          frame {activeFrame}
        </Typography>
        <Tooltip
          content={
            shortCutTips && `Go forward 1 frame (${shortCutTips.forward}`
          }
          placement="bottom"
          useArrow={false}
        >
          <Button
            aria-label="frame forwards"
            buttonStyle="ghost"
            background="secondary"
            iconLeft={'chevron_right'}
            onClick={onNextFrame}
          />
        </Tooltip>

        {canTakeSnapshot && (
          <Button
            aria-label="make snapshot"
            buttonStyle="ghost"
            background="secondary"
            iconLeft={'camera'}
            iconRight={showSnapshotsList ? 'chevron_down' : 'chevron_up'}
            stateOverride={showSnapshotsList ? 'active' : 'default'}
            onClick={onToggle}
          />
        )}
      </div>
    );
  }
);
