import React, { useMemo } from 'react';
import { useCreateResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useCreateResource';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';

export const withProjectTeamMemberships = (Wrapped) => (props) => {
  const [workspaceId] = useCurrentWorkspace();
  const config = useMemo(() => {
    return {
      resourceType: 'project-team-membership' as const,
      cacheInvalidator: (res, anc, rel) => [
        res,
        {
          type: undefined,
          id: undefined,
          relationship: 'project-team-memberships'
        },
        {
          type: 'workspace',
          id: workspaceId,
          relationship: 'projects'
        }
      ]
    };
  }, [workspaceId]);

  const [addTeamAsMember, addTeamAsMemberStatus] =
    useCreateResource__ALPHA(config);

  return (
    <Wrapped
      {...props}
      addTeamAsMember={addTeamAsMember}
      addTeamAsMemberStatus={addTeamAsMemberStatus}
    />
  );
};
