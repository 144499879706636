import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { UseResourceListResponse } from '@cube3/state/src/redux/components/Hooks/useResourceList/useResourceList';
import { Tag } from '@cube3/common/model/schema/resources/tag';
import { findMatchingTag } from './utils/findMatchingTag';
import { useModalActions } from '../../../../layout/Modals/modalActions';
import { useTagCategories } from '../../../TagManagement/hooks/useTagCategories';
import { EditableTag } from '@cube3/cubicle/src/core/atoms/Tag/types';
import { addUniqueTag } from './utils/addUniqueTag';

interface UseEditableTagsConfig {
  inputTags: EditableTag[];
  setInputTags(
    tags: EditableTag[] | ((current: EditableTag[]) => EditableTag[])
  ): void;
  allTags: UseResourceListResponse<Tag>;
  partialTags: UseResourceListResponse<Tag>;
  commonTags: UseResourceListResponse<Tag>;
  canModify?: boolean;
}

export const useEditableTags = ({
  inputTags,
  setInputTags,
  allTags,
  partialTags,
  commonTags,
  canModify
}: UseEditableTagsConfig) => {
  const { openModal } = useModalActions();

  const { getTagsWithCategory } = useTagCategories();

  /** track existing tags that need to get removed */
  const [toDelete, setToDelete] = useState<EditableTag[]>([]);

  /** Callback for when a tag must be removed
   * Can mark an existing tag for deletion, or remove a new tag from the input
   */
  const handleRemoveTag = useCallback(
    (target: EditableTag) => {
      /** ai tags are excluded by default */
      const allEditableTags = getTagsWithCategory(allTags.resources);
      setToDelete((current) =>
        addUniqueTag(
          current,
          target.category.editable
            ? findMatchingTag(allEditableTags)({ id: tag.id })
            : target
        )
      );
      // remove tags that should get removed from the add list
      const tag = findMatchingTag(allEditableTags)({ id: target.id });
      setInputTags((current) => {
        const m = findMatchingTag(current)(tag);
        return m ? current.filter((t) => t !== m) : current;
      });
    },
    [setToDelete, setInputTags, getTagsWithCategory, allTags.resources]
  );

  /** filter common tags from partial tags */
  const onlyPartialTags = useMemo(() => {
    const filtered = partialTags.resources?.filter(
      (t) => !findMatchingTag(commonTags.resources)(t)
    );
    return filtered ? getTagsWithCategory(filtered) : [];
  }, [partialTags.resources, commonTags.resources]);

  // the union of all tags applied to the selected items
  const initial = useMemo(() => {
    const withCategoryName = getTagsWithCategory(commonTags.resources);
    return withCategoryName.map((t) => ({
      id: t.id,
      color: t.color,
      text: t.text,
      category: t.category,
      onCrossClick: () => {
        handleRemoveTag(t);
      },
      onClick:
        !canModify || !t.text || !t.category?.editable
          ? undefined
          : () => {
              openModal('edit_tag_modal', { tag: t }, false);
            }
    }));
  }, [
    commonTags.resources,
    handleRemoveTag,
    openModal,
    canModify,
    getTagsWithCategory
  ]);

  const prefilled = useRef(false);
  useEffect(() => {
    if (
      !prefilled.current &&
      initial?.length &&
      initial.length === commonTags.resources.length
    ) {
      setInputTags(
        initial
        // .filter(t => !t.text && !t.color)
      );
      prefilled.current = true;
    }
  }, [initial, commonTags.resources, setInputTags]);

  const selectedTags = useMemo(() => {
    return inputTags.map((t) => ({
      ...t,
      onClick:
        !canModify || !t.text || !t.id || t.category?.editable === false // it could be `undefined` if the tag is new
          ? undefined
          : () => {
              openModal(
                'edit_tag_modal',
                {
                  tag: t,
                  //  excluded: toCreate,
                  onSave: (changes) => {
                    setInputTags((current) => {
                      const m = findMatchingTag(current)(t);
                      return m
                        ? current.map((t) =>
                            t === m ? { ...t, ...changes } : t
                          )
                        : current;
                    });
                  }
                },
                false
              );
            },
      onCrossClick: () => {
        handleRemoveTag(t);
        setInputTags((current) => {
          const m = findMatchingTag(current)(t);
          return m ? current.filter((t) => t !== m) : current;
        });
      }
    }));
  }, [inputTags, canModify, setInputTags, handleRemoveTag]);

  /** AI tags are excluded */
  const toAdd = useMemo(() => {
    return inputTags?.filter(
      (t) =>
        t.id &&
        t.category?.editable &&
        !findMatchingTag(commonTags.resources)(t)
    );
  }, [inputTags, commonTags.resources]);

  const toCreate = useMemo(() => {
    return inputTags?.filter((t) => !t.id);
  }, [inputTags]);

  /** calculated list of partial tags, that takes user input into account */
  const editablePartialTags = useMemo(() => {
    return onlyPartialTags
      .filter((t) => !findMatchingTag(toDelete)(t))
      .filter((ot) => !findMatchingTag(inputTags)(ot))
      .map((t) => ({
        ...t,
        onCrossClick: !canModify
          ? undefined
          : () => {
              openModal(
                'remove_tag_from_assets',
                {
                  tag: t,
                  onCrossClick: () => handleRemoveTag(t)
                },
                false
              );
            },
        onClick:
          !canModify || !t.text || !t.category?.editable
            ? undefined
            : () => {
                openModal(
                  'edit_tag_modal',
                  { tag: t, excluded: toCreate },
                  false
                );
              }
      }));
  }, [
    onlyPartialTags,
    toDelete,
    handleRemoveTag,
    toAdd,
    openModal,
    canModify,
    toCreate
  ]);

  const editableDeleteTags = useMemo(() => {
    return toDelete?.filter(
      (td) =>
        td.id && !findMatchingTag(toAdd)(td) && !findMatchingTag(inputTags)(td)
    );
  }, [toDelete, toAdd, inputTags]);

  /** track if any mutations are "staged" */
  const anyChanges =
    toCreate.length || toAdd.length || editableDeleteTags.length;

  // console.log('toCreate, toAdd, toDelete', toCreate, toAdd, editableDeleteTags);
  return {
    anyChanges,
    /** tags will be removed from selected assets */
    toDelete: editableDeleteTags,
    /** tags will add to selected assets, AI tags are excluded */
    toAdd,
    /** tags will be created */
    toCreate,
    selectedTags,
    /** tags applied to some files only */
    editablePartialTags
  };
};
