import React, { useMemo } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { iconAdd } from '../../../../icons/GeneralSVG';
import { Typography } from '../../../../typography/Typography';
import IconButton from '../../../../Buttons/IconButton';
import Button from '../../../../Buttons/Button';
import { AvatarList } from '../../../../Workspace/subComponents/AvatarList';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      width: '100%',
      display: 'flex'
    },
    subTitle: {
      marginBottom: theme.customSpacing.margin[16]
    },
    restContainer: {
      width: '100%'
    },
    userContainer: {
      display: 'inline-flex',
      width: '100%',
      alignItems: 'center',
      margin: `${theme.customSpacing.margin[8]} 0px`
    },
    avatar: {
      marginRight: '4px'
    }
  })
);
export interface LinkRecipientsProps {
  email_addresses: string[];
  onClickManageAccess: () => void;
  onClickAddUser: () => void;
}
/** Who can access */
export const LinkSettingsRecipientsUI = (props: LinkRecipientsProps) => {
  const { email_addresses, onClickAddUser, onClickManageAccess } = props;
  const classes = useStyles();

  /*map email addresses to elements for container chips.*/
  const users = useMemo(() => {
    if (email_addresses?.length) {
      return email_addresses.map((e) => ({
        full_name: e
      }));
    }
    return [];
  }, [email_addresses]);

  return (
    <React.Fragment>
      <div className={classes.restContainer}>
        <Typography
          component="span"
          color="contrast1"
          typographyStyle={'heading3'}
          className={classes.subTitle}
        >
          Link recipients
        </Typography>
        <Typography
          component="span"
          color="contrast2"
          // eslint-disable-next-line react/style-prop-object
          typographyStyle={'body2'}
        >
          These people have access through a personal link
        </Typography>

        <div className={`${classes.userContainer}`}>
          {users.length && (
            <AvatarList users={users} maxCount={5} size={'sm'} />
          )}
          <IconButton
            onClick={() => onClickAddUser()}
            path={iconAdd}
            colorVariant={'ghost2'}
          />
        </div>

        <Button
          path={iconAdd}
          text="Manage access"
          onClick={() => onClickManageAccess()}
          colorVariant={'ghost1'}
          marginCollapse={'left'}
        />
      </div>
    </React.Fragment>
  );
};
