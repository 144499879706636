import * as Sentry from '@sentry/browser';

// THIS interceptor removes the "tags" relationship from tag-category
// resources, because that potentially contains a lot of items, which
// hangs the UI during loading process. This shouldn't cause issues
// because it looks like that data isn't used anywhere.
//
// TODO:
// - fix the resource-edges reducer, so it can ingress data more efficiently
// - figure out why making the retrieve middleware async, causes folder upload
//   issues on some macbooks
// - maybe ask backend to just not send this data?

function requestInterceptor(req) {
  return req;
}
function requestErrorInterceptor(error) {
  return Promise.reject(error);
}
function responseInterceptor(res) {
  console.info('DEBUG res', res.data);

  if (res.data.data?.type === 'tag-category') {
    res.data.data.relationships = {
      ...res.data.data.relationships,
      tags: { data: [] }
    };
  } else if (res.data.data?.[0]?.type === 'tag-category') {
    res.data.data.forEach((tc) => {
      tc.relationships = { ...tc.relationships, tags: { data: [] } };
    });
  }

  return res;
}
function responseErrorInterceptor(error) {
  return Promise.reject(error);
}

export const preventTagCategorySlowdownInterceptor = {
  requestInterceptor,
  requestErrorInterceptor,
  responseInterceptor,
  responseErrorInterceptor
};
