import { ModalNoticeUI } from '@cube3/ui/src/Modal/ModalNoticeUI';
import React from 'react';
import { useModalActions } from '../modalActions';
import { Button } from '@cube3/cubicle/src/core/atoms/Button/Button';
import { Typography } from '@cube3/cubicle/src/core/atoms/Typography';
import { HBox, VBox } from '@cube3/cubicle/src/core/templates/layout/Flex';
import { Bytes } from '@cube3/cubicle/src/core/utility/Bytes';
import { Icon } from '@cube3/cubicle/src/core/atoms/Icon';
import { SizeVariants } from '@cube3/cubicle/src/theme/themes';
interface ModalReceivedProps {
  modalContext: {
    /** files exceeding `workspace.maximum_allowed_file_size`, they will not get uploaded  */
    toBlock?: File[];
    /** files exceeding `workspace.maximum_processable_file_size` */
    toWarn?: File[];
    /** accepted files, includes `toWarn` */
    toUpload?: File[];
    exceededSize?: number;
    maximumProcessableFileSize?: number;
    maximumAllowedFileSize?: number;
    onUpload(): void;
  };
}

export const MaximumFileSizeExceededNotice = (props: ModalReceivedProps) => {
  const {
    toBlock,
    toUpload,
    toWarn,
    exceededSize,
    maximumAllowedFileSize,
    maximumProcessableFileSize,
    onUpload
  } = props.modalContext;
  const { previousModal } = useModalActions();

  const blockOnly = toBlock.length > 0 && !toWarn.length;

  return (
    <ModalNoticeUI
      title={
        <HBox align="center" gap={8}>
          <Icon
            icon={toBlock.length ? 'error' : 'warning'}
            size={SizeVariants.sm}
          />
          <Typography>Upload files</Typography>
        </HBox>
      }
      onCloseEvent={previousModal}
      footerRightComponent={
        <>
          <Button
            label={blockOnly ? 'Cancel all' : 'Cancel'}
            background="secondary"
            buttonStyle="ghost"
            onClick={previousModal}
          />
          {exceededSize > 0 || toUpload.length < 1 ? null : (
            <Button
              label={
                blockOnly
                  ? `Skip ${toBlock.length === 1 ? 'file' : 'files'}`
                  : 'Continue upload'
              }
              background="secondary"
              buttonStyle="solid"
              onClick={onUpload}
            />
          )}
        </>
      }
    >
      <VBox style={{ gap: 16 }}>
        {toWarn.length > 0 ? (
          <div>
            <Typography>
              {toWarn.length && toWarn.length === 1
                ? 'This file exceeds '
                : toWarn.length === toUpload.length
                ? 'All files exceed '
                : 'These files exceed '}
              the maximum processable file size{' '}
              <strong>
                <Bytes
                  size={maximumProcessableFileSize.toString()}
                  inFormat="B"
                  fullForm={false}
                  toolTip={false}
                  placement="bottom-start"
                />
              </strong>
              . There will be no thumbnails, AI tags and file previews.
            </Typography>
            <FileList files={toWarn} />
          </div>
        ) : null}

        {toBlock.length > 0 ? (
          <div>
            <Typography>
              {toBlock.length && toBlock.length === 1
                ? 'This file is '
                : !toUpload.length
                ? 'All files are '
                : 'These files are '}
              rejected because {toBlock.length === 1 ? 'it' : 'they'} exceeded
              the maximum allowed file size{' '}
              <strong>
                <Bytes
                  size={maximumAllowedFileSize.toString()}
                  inFormat="B"
                  fullForm={false}
                  toolTip={false}
                  placement="bottom-start"
                />
              </strong>
              .
            </Typography>

            <FileList files={toBlock} />
          </div>
        ) : null}

        {exceededSize > 0 ? (
          <Typography>
            Workspace maximum file size exceeded, File is{' '}
            <strong>
              <Bytes
                size={exceededSize.toString()}
                inFormat="B"
                fullForm={false}
                toolTip={false}
                placement="bottom-start"
              />
            </strong>{' '}
            larger than your workspace allows.
          </Typography>
        ) : null}
      </VBox>
    </ModalNoticeUI>
  );
};

const FileList = ({ files }: { files: File[] }) => {
  return files?.length ? (
    <ul style={{ margin: '8px 0px', paddingLeft: 16 }}>
      {files.map((f) => (
        <li key={f.name}>
          <Typography>
            {f.name}
            <Typography colorVariant="02">
              {' ('}
              <Bytes
                size={f.size.toString()}
                inFormat="B"
                fullForm={false}
                toolTip={false}
                placement="bottom-start"
              />
              {')'}
            </Typography>
          </Typography>
        </li>
      ))}
    </ul>
  ) : null;
};
