import { useContextPath } from '@cube3/state/src/redux/components/Hooks/useContextPath';
import { useResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResource';
import { mergeStatuses } from '@cube3/state/src/redux/utils/mergeStatuses';

export const useActivityContextWithPath = (contextRIDS) => {
  const folder = contextRIDS?.find((c) => c.type === 'folder');
  const parentFolder = useResource__ALPHA({
    resourceId: folder?.id,
    resourceType: folder?.type
  });

  const [ancestorPath] = useContextPath({
    resourceId: folder?.id,
    edgeResourceType: folder?.type,
    edgeResourceId: folder?.id
  });

  const loading = parentFolder.loading;
  const status = mergeStatuses([parentFolder.status], true);

  return {
    name: parentFolder.resource?.display_name,
    pathname: ancestorPath,
    resource: parentFolder.resource,
    loading,
    status
  };
};

export const useActivityContext = (contextRIDS) => {
  const ctx = contextRIDS?.find(
    (c) =>
      c.type === 'render-preset' ||
      c.type === 'export-target' ||
      c.type === 'folder'
  );

  const res = useResource__ALPHA({
    resourceId: ctx?.id,
    resourceType: ctx?.type
  });

  return {
    name: res.resource?.display_name,
    resource: res.resource,
    loading: res.loading
  };
};
