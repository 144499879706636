const irregularPlurals = {
  person: 'people'
};

const rules = [
  { regex: /y$/, replace: 'ies' }, // e.g., "party" -> "parties"
  { regex: /s$/, replace: 'ses' }, // e.g., "bus" -> "buses"
  { regex: /f$/, replace: 'ves' }, // e.g., "wolf" -> "wolves"
  { regex: /fe$/, replace: 'ves' }, // e.g., "knife" -> "knives"
  { regex: /o$/, replace: 'oes' } // e.g., "potato" -> "potatoes"
];

export const pluralize = (word: string, count?: number) => {
  if (!word) return '';
  let plural = '';
  const specialEnding = /(s|ch|sh|x|z)$/;
  const matchedRule = rules.find((rule) => rule.regex.test(word));
  switch (!!word) {
    case irregularPlurals[word]:
      plural = irregularPlurals[word];
      break;
    case specialEnding.test(word):
      plural = word + 'es';
      break;
    case !!matchedRule:
      plural = word.replace(matchedRule.regex, matchedRule.replace);
      break;
    default:
      plural = word + 's';
      break;
  }

  if (count === undefined) {
    return plural;
  }

  return count <= 0 ? '' : count + ' ' + (count === 1 ? word : plural);
};
