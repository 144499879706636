import React from 'react';
import { getPopupSubText, getTextClass } from './helpers';
import { UploadUIStates } from './types';

import Time from '../../utility/Time';
import { Typography } from '../Typography';
import { makeCSS } from '../../../utils/makeCSS';

interface Props {
  uploadState: UploadUIStates;
  eta: number;
  isHovered: boolean;
  gridview: boolean;
  progress?: number;
}

const useCSS = makeCSS(({ theme }) => {
  return {
    defaultText: {
      textAlign: 'left'
    },
    uploadText: {
      color: theme.color.foreground.secondary, //theme.customPalette.secondary.main,
      textAlign: 'left'
    },
    uploadTime: {
      color: theme.color.foreground.secondary, //theme.customPalette.secondary.contrastColor,
      fontSize: '10px',
      textAlign: 'left'
    },
    uploadTextError: {
      color: theme.color.foreground.critical, // theme.customPalette.dangerError,
      textAlign: 'left'
    }
  };
});

export const ProgressEta: React.FunctionComponent<Props> = (props) => {
  const { gridview, uploadState, isHovered, eta, progress } = props;

  const classes = useCSS(props);

  let text = getPopupSubText(isHovered, uploadState);
  let textClass = getTextClass(isHovered, uploadState, classes);

  //#region determine some edge cases for the upload texts.
  if (isHovered && uploadState === 'uploading') {
    text = 'Click to pause upload. ';
    textClass = classes.defaultText;
  }

  if (isHovered && uploadState === 'paused') {
    text = 'Click to resume upload. ';
    textClass = classes.defaultText;
  }

  if (isHovered && uploadState === 'upload_pending') {
    text = 'Click to start upload. ';
    textClass = classes.defaultText;
  }

  //#region determine text class
  if (
    uploadState === 'upload_failed' ||
    uploadState === 'upload_initialization_failed' ||
    uploadState === 'upload_missing' ||
    (uploadState === 'paused' && !isHovered)
  ) {
    textClass = classes.uploadTextError;
  }

  return (
    gridview && (
      <React.Fragment>
        <span className={textClass}>
          <Typography weight="body" colorVariant="01" customColor="#ffd423">
            {text}
          </Typography>
        </span>
        {uploadState === 'uploading' &&
          eta &&
          eta !== Infinity &&
          progress !== 100 && (
            <span className={classes.uploadTime}>
              <Typography weight="body" colorVariant="01" customColor="#ffd423">
                {' '}
                Done{' '}
                <Time
                  date={new Date(eta)}
                  fullForm={false}
                  toolTip={false}
                  placement="bottom-start"
                  now={new Date()}
                  relativeTime={true}
                />
              </Typography>
            </span>
          )}
      </React.Fragment>
    )
  );
};
