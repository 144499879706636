import {
  createStyles,
  MenuItem,
  TextField,
  Theme,
  makeStyles
} from '@material-ui/core';
import * as React from 'react';
import { useCallback } from 'react';
import { isIOS, isMobile } from 'react-device-detect';
/* import components */
import CustomFade from '../../helpers/CustomFade';
import UseHover from '../../helpers/hooks/UseHover';
/* import icons */
import GeneralSVG, { iconKeyboardArrowDown } from '../../icons/GeneralSVG';

import {
  shortCutDocumentTop,
  useImagePreviewKeyboardShortCuts
} from '../ImagePreview/hooks/useImagePreviewKeyboardShortCuts';
import { makeTip } from '../ImagePreview/ImagePreviewControls';
import { PanZoomContext } from '../ImagePreview/PanZoomContext';
import Button from '@cube3/cubicle/src/core/atoms/Button/Button';
import { Tooltip } from '@cube3/cubicle/src/core/atoms/Tooltip/Tooltip';
import { Typography } from '@cube3/cubicle/src/core/atoms/Typography/Typography';
import { ZoomButtons } from '../ImagePreview/ZoomButtons';
import { FullscreenButton } from '../VideoPreview/FullscreenButton';
import { videoPlayerShortCutsTips } from '../VideoPreview/hooks/useVideoPlayerKeyboardShortcuts';
// import Select from '@cube3/cubicle/src/core/atoms/Select/Select';
// import { Option } from '@cube3/cubicle/src/core/atoms/Select/Option';

const DropdownIcon = (props) => {
  return <GeneralSVG {...props} path={iconKeyboardArrowDown} />;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mobile: {},
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
      flexWrap: 'wrap',
      paddingBottom: theme.customSpacing.padding[20],
      paddingLeft: theme.customSpacing.padding[24],
      paddingRight: theme.customSpacing.padding[24],
      paddingTop: theme.customSpacing.padding[32],
      '&$mobile': {
        padding: theme.customSpacing.padding[8],
        position: 'absolute',
        width: 'calc(100% - 16px)',
        bottom: 0
      }
    },
    barGroup: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      textAlign: 'center',
      width: '100%'
    },
    leftNavControls: {
      display: 'flex',
      alignSelf: 'flex-start',
      width: theme.sizing[92],
      justifyContent: 'space-between',
      '$mobile &': {
        width: 'auto'
      }
    },
    rightControls: {
      display: 'flex',
      alignSelf: 'flex-end',
      '$mobile &': {
        width: 40
      }
    },
    zoomInIcon: {
      margin: `0px ${theme.customSpacing.margin[24]} 0px ${theme.customSpacing.margin[12]}`,
      '$mobile &': {
        margin: 'auto'
      }
    },
    input: {
      justifyContent: 'center',
      textAlign: 'center',
      background: 'inherit',
      color: theme.customPalette.text.onBase.contrast1,
      '&:hover': {
        backgroundColor: theme.customPalette.surfaceState.getSurfaceStateColor(
          theme.customPalette.surface.base1,
          'onBase',
          ['hover1']
        )
      }
    },
    inputContainer: {
      justifySelf: 'center',
      '$mobile &': {
        width: '120px'
      }
    },
    totalPages: {
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      marginLeft: theme.customSpacing.margin[24],
      '$mobile &': {
        marginLeft: theme.customSpacing.margin[8]
      }
    },
    divider: {
      display: 'flex',
      alignItems: 'center'
    },
    sameline: {
      display: 'flex'
    },
    select: {
      maxHeight: '40vh',
      overflowX: 'hidden',
      marginRight: -12,
      width: 'unset',
      backgroundColor: theme.customPalette.surface.base2
    },
    selectIcon: {
      color: theme.customPalette.text.onBase.contrast1
    },
    paperSelect: {
      '&:focus': {
        backgroundColor: 'initial'
      },
      color: theme.customPalette.text.onBase.contrast1,
      ...theme.typography.body2,
      padding: `0px ${theme.customSpacing.padding[24]}`,
      '$mobile &': {
        padding: `0px ${theme.customSpacing.padding[8]}`
      }
    },
    selectMenu: {
      backgroundColor: 'inherit'
    },
    scrim: {
      pointerEvents: 'none',
      position: 'absolute',
      height: '56px',
      left: 0,
      bottom: 0,
      right: 0,
      background: theme.customPalette.surfaceAccent.onBase.scrim3
    }
  })
);

interface DocumentPreviewControlsProps {
  toggleFullscreenAction: () => void;
  // zoomOut: () => void;
  // zoomIn: () => void;
  next?: () => void;
  previous?: () => void;
  goToPage?: (ev: React.SyntheticEvent | number) => void;
  // zoom: number;
  //minZoom: number;
  //maxZoom: number;
  current: number;
  totalPages: number;
  fullscreen: boolean;
  renderFullscreenControls?: () => React.ReactNode;
  mainContainerRef: HTMLDivElement;
}

const DocumentPreviewControls: React.FC<DocumentPreviewControlsProps> = (
  props
) => {
  const [hoverRef, isHovered] = UseHover();
  const {
    toggleFullscreenAction,
    current,
    totalPages,
    goToPage,
    fullscreen,
    renderFullscreenControls
  } = props;

  const { zoomIn, zoomOut, canZoomOut, canZoomIn, zoomReset, canFitZoom } =
    React.useContext(PanZoomContext);

  const handleScrollTopClick = useCallback(() => {
    goToPage(1);
  }, [goToPage]);

  useImagePreviewKeyboardShortCuts({
    zoomIn,
    zoomOut,
    zoomReset,
    canZoomIn,
    canZoomOut,
    canFitZoom,
    toggleFullscreenAction,
    canGoToPage: current !== 1,
    goToPage: handleScrollTopClick
  });

  const pages = new Array(totalPages).fill(null);

  const classes = useStyles();

  return (
    <div className={`${classes.container} ${isMobile ? classes.mobile : ''}`}>
      {isMobile && <div className={classes.scrim} />}
      {fullscreen && renderFullscreenControls()}
      <div className={classes.barGroup}>
        <div className={classes.leftNavControls}></div>
        <div className={classes.inputContainer}>
          <div className={classes.sameline}>
            <Tooltip content={makeTip(shortCutDocumentTop)} placement="bottom">
              <Button
                aria-label="scroll to top"
                iconLeft="arrow_up"
                background="secondary"
                buttonStyle="ghost"
                disabled={current === 1}
                onClick={handleScrollTopClick}
              />
            </Tooltip>
            <span ref={hoverRef} className={classes.sameline}>
              {/* <Select label={current.toString()} value={current.toString()}>
                {pages.map((p, idx) => {
                  return (
                    <Option
                      key={idx + 1}
                      value={idx + 1}
                      onClick={() => goToPage(idx + 1)}
                      active={current === idx + 1}
                    />
                  );
                })}
              </Select> */}
              <TextField
                select={true}
                type="select"
                value={current}
                InputProps={{ disableUnderline: true }}
                onChange={goToPage}
                className={classes.input}
                SelectProps={{
                  IconComponent: isHovered ? DropdownIcon : () => null,
                  classes: {
                    select: classes.paperSelect,
                    icon: classes.selectIcon,
                    selectMenu: classes.selectMenu
                  },
                  MenuProps: {
                    PaperProps: {
                      className: classes.select
                    }
                  }
                }}
              >
                {pages.map((p, idx) => (
                  <MenuItem key={idx + 1} value={`${idx + 1}`}>
                    {idx + 1}
                  </MenuItem>
                ))}
              </TextField>
            </span>
            <span className={classes.divider}>
              <Typography>/</Typography>
            </span>
            <div className={classes.totalPages}>
              <Typography colorVariant="02">{totalPages}</Typography>
            </div>
          </div>
        </div>
        <div className={classes.rightControls}>
          {!isMobile && <ZoomButtons />}

          {!isIOS && (
            <FullscreenButton
              fullscreen={fullscreen}
              toggleFullscreenAction={toggleFullscreenAction}
              shortCutTip={videoPlayerShortCutsTips.fullscreen}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomFade(DocumentPreviewControls);
const DocumentPreviewControlsMobile = DocumentPreviewControls;
export { DocumentPreviewControlsMobile };
