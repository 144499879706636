import { useMemo, useCallback } from 'react';
import { useTypedSelector } from './useTypedSelector';
import { useResource__ALPHA } from './useResource';
import { useResourceList__ALPHA } from './useResourceList';
import { mergeStatuses } from '../../utils/mergeStatuses';
import { useDispatch } from 'react-redux';
import { actionCreators } from '../../ducks/session';

export const useAuth = ({ setPath }: { setPath: (path: string) => void }) => {
  const authenticated = useTypedSelector((state) => {
    return state.session.auth;
  });

  const userId = useTypedSelector((state) => {
    return state.session.user;
  });

  const accountId = useTypedSelector((state) => {
    return state.session.account;
  });

  const workspaceId = useTypedSelector((state) => {
    return state.session.workspace;
  });

  const remember = useTypedSelector((state) => {
    return state.session.remember;
  });

  const user = useResource__ALPHA({
    resourceType: 'user',
    resourceId: userId
  });
  const userAccounts = useResourceList__ALPHA({
    resourceType: 'user',
    resourceId: userId,
    edgeType: 'account',
    edgeLabel: 'accounts'
  });
  // const workspaceIds = useMemo( () => {
  //     if(!userAccounts || !userAccounts.resources){
  //         return
  //     }
  //     return userAccounts.resources.map( a => a.relationships && a.relationships.worksp )
  // }, [userAccounts])

  // const userWorkspaces = useResource__ALPHA({resourceIdentifiers: workspaceIds});

  const currentAccount = useResource__ALPHA({
    resourceType: 'account',
    resourceId: accountId
  });
  const currentWorkspace = useResource__ALPHA({
    resourceType: 'workspace',
    resourceId: workspaceId,
    strategy: 'cache-only'
  });

  const dispatch = useDispatch();

  const switchAccounts = useCallback(() => {
    dispatch(actionCreators.setCurrentWorkspace(undefined));
    dispatch(
      actionCreators.setCurrentAccount({
        accountId: undefined,
        workspaceId: undefined
      })
    );
    setPath('/');
  }, [dispatch, setPath]);

  const toggleRemember = useCallback(() => {
    if (remember) {
      dispatch(actionCreators.forget());
    } else {
      dispatch(actionCreators.remember());
    }
  }, [dispatch, remember]);

  const merged = useMemo(() => {
    return {
      authenticated,
      user: user && user.resource,
      currentAccount: currentAccount && currentAccount.resource,
      userAccounts: userAccounts && userAccounts.resources,
      currentWorkspace: currentWorkspace && currentWorkspace.resource,
      status: mergeStatuses(
        [
          user.status,
          currentAccount.status,
          userAccounts.status,
          currentWorkspace.status
        ],
        false
      ),
      loading:
        user.loading ||
        currentAccount.loading ||
        userAccounts.loading ||
        currentWorkspace.loading,
      switchAccounts,
      remember,
      toggleRemember
    };
  }, [
    authenticated,
    user,
    userAccounts,
    currentAccount,
    currentWorkspace,
    switchAccounts,
    remember,
    toggleRemember
  ]);

  return merged;
};
