import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { CircularProgress } from '../Progress/CircularProgress';

import Surface from '../Surface/Surface';
import ModalHeader from './ModalHeader';
import { ModalFooterUI } from './ModalFooter';
import ScrollBar from '../Scrollbar/Scrollbar';
import { ReactNode } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    surface: {
      display: 'flex',
      flexDirection: 'column',
      width: 'calc(100vw - 8px)',
      height: 'calc(100vh - 8px)'
    },
    contents: {
      margin: '8px 16px 0px 16px',
      maxHeight: 'calc(100vh - 296px)', // 64px both on bottom and on top - 40 headerHeight - 64 footerHeight - 64 some space for margin and scrollbar
      minHeight: 'calc(100vh - 224px)', // 4px margin both on bottom surface, and on top - 56 headerHeight - 64 footerHeight - extra mobile px
      '& > :last-child': {
        marginBottom: 8
      }
    },
    loadingOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      height: 'auto',
      width: 'auto',
      background: theme.customPalette.backgroundAccent.blackAccent3,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  })
);

interface ModalMbileSurfaceUIProps {
  loading?: boolean;
  showCloseButton?: boolean;
  onCloseEvent?: Function;
  children?: ReactNode;
  /** Component in footer left, centered */
  footerLeftComponent?: JSX.Element;
  /** Component in footer right, centered */
  footerRightComponent?: JSX.Element;
  extraSurfaceProperties?: any;
  title?: string | JSX.Element;
}

function ModalMbileSurfaceUI(props: ModalMbileSurfaceUIProps) {
  const {
    extraSurfaceProperties,
    footerLeftComponent,
    footerRightComponent,
    loading,
    onCloseEvent,
    showCloseButton,
    title
  } = props;
  const classes = useStyles({});
  const { surface, loadingOverlay, contents } = classes;
  return (
    <Surface
      className={surface}
      background={'base2'}
      corners={'rounded'}
      floating={true}
      gradient={'lamp'}
      floatingHeight={24}
      {...extraSurfaceProperties} // override surface properties from parent.
    >
      <ModalHeader
        onClickClose={onCloseEvent ? () => onCloseEvent() : undefined}
        showCloseButton={showCloseButton}
        title={title}
      />

      <ScrollBar autoHeight={true} autoHeightMax={3000}>
        <div className={contents}>{props.children}</div>
      </ScrollBar>

      <ModalFooterUI
        footerLeftComponent={footerLeftComponent}
        footerRightComponent={footerRightComponent}
      />

      {/* Show circular progress, blocking interaction when in loading state */}
      {loading && (
        <div className={loadingOverlay}>
          <CircularProgress />
        </div>
      )}
    </Surface>
  );
}

export default ModalMbileSurfaceUI;
