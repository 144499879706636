import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';

import { iconClose } from '../icons/GeneralSVG';
import { Typography } from '../typography/Typography';
import Surface from '../Surface/Surface';
import { Divider } from '../Surface/Divider';

import IconButton from '../Buttons/IconButton';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: '56px',
      flexShrink: 0 // to prevent txt 100% deforming the avatar.
    },
    contents: {
      display: 'flex',
      width: '100%',
      alignItems: 'center', // vertically align text and button
      marginTop: 'auto',
      marginBottom: 'auto',
      marginLeft: '12px',
      marginRight: '12px'
    },
    title: {
      alignSelf: 'center',
      marginLeft: '12px' // make total of 24 with base of contents
    },
    closeButton: {
      marginLeft: 'auto'
    },
    divider: {
      alignSelf: 'end',
      display: 'block',
      width: '100%'
    }
  })
);

export interface ModalHeaderProps {
  classes?: ClassNameMap;
  title?: string | JSX.Element;
  subTitle?: string;
  onClickClose?: Function;
  showCloseButton?: boolean;
  topBarComponent?: React.ReactNode;
}

const ModalHeader: React.FC<ModalHeaderProps> = (props) => {
  const { title, onClickClose, showCloseButton, topBarComponent, subTitle } =
    props;

  const classes = useStyles();

  return (
    <>
      <Surface background={'shade3'} className={classes.root}>
        {' '}
        {/* TODO use right style for surface */}
        <div className={classes.contents}>
          <Typography
            className={`${classes.title}`}
            typographyStyle={'heading2'}
          >
            {title}
          </Typography>
          {subTitle && (
            <Typography
              className={`${classes.title}`}
              color="contrast2"
              typographyStyle={'body1'}
            >
              {subTitle}
            </Typography>
          )}
          {showCloseButton && onClickClose && (
            <div className={classes.closeButton}>
              <IconButton
                path={iconClose}
                colorVariant={'ghost2'}
                onClick={() => onClickClose()}
              />
            </div>
          )}
        </div>
      </Surface>
      {/* Draw Divider */}
      <div className={classes.divider}>
        <Divider background={'base2'} color={'contrast2'} />
      </div>
      {topBarComponent}
      {topBarComponent && (
        <div className={classes.divider}>
          <Divider background={'base2'} color={'contrast2'} />
        </div>
      )}
    </>
  );
};

export default ModalHeader;
