// import your modal components here
import AddFolderPrompt from './Edit/AddFolderPrompt';
import DeletePrompt from './Edit/DeletePrompt';
import { ExampleModal } from './ExampleModal';

import RenamePrompt from '../ContentPane/RenamePrompt';
// account settings
import ResetPassWordSent from '@cube3/ui/src/dialog/ResetPasswordSent';
import ChangeEmailModal from '../AccountPages/Modals/ChangeEmailModal';
import { ChangeMailPreferenceModal } from '../AccountPages/Modals/ChangeMailPreferenceModal';
import ChangeNameModal from '../AccountPages/Modals/ChangeNameModal';
import ChangePasswordConfirmedModal from '../AccountPages/Modals/ChangePasswordConfirmedModal';
import ChangePasswordModal from '../AccountPages/Modals/ChangePasswordModal';
import ChangeProfilePictureModal from '../AccountPages/Modals/ChangeProfilePictureModal';
import ChangeWorkspaceRoleModal from '../AccountPages/Modals/ChangeWorkspaceRoleModal';
import ConfirmEmailModal from '../AccountPages/Modals/ConfirmEmailModal';
import {
  ConfirmRemoveConnectedAccountModal,
  ConfirmRevokeConnectedAccountModal,
  ConnectAccountPickWorkspaceModal
} from '../AccountPages/Modals/ConfirmRemoveConnectedAccountModal';
import { ChangeProfilePictureErrorNotice } from '../AccountPages/Modals/ProfilePictureErrorNotice';
import DownloadItemsModal from '../Downloads/Modals/DownloadItemsModal';
import CannotOpenItemModal from '../TrashBin/Modals/CannotOpenItemModal';
import DeletePermanentlyPrompt from '../TrashBin/Modals/DeleteForeverModal';
// admin center
import AddedToProjectsConfirmationModal from '../AdminCenter/views/WorkspaceMembersView/modals/AddedToProjectsConfirmationModal';
import RemoveWorkspaceMemberPrompt from '../AdminCenter/views/WorkspaceMembersView/modals/RemoveWorkspaceMemberPrompt';
import WorkspaceMemberAddToProjectsModal from '../AdminCenter/views/WorkspaceMembersView/modals/WorkspaceMemberAddToProjectsModal';
import { CreateProjectTemplateModal } from '../AdminCenter/views/WorkspaceProjectTemplatesView/modals/CreateProjectTemplateModal';
import { DeleteProjectTemplateModal } from '../AdminCenter/views/WorkspaceProjectTemplatesView/modals/DeleteProjectTemplateModal';
import { DuplicateProjectTemplateModal } from '../AdminCenter/views/WorkspaceProjectTemplatesView/modals/DuplicateProjectTemplateModal';
import { ProjectTemplateSettingsModal } from '../AdminCenter/views/WorkspaceProjectTemplatesView/modals/ProjectTemplateSettingsModal';
import { RenameProjectTemplateModal } from '../AdminCenter/views/WorkspaceProjectTemplatesView/modals/RenameProjectTemplateModal';

// import BadRequestModal from '../Modals/Requests/BadRequestModal';

// privacy settings
import CookieSettingsModal from '../Cookies/Modals/CookieSettingsModal';

// projects
import AddProjectMembersModal from '../Projects/Modals/AddProjectMembersModal';
import AddProjectModal from '../Projects/Modals/AddProjectModal';
import ChangeProjectAccessModal from '../Projects/Modals/ChangeProjectAccessModal';
import DeleteProjectModal from '../Projects/Modals/DeleteProjectModal';
import { ProjectActivityModal } from '../Projects/Modals/ProjectActivityModal';
import { ProjectChangeRolePrompt } from '../Projects/Modals/ProjectChangeRolePrompt';
import { ProjectLastAdminNotice } from '../Projects/Modals/ProjectLastAdminNotice';
import ProjectMembersModal from '../Projects/Modals/ProjectMembersModal';
import ProjectMetadataModal from '../Projects/Modals/ProjectMetadataModal';
import ProjectSettingsModal from '../Projects/Modals/ProjectSettingsModal';
import RemoveProjectMemberModal from '../Projects/Modals/RemoveProjectMemberModal';
import EditNewProjectMetadata from './Edit/EditNewProjectMetadata';

import ConfirmDiscardUnsavedMetaDataModal from '../MetaData/Modals/ConfirmDiscardUnsavedMetaDataModal';
import EditMetadata from './Edit/EditMetadata';

// invite members
import ChangeRoleConfirmationModal from '../AccountPages/Modals/ChangeRoleConfirmationModal';
import RegistrationSucceededModal from '../AccountPages/Modals/RegistrationSucceededModal';
import RevokeInviationConfirmationModal from '../AdminCenter/views//WorkspaceMembersView/modals/RevokeInvitationConfirmationModal';
import WorkspaceMemberInvitedModal from '../AdminCenter/views//WorkspaceMembersView/modals/WorkspaceMemberInvitedModal';
import InviteMemberModal from '../AdminCenter/views/WorkspaceMembersView/modals/InviteMemberModal';

// file requests
import FileRequestNewModal from '../FileRequest/Modals/FileRequestNewModal';
import { FileRequestOverviewModal } from '../FileRequest/Modals/FileRequestOverviewModal';
import FileRequestSettingsModal from '../FileRequest/Modals/FileRequestSettingsModal';

// share link
import ConfirmationDeleteLink from '../ShareLink/Modals/ConfirmationDeleteLink';
import ConfirmationNewLink from '../ShareLink/Modals/ConfirmationLinkSent';
import ConfirmationRemoveAccess from '../ShareLink/Modals/ConfirmationRemoveAccess';
import ShareLinkInvitePeopleModal from '../ShareLink/Modals/InvitePeopleModal'; // TODO consider renaming
import ManageAccessModal from '../ShareLink/Modals/ManageAccessModal';
import ShareNewLinkDownloadFormatsModal from '../ShareLink/Modals/ShareDownloadAdvancedFormatsModal';
import ShareLinkOverView from '../ShareLink/Modals/ShareLinkOverviewModal';
import LinkSettingsModal from '../ShareLink/Modals/ShareLinkSettingsModal';
import ShareNewLinkModal from '../ShareLink/Modals/ShareNewLinkModal';

// review link
import { ReviewLinkNewModal } from '../ReviewLink/Modals/ReviewLinkNewModal';
import { ReviewLinkOverviewModal } from '../ReviewLink/Modals/ReviewLinkOverviewModal';
import { ReviewLinkSettingsModal } from '../ReviewLink/Modals/ReviewLinkSettingsModal';

// contracts
import { AttachmentsUploadModal } from '../../../forms/helpers/fields/AttachmentsField';
import { AttachContractPrompt } from '../Contracts/modals/AttachContractPrompt';
import { AttachExistingContractModal } from '../Contracts/modals/AttachExistingContractModal';
import { AttachmentPreviewModal } from '../Contracts/modals/AttachmentPreviewModal';
import { CreateContractModal } from '../Contracts/modals/CreateContractModal';
import { DeleteContractPrompt } from '../Contracts/modals/DeleteContractPrompt';
import { DetachContractPrompt } from '../Contracts/modals/DetachContractPrompt';
import { DiscardContractChangesPrompt } from '../Contracts/modals/DiscardContractChangesPrompt';
import { EditContractModal } from '../Contracts/modals/EditContractModal';
import { ManageContractModal } from '../Contracts/modals/ManageContractModal';
import { ProjectContractsModal } from '../Contracts/modals/ProjectContractsModal';
// exports
import { AllFilesModal } from '../Exports/modals/AllFilesModal';
import { ExportBroadcastLegacyModal } from '../Exports/modals/ExportBroadcastLegacyModal';
import { ExportStatusModal } from '../Exports/modals/ExportStatusModal';
import { SocialExportOptionsModal } from '../Exports/modals/SocialExportOptionsModal';
import TVadExportDataModal from '../Exports/modals/TVadExportDataModal';
import TVadExportDestinationModal from '../Exports/modals/TVadExportDestinationModal';
import { TVadExportQualityCheckModal } from '../Exports/modals/TVadExportQualityCheckModal';
import { LoggedOutAlertModal } from '../Workspace/prefabs/LoggedOutAlertModal';

// tags
import { CreateTagCategoryModal } from '../TagManagement/modals/CreateTagCategoryModal';
import { CreateTagsModal } from '../TagManagement/modals/CreateTagsModal';
import { DeleteTagCategoryModal } from '../TagManagement/modals/DeleteTagCategoryModal';
import { DeleteTagModal } from '../TagManagement/modals/DeleteTagModal';
import { MoveTagsNotice } from '../TagManagement/modals/MoveTagsNotice';
import { RenameTagCategoryModal } from '../TagManagement/modals/RenameTagCategoryModal';
import { DiscardTagsChangesPrompt } from '../Tags/modals/DiscardTagsChangesPrompt';
import { EditTagModal } from '../Tags/modals/EditTagModal';
import { ManageTagsModal } from '../Tags/modals/ManageTagsModal';
import { RemoveTagFromAssetsNotice } from '../Tags/modals/RemoveTagFromAssetsNotice';

// workspace settings
import { EditContractExpirationModal } from '../AdminCenter/views/WorkspaceSettingsView/modals/EditContractExpiration';
import { EditFilerequestExpirationModal } from '../AdminCenter/views/WorkspaceSettingsView/modals/EditFilerequestExpiration';
import { EditShareDownloadEnabledModal } from '../AdminCenter/views/WorkspaceSettingsView/modals/EditShareDownloadEnabled';
import { EditShareDownloadTypeModal } from '../AdminCenter/views/WorkspaceSettingsView/modals/EditShareDownloadType';
import { EditShareExpirationDurationModal } from '../AdminCenter/views/WorkspaceSettingsView/modals/EditShareExpirationDuration';
import { EditShareExpirationEnabledModal } from '../AdminCenter/views/WorkspaceSettingsView/modals/EditShareExpirationEnabled';
import { EditSharePasswordModal } from '../AdminCenter/views/WorkspaceSettingsView/modals/EditSharePassword';
import { EditWorkspaceDefaultLabeling } from '../AdminCenter/views/WorkspaceSettingsView/modals/EditWorkspaceDefaultLabeling';
import { EditWorkspaceNameModal } from '../AdminCenter/views/WorkspaceSettingsView/modals/EditWorkspaceNameModal';

import { EditShareShowDatesModal } from '../AdminCenter/views/WorkspaceSettingsView/modals/EditShareShowDates';
import { EditShareShowTagsModal } from '../AdminCenter/views/WorkspaceSettingsView/modals/EditShareShowTags';

import { DiscardConfigChangesPrompt } from '../AdminCenter/views/WorkspaceIntegrationsView/modals/DiscardConfigChangesModal';
import { ManageIntegrationModal } from '../AdminCenter/views/WorkspaceIntegrationsView/modals/ManageIntegrationModal';
import { ConnectedAccountsModal } from '../Exports/modals/ConnectedAccountsModal';

import { GenericModalNotice } from '@cube3/ui/src/modals/GenericNoticeModalUI';
import { LogoutMultiple } from '../AccountPages/Modals/LogoutMultiple';
import WorkspaceMembersModal from '../AdminCenter/views/WorkspaceMembersView/modals/WorkspaceMembersModal';
import { EditShareAllowRequestingModal } from '../AdminCenter/views/WorkspaceSettingsView/modals/EditShareAllowRequesting';
import { EditShareRequireVerificationModal } from '../AdminCenter/views/WorkspaceSettingsView/modals/EditShareExpirationRequireVerification';
import AddNewTeamModal from '../AdminCenter/views/WorkspaceTeamsView/modals/AddNewTeamModal';
import AddTeamMembersModal from '../AdminCenter/views/WorkspaceTeamsView/modals/AddTeamMembersModal';
import { DeleteTeamModal } from '../AdminCenter/views/WorkspaceTeamsView/modals/DeleteTeamModal';
import { ManageTeamMembersModal } from '../AdminCenter/views/WorkspaceTeamsView/modals/ManageTeamMembersModal';
import { RemoveTeamMemberConfirmModal } from '../AdminCenter/views/WorkspaceTeamsView/modals/RemoveTeamMemberConfirmModal';
import TeamSettingsModal from '../AdminCenter/views/WorkspaceTeamsView/modals/TeamSettingsModal';
import { DisclaimerModal } from '../ContentPane/ContentTree/prefabs/DisclaimerModal';
import { SocialExportModal } from '../Exports/modals/SocialExportModal';
import { ChangeProjectTeamRoleModal } from '../Projects/Modals/ChangeProjectTeamRoleModal';
import { RemoveProjectTeamModal } from '../Projects/Modals/RemoveProjectTeamModal';
import { ShareSettingsModal } from '../shares/ShareSettingsModal';
import { CreateOrEditUrlModal } from './Edit/CreateOrEditUrlModal';
// uploads
import { ReplaceFilePrompt } from './Uploads/ReplaceFilePrompt';
import { MaximumFileSizeExceededNotice } from './Uploads/MaximumFileSizeExceededNotice';

const common = {
  generic_notice: GenericModalNotice
} as const;

const shareLinkModals = {
  sharelink_settings: LinkSettingsModal,
  sharelink_delete: ConfirmationDeleteLink,
  sharelink_overview: ShareLinkOverView,
  sharelink_manageaccess: ManageAccessModal,
  sharelink_invitepeople: ShareLinkInvitePeopleModal,
  confirm_link_sent: ConfirmationNewLink,
  sharelink_new: ShareNewLinkModal,
  sharelink_new_download: ShareNewLinkDownloadFormatsModal,
  sharelink_removeaccess: ConfirmationRemoveAccess,
  // new consolidated settings modal
  ShareSettings: ShareSettingsModal
} as const;

const reviewLinkModals = {
  reviewlink_new: ReviewLinkNewModal,
  reviewlink_overview: ReviewLinkOverviewModal,
  reviewlink_settings: ReviewLinkSettingsModal
} as const;

const fileRequestModals = {
  filerequest_settings: FileRequestSettingsModal,
  filerequest_overview: FileRequestOverviewModal,
  filerequest_change_name: RenamePrompt,
  filerequest_new: FileRequestNewModal
} as const;

const accountSettingsModals = {
  accountsettings_changeEmail: ChangeEmailModal,
  accountsettings_changeName: ChangeNameModal,
  accountsettings_changePassword: ChangePasswordModal,
  accountsettings_changeProfilePicture: ChangeProfilePictureModal,
  accountsettings_ProfilePicture_error: ChangeProfilePictureErrorNotice,
  accountsettings_changePasswordConfirmed: ChangePasswordConfirmedModal,
  accountsettings_confirmEmail: ConfirmEmailModal,
  accountsettings_changeMailPreference: ChangeMailPreferenceModal,
  reset_password_sent: ResetPassWordSent,
  cookie_settings: CookieSettingsModal,
  connected_accounts_pick_workspace: ConnectAccountPickWorkspaceModal,
  connected_accounts_confirm_remove: ConfirmRemoveConnectedAccountModal,
  connected_accounts_confirm_revoke: ConfirmRevokeConnectedAccountModal,
  connected_accounts_modal: ConnectedAccountsModal,
  session_logout_multiple: LogoutMultiple
} as const;

const projectsModals = {
  projects_activity: ProjectActivityModal,
  projects_settings: ProjectSettingsModal,
  projects_members: ProjectMembersModal,
  projects_changeName: RenamePrompt,
  projects_deleteproject: DeleteProjectModal,
  projects_change_access: ChangeProjectAccessModal,
  projects_addMembers: AddProjectMembersModal,
  projects_removemember: RemoveProjectMemberModal,
  projects_metadata: ProjectMetadataModal,
  projects_add: AddProjectModal,
  projects_change_role: ProjectChangeRolePrompt,
  projects_last_admin: ProjectLastAdminNotice,
  projects_change_team_role: ChangeProjectTeamRoleModal,
  projects_remove_team: RemoveProjectTeamModal
} as const;

const workspaceModals = {
  workspace_members: WorkspaceMembersModal,
  workspace_inviteMember: InviteMemberModal,
  workspace_memberInvited: WorkspaceMemberInvitedModal,
  'workspaceMembers/changeWorkspaceRole': ChangeWorkspaceRoleModal,
  'workspaceMembers/addToProjects': WorkspaceMemberAddToProjectsModal,
  'workspaceMembers/addedToProjectsConfirmation':
    AddedToProjectsConfirmationModal,
  'workspaceMembers/removeMemberPrompt': RemoveWorkspaceMemberPrompt,
  change_role_confirmation: ChangeRoleConfirmationModal,
  registration_succeeded: RegistrationSucceededModal,
  revoke_invitation: RevokeInviationConfirmationModal,
  logged_out_alert: LoggedOutAlertModal,
  workspace_edit_name: EditWorkspaceNameModal,
  workspace_edit_share_expiration_enabled: EditShareExpirationEnabledModal,
  workspace_edit_share_require_verification: EditShareRequireVerificationModal,
  workspace_edit_share_allow_requesting: EditShareAllowRequestingModal,
  workspace_edit_share_expiration_duration: EditShareExpirationDurationModal,
  workspace_edit_contract_expiration: EditContractExpirationModal,
  workspace_edit_share_password: EditSharePasswordModal,
  workspace_edit_share_download_enabled: EditShareDownloadEnabledModal,
  workspace_edit_share_download_type: EditShareDownloadTypeModal,
  workspace_edit_filerequest_duration: EditFilerequestExpirationModal,
  workspace_manage_integration: ManageIntegrationModal,

  workspace_edit_allow_labeling: EditWorkspaceDefaultLabeling,
  workspace_edit_share_show_dates: EditShareShowDatesModal,
  workspace_edit_share_show_tags: EditShareShowTagsModal,

  integration_discard_config_changes: DiscardConfigChangesPrompt,
  // teams
  workspace_teams_add_new: AddNewTeamModal,
  workspace_teams_settings: TeamSettingsModal,
  workspace_teams_manage_members: ManageTeamMembersModal,
  workspace_teams_delete: DeleteTeamModal,
  workspace_teams_add_members: AddTeamMembersModal,
  workspace_teams_remove_members: RemoveTeamMemberConfirmModal,
  // project templates
  workspace_project_template_duplicate: DuplicateProjectTemplateModal,
  workspace_project_template_delete: DeleteProjectTemplateModal,
  workspace_project_template_rename: RenameProjectTemplateModal,
  workspace_project_template_create: CreateProjectTemplateModal,
  workspace_project_template_settings: ProjectTemplateSettingsModal
} as const;

const contentTreeModals = {
  assets_delete: DeletePrompt,
  add_folder: AddFolderPrompt,
  asset_rename: RenamePrompt,
  download_assets: DownloadItemsModal,
  asset_url_create_or_edit: CreateOrEditUrlModal,
  // trash bin
  assets_delete_permanent: DeletePermanentlyPrompt,
  asset_cannot_open: CannotOpenItemModal,
  asset_url_disclaimer: DisclaimerModal
} as const;

const metadataModals = {
  metadata_edit: EditMetadata,
  metadata_edit_required_fields: EditNewProjectMetadata,
  metadata_confirmDiscardChanges: ConfirmDiscardUnsavedMetaDataModal,
  attachments_field: AttachmentsUploadModal,
  attachment_preview: AttachmentPreviewModal
} as const;

const exportModals = {
  export_broadcast_legacy: ExportBroadcastLegacyModal,
  social_export: SocialExportModal,
  social_export_options: SocialExportOptionsModal,
  export_options__all_files: AllFilesModal,
  export_status: ExportStatusModal,
  export_TVad_destination: TVadExportDestinationModal,
  export_TVad_qualityCheck: TVadExportQualityCheckModal,
  export_TVad_data: TVadExportDataModal
} as const;

const contractsModals = {
  manage_contract: ManageContractModal,
  edit_contract: EditContractModal,
  create_contract: CreateContractModal,
  project_contracts: ProjectContractsModal,
  attach_existing_contract: AttachExistingContractModal,
  attach_contract_prompt: AttachContractPrompt,
  delete_contract_prompt: DeleteContractPrompt,
  detach_contract_prompt: DetachContractPrompt,
  discard_contract_changes_prompt: DiscardContractChangesPrompt
} as const;

const tagsModals = {
  manage_tags: ManageTagsModal,
  discard_tags_changes_prompt: DiscardTagsChangesPrompt,
  edit_tag_modal: EditTagModal,
  create_tag_category: CreateTagCategoryModal,
  create_tags_modal: CreateTagsModal,
  delete_tag: DeleteTagModal,
  delete_tag_category: DeleteTagCategoryModal,
  rename_tag_category: RenameTagCategoryModal,
  move_tag_error_notice: MoveTagsNotice,
  remove_tag_from_assets: RemoveTagFromAssetsNotice
} as const;

const uploadModals = {
  replace_file_prompt: ReplaceFilePrompt,
  maximum_file_size_exceeded_notice: MaximumFileSizeExceededNotice
} as const;

export const modalIndex = {
  examplemodal: ExampleModal,
  ...common,
  ...shareLinkModals,
  ...reviewLinkModals,
  ...fileRequestModals,
  ...accountSettingsModals,
  ...projectsModals,
  ...workspaceModals,
  ...contentTreeModals,
  ...metadataModals,
  ...exportModals,
  ...contractsModals,
  ...tagsModals,
  ...uploadModals
  //   bad_request: BadRequestModal,
} as const;

/**
 *  WORKAROUND A CIRCULAR DEPENDENCY ISSUES WITH TYPESCRIPT THAT SHOULDN'T BE A PROBLEM
 */

const names = [
  'generic_notice',
  //
  'sharelink_settings',
  'sharelink_delete',
  'sharelink_overview',
  'sharelink_manageaccess',
  'sharelink_invitepeople',
  'confirm_link_sent',
  'sharelink_new',
  'sharelink_new_download',
  'sharelink_removeaccess',
  //
  'ShareSettings',
  //
  'reviewlink_new',
  'reviewlink_overview',
  'reviewlink_settings',
  //
  'filerequest_settings',
  'filerequest_overview',
  'filerequest_change_name',
  'filerequest_new',
  //
  'accountsettings_changeEmail',
  'accountsettings_changeName',
  'accountsettings_changePassword',
  'accountsettings_changeProfilePicture',
  'accountsettings_ProfilePicture_error',
  'accountsettings_changePasswordConfirmed',
  'accountsettings_confirmEmail',
  'accountsettings_changeMailPreference',
  'reset_password_sent',
  'cookie_settings',
  'connected_accounts_pick_workspace',
  'connected_accounts_confirm_remove',
  'connected_accounts_confirm_revoke',
  'connected_accounts_modal',
  'session_logout_multiple',
  //
  'projects_activity',
  'projects_settings',
  'projects_members',
  'projects_changeName',
  'projects_deleteproject',
  'projects_change_access',
  'projects_addMembers',
  'projects_removemember',
  'projects_metadata',
  'projects_add',
  'projects_change_role',
  'projects_last_admin',
  'projects_change_team_role',
  'projects_remove_team',
  //
  'workspace_settings',
  'workspace_members',
  'workspace_inviteMember',
  'workspace_memberInvited',
  'workspaceMembers/changeWorkspaceRole',
  'workspaceMembers/addToProjects',
  'workspaceMembers/addedToProjectsConfirmation',
  'workspaceMembers/removeMemberPrompt',
  'change_role_confirmation',
  'registration_succeeded',
  'revoke_invitation',
  'logged_out_alert',
  'workspace_edit_name',
  'workspace_edit_share_expiration_enabled',
  'workspace_edit_share_expiration_duration',
  'workspace_edit_share_show_dates',
  'workspace_edit_share_show_tags',
  'workspace_edit_share_require_verification',
  'workspace_edit_share_allow_requesting',
  'workspace_edit_contract_expiration',
  'workspace_edit_share_password',
  'workspace_edit_share_download_enabled',
  'workspace_edit_share_download_type',
  'workspace_edit_filerequest_duration',
  'workspace_manage_integration',
  'integration_discard_config_changes',
  'workspace_project_template_duplicate',
  'workspace_project_template_delete',
  'workspace_project_template_rename',
  'workspace_project_template_create',
  'workspace_project_template_settings',
  'workspace_edit_allow_labeling',
  //
  'workspace_teams_add_new',
  'workspace_teams_settings',
  'workspace_teams_manage_members',
  'workspace_teams_delete',
  'workspace_teams_add_members',
  'workspace_teams_remove_members',
  //
  'assets_delete',
  'add_folder',
  'asset_rename',
  'asset_url_create_or_edit',
  'download_assets',
  'assets_delete_permanent',
  'asset_cannot_open',
  'asset_url_disclaimer',
  //
  'metadata_edit',
  'metadata_edit_required_fields',
  'metadata_confirmDiscardChanges',
  'attachments_field',
  'attachment_preview',
  //
  'export_broadcast_legacy',
  'social_export',
  'social_export_options',
  'export_options__all_files',
  'export_status',
  'export_TVad_destination',
  'export_TVad_qualityCheck',
  'export_TVad_data',
  //
  'manage_contract',
  'edit_contract',
  'create_contract',
  'project_contracts',
  'attach_existing_contract',
  'attach_contract_prompt',
  'delete_contract_prompt',
  'detach_contract_prompt',
  'discard_contract_changes_prompt',
  // tags
  'manage_tags',
  'discard_tags_changes_prompt',
  'edit_tag_modal',
  'create_tag_category',
  'create_tags_modal',
  'delete_tag_category',
  'delete_tag',
  'rename_tag_category',
  'move_tag_error_notice',
  'remove_tag_from_assets',
  // uploads
  'replace_file_prompt',
  'maximum_file_size_exceeded_notice'
] as const;

export type ModalNames = (typeof names)[number];
