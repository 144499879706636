import React, { useCallback, useRef } from 'react';

import ImageThumbnail from './Image-thumbnail';
import { ImageThumbnailFit } from './Image-thumbnail-fit';
import ThumbnailImageContainer, {
  FillVariant
} from './Thumbnail-image-container';

import { ThumbnailContainerRatio } from './ThumbnailContainerRatio';
import { ScrollableThumbnail } from '@cube3/cubicle/src/core/atoms/Thumbnail/ScrollableThumbnail';
import { useImageDimensions } from '../Hooks/useImageDimensions';
import {
  DisplayImageResourceConfig,
  getNodeDisplayImageSrc
} from '../human/nodeDisplayImageResolver';

export type ThumbnailRatioType =
  | 'oneToOne'
  | 'fourToThree'
  | 'sixteenToNine'
  | 'sixteenToEleven';

interface Properties {
  resource?: DisplayImageResourceConfig;
  src?: string;
  cover: boolean;
  fillVariant?: FillVariant;
  ratio: ThumbnailRatioType;
  showCheckerboard?: boolean;
  widthLagerThanHeight?: any;

  /** If `bordered` is true, a special `Surface` wrapper will be used, defaults to `false`  */
  bordered?: boolean;
  scrollable?: boolean;
  stripSrc?: string;
}

/**
 *  Thumbnail component for use in libraryview.
 *  Can autofill frame with different ratios.
 */

const Thumbnail: React.FunctionComponent<Properties> = (props) => {
  const {
    resource,
    src: thumbnail,
    fillVariant = 'dark',
    showCheckerboard,
    cover,
    ratio,
    widthLagerThanHeight,
    bordered = false,
    stripSrc
  } = props;

  const src = resource ? getNodeDisplayImageSrc(resource) : thumbnail;

  const thumbRef = useRef(null);

  const size = useImageDimensions(src);

  const handleGetLimits = useCallback(() => {
    if (!stripSrc || !thumbRef.current) {
      return;
    }
    const bb = thumbRef.current?.getBoundingClientRect();
    const bbp = thumbRef.current?.parentElement?.getBoundingClientRect();
    if (!bb || !bbp) {
      return;
    }
    return {
      top: bb.top - bbp.top,
      left: bb.left - bbp.left,
      maxWidth: bb.width,
      maxHeight: bb.height
    };
  }, [stripSrc]);

  return (
    <ThumbnailContainerRatio ratio={ratio}>
      <ThumbnailImageContainer
        fillVariant={fillVariant}
        addShadow={showCheckerboard}
      >
        {src &&
          (cover ? (
            <ImageThumbnail
              bordered={bordered}
              src={src}
              onContextMenu={(e) => e.preventDefault()}
              widthLargerThanHeight={widthLagerThanHeight}
              showCheckerboard={showCheckerboard}
              ref={thumbRef}
            />
          ) : (
            <ImageThumbnailFit
              src={src}
              onContextMenu={(e) => e.preventDefault()}
              showCheckerboard={showCheckerboard}
              ref={thumbRef}
            />
          ))}
        {stripSrc && (
          <ScrollableThumbnail
            src={stripSrc}
            count={40}
            getLimits={handleGetLimits}
            forceRatio={size ? size.width / size.height : undefined}
          />
        )}
      </ThumbnailImageContainer>
    </ThumbnailContainerRatio>
  );
};

export default Thumbnail;
