import React from 'react';
import { AssetProcessingStates } from '@cube3/common/model/types';
import { UploadUIStates } from '../../core/atoms/ProgressWithText';

/**
 * Provides a wap to map the processing status received from the API to the internal status that we are using within the UI components.
 */

const mapping: { [key in AssetProcessingStates]: UploadUIStates } = {
  AssetProcessingStatusComplete: 'processing_complete',
  AssetProcessingStatusFailed: 'processing_failed',
  AssetProcessingStatusPending: 'processing_pending',
  AssetProcessingStatusProcessing: 'processing',
  AssetProcessingStatusComposing: 'composing',
  AssetProcessingStatusScanning: 'processing_pending', // make separate?
  AssetProcessingStatusUploading: 'uploading',
  AssetProcessingStatusUnknown: undefined,
  AssetUploadStatusPaused: 'paused',
  AssetUploadStatusPending: 'upload_pending',
  AssetUploadStatusFailed: 'upload_failed',
  AssetUploadStatusUplodadInitFailed: 'upload_initialization_failed',
  AssetUploadStatusUplodadMissing: 'upload_missing'
};

const map = Object.values(mapping);

const useAssetProcessingStates = (processingStatus) => {
  if (map.includes(processingStatus)) {
    return processingStatus;
  }
  return mapping[processingStatus];
};

export default useAssetProcessingStates;
