import { ResourceBase, ResourceType } from '../../resource-types';
import { RawModel } from '../schema';

export interface SearchResult extends ResourceBase {
  display_name: string;
  display_image: string;
  resource_type: ResourceType;
  resource_id: string;
  context_resource: {
    type: 'project' | 'library' | 'workspace';
    id: string;
  };
  parent_folder: string;
}

export const searchResult: RawModel = {
  type: 'search-result',
  plural: 'search-results',
  JSONApi: true,
  attributes: {
    id: 'UUID',
    display_name: 'string'
    // display_image: 'URL'
  },
  relationships: {}
};
