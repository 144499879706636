/* method to get the container width minus padding and other stuff that takes up space
    - if the container has a max width limit, truncate the text even if there is more space available
   */
export const getAvailableWidth = (
  containerEl: HTMLElement,
  padding: number,
  widthOfWidestLetter: number,
  suffixEl?: HTMLElement,
  forceWidth?: number,
  maxWidth?: number,
  minWidth?: number
) => {
  const suffixWidth = suffixEl?.clientWidth ?? 0;

  /** TODO: the suffix should only take up the width of the last line */
  const containerWidth = forceWidth
    ? forceWidth
    : Math.min(
        Math.max(minWidth ?? 0, containerEl.clientWidth),
        maxWidth ?? containerEl.clientWidth
      );

  return containerWidth - padding - suffixWidth - widthOfWidestLetter;
};
