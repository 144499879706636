import { ResourceBase } from '../../resource-types';
import { RawModel } from '../schema';

export interface Role extends ResourceBase {
  display_name?: string;
  billable: boolean;
  is_admin: boolean;
}

export const role: RawModel = {
  type: 'role',
  plural: 'roles',
  JSONApi: true,
  attributes: {
    id: 'UUID'
  },
  relationships: {
    privileges: {
      type: 'privilege',
      binding: ['many', 'many'],
      reverse: 'roles'
    }
  }
};
