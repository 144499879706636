import React from 'react';
import { makeCSS } from '../../../utils/makeCSS';
import { SizeVariants } from '../../../theme/themes';
import { cx } from '@emotion/css';
import {
  DisplayImageResourceConfig,
  getNodeDisplayImageSrc
} from '../../../../../ui/src/human/nodeDisplayImageResolver';
import { FileIcons } from '../FileIcons/FileIcons';
import { Icon } from '../Icon';
import { fileIcons } from '../FileIcons/files';
import { iconFiles } from '../Icon/files';

/**
 * The Thumbnail is an image that represents a resource.
 * If the resource is an image or video asset it will display a preview image for them.
 * If the resource is a folder or other asset type it will display the icon from the FileIcons component that best represents it.
 */

export interface ThumbnailProps {
  /**Different sizes */
  size?: SizeVariants.sm | SizeVariants.md | SizeVariants.lg | 'fill';
  /**Icon of the file */
  fileIcon?: string;
  /**The type of content the thumbnail can contain */
  content?: 'file' | 'placeholder';
  /**The src of the image that will be diplayed inside the placeholder*/
  src?: string;
  /**It gives as an image based on the asset type. The image can be resolved based on the passed type*/
  resource?: DisplayImageResourceConfig;
}

const useCSS = makeCSS(({ theme }) => {
  return {
    container: {
      position: 'relative',
      paddingTop: '56.25%' //16:9
    },
    root: {
      top: 0,
      left: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexShrink: 0,
      borderRadius: theme.borderRadius.lg,
      background: theme.color.underlay['01']
    },
    /*sizes*/
    'size-sm': {
      position: 'relative',
      borderRadius: theme.borderRadius.md,
      width: theme.spacing[12],
      height: theme.spacing[7]
    },
    'size-md': {
      position: 'relative',
      width: theme.spacing[16],
      height: theme.spacing[9]
    },
    'size-lg': {
      position: 'relative',
      width: theme.spacing[56],
      height: theme.spacing[32]
    },
    'size-fill': {
      position: 'absolute',
      width: '100%',
      height: '100%'
    },

    /*content types*/
    file: {
      justifyContent: 'center'
    },
    placeholder: {
      display: 'flex',
      objectFit: 'contain'
    },
    placeholderDiv: {
      display: 'inline-flex',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      overflow: 'hidden',

      '& img': {
        flexShrink: 1,
        flexGrow: 1,
        objectFit: 'contain',
        maxWidth: '100%',
        height: '100%'
      }
    }
  };
});

export const Thumbnail = (props: ThumbnailProps) => {
  const {
    size = SizeVariants.md,
    fileIcon = 'folder',
    content = 'placeholder',
    src: thumbnail,
    resource
  } = props;

  let iconSize = SizeVariants.md;
  size === 'fill' ? (iconSize = SizeVariants.xl) : (iconSize = size);

  const classes = useCSS();

  const src = thumbnail || getNodeDisplayImageSrc(resource);

  return (
    <div className={cx(size === 'fill' ? classes.container : '')}>
      <div
        className={cx(
          classes.root,
          classes[`size-${size}`],
          content === 'file' ? classes.file : classes.placeholder
        )}
      >
        {content === 'placeholder' ? (
          <div className={`${classes.placeholderDiv}`}>
            <img src={src} />
          </div>
        ) : fileIcons[fileIcon] ? (
          <span>
            <FileIcons fileType={fileIcon} size={iconSize} />
          </span>
        ) : (
          <Icon icon={fileIcon as keyof typeof iconFiles} />
        )}
      </div>
    </div>
  );
};
