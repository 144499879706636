import React from 'react';
import { Button } from '@cube3/cubicle/src/core/atoms/Button';
import { Tooltip } from '@cube3/cubicle/src/core/atoms/Tooltip/Tooltip';

interface Props {
  fullscreen: boolean;
  shortCutTip?: string;
  toggleFullscreenAction(): void;
}

export const FullscreenButton: React.FC<Props> = React.memo((props) => {
  const { fullscreen, shortCutTip, toggleFullscreenAction } = props;

  return fullscreen ? (
    <Tooltip
      content={shortCutTip && `Exit full screen (${shortCutTip})`}
      placement="bottom-start"
      useArrow={false}
    >
      <Button
        aria-label="leave fullscreen"
        background="secondary"
        buttonStyle="ghost"
        iconLeft={'fullscreen_exit'}
        onClick={toggleFullscreenAction}
      />
    </Tooltip>
  ) : (
    <Tooltip
      content={shortCutTip && `Full screen (${shortCutTip})`}
      placement="bottom-start"
      useArrow={false}
    >
      <Button
        aria-label="enter fullscreen"
        background="secondary"
        buttonStyle="ghost"
        iconLeft={'fullscreen'}
        onClick={toggleFullscreenAction}
      />
    </Tooltip>
  );
});
