import React, { useMemo } from 'react';
import { TagCategory } from './TagCategory';
import { ChipProps } from './Tag';
import { EditableTag } from './types';

interface Props extends Pick<ChipProps, 'size'> {
  tags: Array<Partial<EditableTag>>;
}

/** Component to list tags by category name
 * - e.g. `[tag1,tag2,tag3] -> {category1: [tag1,tag2], category2: [tag3]}`
 */
export const TagsList = ({ tags, size }: Props) => {
  const grouped = useMemo(() => {
    if (!tags?.length) return {};
    return tags.reduce((acc, tag) => {
      const cat = tag.category?.display_name;
      if (!acc[cat]) {
        acc[cat] = [tag];
      } else {
        acc[cat].push(tag);
      }
      return acc;
    }, {});
  }, [tags]);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        flexWrap: 'wrap'
      }}
    >
      {Object.keys(grouped).map((cat, idx) => (
        <TagCategory
          key={cat + '__' + idx}
          category={cat}
          tags={grouped[cat]}
          size={size}
          readonly={grouped[cat]?.[0]?.category?.editable === false}
        />
      ))}
    </div>
  );
};
