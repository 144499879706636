import React, { HTMLAttributes } from 'react';
import { makeCSS, WithTheme } from '../../../../utils/makeCSS';

const useCSS = makeCSS(
  ({
    direction = 'row',
    align = 'stretch',
    justify = 'space-between',
    gap
  }: WithTheme<ContainerProps>) => ({
    root: {
      position: 'relative',
      display: 'flex',
      flexDirection: direction,
      flexWrap: 'nowrap',
      alignItems: align,
      justifyContent: justify,
      gap: gap
      // overflow: 'hidden'
    }
  })
);

interface ContainerProps
  extends React.PropsWithChildren,
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    > {
  // flex direction
  direction: 'row' | 'column';
  align?: 'center' | 'stretch' | 'start' | 'end' | 'baseline';
  justify?:
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'start'
    | 'end';
  // style overrides
  style?: HTMLAttributes<HTMLDivElement>['style'];
  gap?: number;
}
const emptyObj = {};
// Flex container with predefined alignment and justification settings. Add Flex Items components as children
export const Container = (props: ContainerProps) => {
  const {
    children,
    style = emptyObj,
    direction,
    align,
    justify,
    gap,
    ...divProps
  } = props;

  const classes = useCSS(props);

  return (
    <div
      className={classes.root}
      style={{ alignItems: align, gap, ...style }}
      {...divProps}
    >
      {children}
    </div>
  );
};

// Container variant with direction set to column
export const VBox = (props: Omit<ContainerProps, 'direction'>) =>
  Container({ ...props, direction: 'column' });

// Container variant with direction set to row
export const HBox = (props: Omit<ContainerProps, 'direction'>) =>
  Container({ ...props, direction: 'row' });
