import React from 'react';
import { getPopupSubText } from './helpers';
import { ProgressEta } from './ProgressEta';
import { UploadUIStates } from './types';
import { makeCSS } from '../../../utils/makeCSS';

interface Props {
  gridview: boolean;
  isHovered: boolean;
  anchor: HTMLElement;
  onPopoverOpen(event: React.MouseEvent<HTMLDivElement>): void;
  onPopoverClose(event: React.MouseEvent<HTMLDivElement>): void;
  onClickRetryUpload?(): void;
  onClickRemoveUpload?(): void;
  canResume: boolean;
  uploadState: UploadUIStates;
  upload: { progress?: number; tusUpload?: { eta: number } };
}

const useCSS = makeCSS(({ theme }) => {
  return {
    textContainer: {
      width: '70%',
      maxHeight: '50%',
      marginLeft: '5px'
    }
  };
});

/** Displays a popup with the current state of the upload and some additional info, such as the eta */
export const ProgressPopup: React.FunctionComponent<Props> = (props) => {
  const {
    gridview,
    isHovered,
    upload,
    onPopoverClose,
    onPopoverOpen,
    onClickRetryUpload,
    onClickRemoveUpload,
    canResume,
    uploadState,
    anchor
  } = props;

  const classes = useCSS(props);

  // const [popOverHovered, setPopoverHovered] = useState<boolean>(false);

  const headerText = getPopupSubText(isHovered, uploadState);

  if (uploadState === 'processing_failed') {
    return null;
  }

  return (
    <div
      data-type="errorMessage"
      className={gridview ? classes.textContainer : ''}
    >
      <ProgressEta
        isHovered={isHovered}
        eta={upload?.tusUpload?.eta}
        progress={upload?.progress}
        gridview={gridview}
        uploadState={uploadState}
      />
    </div>
  );
};
