import { User } from '@cube3/common/model/schema/resources/user';
import { useMemo } from 'react';
import { Account } from '@cube3/common/model/schema/resources/account';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';

const params = {
  page: { size: -1 }
};
const emptyArray = [];
/** insert user info to accounts */
export const useAccountsUser = (
  accounts: Account[],
  excludedAccounts = emptyArray
) => {
  const [workspaceId] = useCurrentWorkspace();

  const availableAccounts = useMemo(() => {
    if (excludedAccounts.length === 0) {
      return accounts;
    }
    const existedIds = excludedAccounts.map((a) => a.id);
    return accounts?.filter((user) => !existedIds.includes(user.id)) || [];
  }, [accounts, excludedAccounts]);

  const users = useResourceList__ALPHA({
    resourceType: 'workspace',
    resourceId: workspaceId,
    edgeType: 'user',
    edgeLabel: 'users',
    params
  });

  const accountsWithUserInfo = useMemo(() => {
    if (!availableAccounts || !users.resources?.length) {
      return [];
    }

    return availableAccounts.map((ac) => {
      const user: User = users.resources.filter(
        (u) =>
          u.id ===
          (ac.relationships.user.data
            ? ac.relationships.user.data.id
            : ac.relationships.user.id)
      )[0];
      return {
        ...ac,
        user_id: user.id,
        full_name: user.full_name,
        email_address: user.email_address,
        profile_picture: user.profile_picture
      };
    });
  }, [availableAccounts, users.resources]);

  return useMemo(
    () => ({ resources: accountsWithUserInfo, loading: users.loading }),
    [users.loading, accountsWithUserInfo]
  );
};
