import React, { useCallback, useEffect, useState } from 'react';
import { ModalNoticeUI } from '@cube3/ui/src/Modal/ModalNoticeUI';
import { useDeleteResource__ALPHA } from '@cube3/state/src/redux/components/Hooks/useDeleteResource';
import { useModalActions } from '@cube3/state/src/redux/Hooks/useModalActions';
import { statuses } from '@cube3/state/src/redux/ducks/request-status';
import Button from '@cube3/cubicle/src/core/atoms/Button/Button';
import { hasResolved } from '@cube3/state/src/redux/ducks/request-status/utils/hasResolved';
import { useCurrentAccount } from '@cube3/state/src/redux/components/Administration/withCurrentUserAccount';
import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { Select } from '@cube3/cubicle/src/core/atoms/Select/Select';
import { VBox } from '@cube3/cubicle/src/core/templates/layout/Flex';
import { Avatar } from '@cube3/cubicle/src/core/atoms/Avatar';
import { Typography } from '@cube3/cubicle/src/core/atoms/Typography';
import { SizeVariants } from '@cube3/cubicle/src/theme/themes';
import { Option } from '@cube3/cubicle/src/core/atoms/Select/Option';
import { getErrorText } from '../../../../Projects/Modals/AddProjectModal';
import { Icon } from '@cube3/cubicle/src/core/atoms/Icon';
import { useTransferOwnership } from './hooks/useTransferOwnership';
import { useWorkspaceAdmins } from './hooks/useWorkspaceAdmins';
import { pluralize } from '@cube3/common/utils/pluralize';
import { useLogout } from '../../../../AccountPages/hooks/useLogout';

interface ModalProps {
  modalContext: {
    accountId: string;
  };
}
const OPTION_DELETE = 'option_delete';

const RemoveWorkspaceMemberPrompt: React.FC<ModalProps> = (props) => {
  const { accountId } = props.modalContext;

  const [currentAccount] = useCurrentAccount();

  const { previousModal } = useModalActions();
  const [workspaceId] = useCurrentWorkspace();

  const admins = useWorkspaceAdmins(accountId);

  const getOptionValue = useCallback(
    (id: string) => {
      if (!id || !admins.resources) return '';
      return admins.resources.find((adm) => adm.id === id)?.full_name;
    },
    [admins.resources]
  );

  const isCurrentAccount = currentAccount === accountId;
  // account id
  const [selectedAdmin, setSelectedAdmin] = useState(undefined);

  // if is current user, select the first admin by default
  const firstAccountId = admins.resources?.[0]?.id;
  useEffect(() => {
    if (!selectedAdmin && firstAccountId) {
      setSelectedAdmin(isCurrentAccount ? firstAccountId : currentAccount);
    }
  }, [isCurrentAccount, firstAccountId, selectedAdmin]);

  const toUser = selectedAdmin === OPTION_DELETE ? undefined : selectedAdmin;
  const {
    projectsCount,
    shareLinksCount,
    reviewLinksCount,
    fileRequestsCount,
    hasAnyShares,
    transferProjectsError,
    transferProjectsStatus,
    transferSharesError,
    transferSharesStatus,
    loading: retrievingData,
    onTransferOwnnership
  } = useTransferOwnership(accountId, toUser);

  const [startTransfer, setStartTransfer] = useState(false);

  const [deleteMember, deleteMemberStatus] = useDeleteResource__ALPHA({
    cacheInvalidator: useCallback(
      () => [
        {
          type: 'workspace',
          id: workspaceId,
          relationship: 'accounts'
        }
      ],
      [workspaceId]
    )
  });

  const onDeleteAccount = useCallback(() => {
    deleteMember({ type: 'account', id: accountId });
  }, [accountId, deleteMember]);

  const onSubmit = useCallback(() => {
    if (selectedAdmin === OPTION_DELETE) {
      onDeleteAccount();
    } else {
      onTransferOwnnership();
      setStartTransfer(true);
    }
  }, [selectedAdmin, onDeleteAccount, onTransferOwnnership]);

  // if user intent to transfer ownership before delete member, should
  // - firstly transfer ownership to seleted admin
  // - delete the member if transfer is succeeded
  useEffect(() => {
    if (
      startTransfer &&
      (projectsCount ? transferProjectsStatus === statuses.SUCCESS : true) &&
      (hasAnyShares ? transferSharesStatus === statuses.SUCCESS : true)
    ) {
      onDeleteAccount();
      setStartTransfer(false);
    }
  }, [
    startTransfer,
    projectsCount,
    hasAnyShares,
    transferProjectsStatus,
    transferSharesStatus,
    onDeleteAccount
  ]);

  const { logout, endAllSessions } = useLogout();
  useEffect(() => {
    if (deleteMemberStatus === statuses.SUCCESS) {
      previousModal();
      if (isCurrentAccount) {
        endAllSessions();
        logout();
      }
    }
  }, [deleteMemberStatus, isCurrentAccount]);

  const loading =
    retrievingData ||
    admins.loading ||
    (deleteMemberStatus && !hasResolved(deleteMemberStatus)) ||
    startTransfer;

  const removeMemberText = isCurrentAccount ? 'Leave' : 'Remove';

  const copy = (() => {
    //XX projects, XX shares and XX reviews are associated with this member.
    const areOrIs =
      projectsCount + shareLinksCount + reviewLinksCount + fileRequestsCount > 1
        ? 'are'
        : 'is';
    const projects = pluralize('project', projectsCount);
    const shares = pluralize('share', shareLinksCount);
    const reviews = pluralize('review', reviewLinksCount);
    const fileRequests = pluralize('file request', fileRequestsCount);

    let str = `${projects}, ${shares}, ${reviews} and ${fileRequests} ${areOrIs} associated with this member.`;
    // , 1 share, 1 review and 1 file request ...
    // , , 1 review....
    // , , , and 1 file....
    str = str.replace(/^(,\s+)+(and\s+)?/, '');
    // 1 project, , 1 review and 1 file...
    str = str.replace(/,\s+(,\s+)+/, ', ');
    // 1 project, 1 share, and 1 file...
    str = str.replace(/(,\s+)+and/, ' and');
    // 1 project, 1 share, 1 review and are(is)....
    str = str.replace(/and\s+(are|is)/, areOrIs);
    return str;
  })();

  const hasData = projectsCount > 0 || hasAnyShares;
  const deleteCopy = 'Delete' + ' ' + copy.split(/\s+is|are\s+/)[0].trim();

  return (
    <ModalNoticeUI
      extraSurfaceProperties={{ style: { overflow: 'visible' } }}
      disableScrollBar
      title={removeMemberText}
      loading={loading}
      onCloseEvent={() => previousModal()}
      footerLeftComponent={
        <Button
          label={'Cancel'}
          buttonStyle={'ghost'}
          background={'secondary'}
          onClick={previousModal}
        />
      }
      footerRightComponent={
        <Button
          label={isCurrentAccount ? 'Leave' : 'Remove'}
          buttonStyle="solid"
          background="critical"
          disabled={!!transferProjectsError || !!transferSharesError}
          onClick={onSubmit}
        />
      }
    >
      <VBox gap={16} justify="start">
        <Typography colorVariant="02">
          {isCurrentAccount
            ? 'Are you sure you want to leave workspace? You will no longer have access to this workspace.'
            : 'Are you sure you want to remove this member? They will no longer have access to this workspace.'}
        </Typography>

        {hasData ? (
          <VBox>
            <Typography colorVariant="02">{copy}</Typography>
            <Typography colorVariant="02">
              Transfer ownership otherwise these will get deleted.
            </Typography>
          </VBox>
        ) : null}

        {!hasData ? null : (
          <Select
            value={
              selectedAdmin === OPTION_DELETE
                ? deleteCopy
                : getOptionValue(selectedAdmin)
            }
            label="Select an admin"
          >
            {admins.resources
              ?.filter(
                (acc) => (isCurrentAccount ? acc.id !== currentAccount : true) // prevent from transfering ownship to youself when you intent to leave
              )
              ?.map((adm) => (
                <Option
                  key={adm.id}
                  value={adm.full_name}
                  onClick={() => setSelectedAdmin(adm.id)}
                  startAdornment={
                    <Avatar
                      imageUrl={adm.profile_picture || adm.display_image}
                      userInitial={adm.full_name}
                      size={SizeVariants.sm}
                    />
                  }
                  endAdornment={
                    selectedAdmin === adm.id ? <Icon icon="check" /> : null
                  }
                />
              ))}
            <Option
              key={OPTION_DELETE}
              value={deleteCopy}
              onClick={() => setSelectedAdmin(OPTION_DELETE)}
              startAdornment={<Icon icon="delete" />}
              endAdornment={
                selectedAdmin === OPTION_DELETE ? <Icon icon="check" /> : null
              }
            />
          </Select>
        )}
        {transferProjectsError && (
          <Typography color="critical">
            {getErrorText(transferProjectsError)}
          </Typography>
        )}
        {transferSharesError && (
          <Typography color="critical">
            {getErrorText(transferSharesError)}
          </Typography>
        )}
      </VBox>
    </ModalNoticeUI>
  );
};

export default RemoveWorkspaceMemberPrompt;
