import React, { useCallback } from 'react';
import Button from '@cube3/cubicle/src/core/atoms/Button/Button';
import { Tooltip } from '@cube3/cubicle/src/core/atoms/Tooltip/Tooltip';

interface Props {
  finished: boolean;
  playing: boolean;
  loop: boolean;
  jumpBack(): void;
  toggleLoop(): void;
  replay(): void;
  playPause(): void;
  shortCutTips?: any;
}

export const PlaybackButtons: React.ComponentType<Props> = React.memo(
  (props) => {
    const {
      finished,
      playing,
      loop,
      jumpBack,
      toggleLoop,
      replay,
      playPause,
      shortCutTips
    } = props;

    const handlePlayButtonClick = useCallback(() => {
      finished ? replay() : playPause();
    }, [finished, replay, playPause]);

    return (
      <>
        <Tooltip
          content={
            shortCutTips &&
            `${finished ? 'Replay' : playing ? 'Pause' : 'Play'} (${
              shortCutTips.play
            })`
          }
          placement="bottom-end"
          useArrow={false}
        >
          <Button
            aria-label={'play or pause'}
            buttonStyle="ghost"
            background="secondary"
            iconLeft={playing ? 'pause' : 'play'}
            onClick={handlePlayButtonClick}
          />
        </Tooltip>
        <Tooltip
          content={
            shortCutTips && `Go back 10 seconds (${shortCutTips.jumpBack})`
          }
          placement="bottom"
          useArrow={false}
        >
          <Button
            aria-label={'jump back'}
            buttonStyle="ghost"
            background="secondary"
            iconLeft={'replay_10'}
            onClick={jumpBack}
          />
        </Tooltip>
        <Tooltip
          content={shortCutTips && `Loop (${shortCutTips.loop})`}
          placement="bottom"
          useArrow={false}
        >
          <Button
            aria-label="toggle loop"
            buttonStyle="ghost"
            background="secondary"
            iconLeft={'loop'}
            onClick={toggleLoop}
            stateOverride={loop ? 'active' : 'default'}
          />
        </Tooltip>
      </>
    );
  }
);
