import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';

/* Import components */
import Time from '../../../human/Time';
import { Typography } from '../../../typography/Typography';
import { iconClipShare } from '../../../icons/GeneralSVG';
import IconButton from '../../../Buttons/IconButton';
import InteractableSurface from '../../../Surface/InteractableSurface';
import { ReviewLinkUIProps } from '../Modals/ReviewLinkOverviewModalUI';
import { AvatarList } from '../../../Workspace/subComponents/AvatarList';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: '5px 0px 0px 0px'
    },
    avatars: {
      marginLeft: '5px',
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      height: '20px'
    },
    sameline: {
      display: 'flex',
      alignItems: 'center'
    },
    contents: {
      margin: '4px'
    },
    avatar: {
      marginRight: '2px'
    }
  })
);

const ReviewLinkSmallOverviewItemUI: React.FC<ReviewLinkUIProps> = (props) => {
  const {
    created_at,
    email_addresses = [],
    expires,
    expires_at,
    expired,
    onClick
  } = props;

  const classes = useStyles();

  // map email addresses to elements for container chips.
  const users = React.useMemo(() => {
    return email_addresses?.map((e) => ({ full_name: e })) || [];
  }, [email_addresses]);

  let expiredText: string;
  if (expires && expired) {
    expiredText = 'Link expired ';
  }

  if (expires && !expired) {
    expiredText = 'Will expire ';
  }

  if (!expires) {
    expiredText = 'Never expires';
  }

  return (
    <InteractableSurface
      classes={{
        root: `${classes.container} ${props.classes?.container || ''}`
      }}
      surfaceProps={{ corners: 'rounded' }}
      highlightOnHover={true}
      onClickEvent={() => onClick()}
    >
      <div className={classes.contents} data-cy={'reviewLinkUI'}>
        <div className={classes.sameline}>
          <IconButton
            onClick={() => onClick()}
            path={iconClipShare}
            colorVariant={'ghost2'}
            disableHover={true}
          />
          <div className={classes.avatars}>
            {users.length && (
              <AvatarList users={users} maxCount={5} size={'sm'} />
            )}
          </div>
        </div>
        <div>
          <Typography component="span" color="contrast3">
            Created{' '}
            <Time
              date={created_at}
              fullForm={false}
              toolTip={true}
              placement="bottom"
              now={new Date()}
              relativeTime={true}
            />
          </Typography>

          <Typography
            component="span"
            color={expired && expires ? 'danger1' : 'contrast2'}
          >
            {expiredText}

            {expires && expires_at && (
              <Time
                date={expires_at}
                fullForm={false}
                toolTip={true}
                placement="bottom-start"
                now={new Date()}
                relativeTime={true}
              />
            )}
          </Typography>
        </div>
      </div>
    </InteractableSurface>
  );
};

export default ReviewLinkSmallOverviewItemUI;
