import React from 'react';

import { Typography } from '@cube3/cubicle/src/core/atoms/Typography/Typography';
import { Loader } from '@cube3/cubicle/src/core/atoms/Loader/Loader';
import { getThemeColor, Icon } from '@cube3/cubicle/src/core/atoms/Icon';
import { SizeVariants } from '@cube3/cubicle/src/theme/themes';
import MaybeTooltip from '@cube3/cubicle/src/core/atoms/Tooltip/MaybeTooltip';
import { makeCSS } from '@cube3/cubicle/src/utils/makeCSS';

export type ThumbnailType = {
  thumbnail: string;
  captured_at: number;
};

export interface SnapshotType extends ThumbnailType {
  id: string;
  processing: boolean;
  failed: boolean;
  display_image?: string;
}

interface SnapshotsListProps {
  snapshots: SnapshotType[];
  canCapture: boolean;
  error: string;
  onClickSnapshot: () => void;
  onClickThumbnail: (playedSeconds: number) => void;
}

const useStyles = makeCSS(({ theme }) => ({
  root: {
    backgroundColor: theme.color.black['80'], //rgba(0, 0, 0, 0.8),
    height: 160,
    boxSizing: 'border-box'
  },
  mobile: {},
  helperText: {
    paddingLeft: 24
  },
  listItem: {
    position: 'relative',
    padding: '0 24px',
    animation: '$fade 1s linear'
  },
  imageContainer: {
    borderRadius: '5px',
    border: `solid 1px ${theme.color.white['24']}`, //rgba(255, 255, 255, 0.24)
    maxWidth: 160,
    maxHeight: 90
  },
  image: {
    borderRadius: 'inherit',
    maxWidth: 'inherit',
    maxHeight: 'inherit',
    objectFit: 'contain',
    objectPosition: 'center',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.7
    }
  },
  loadingOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    height: 'auto',
    width: 'auto',
    background: theme.color.black['48'],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    animation: '$fade 1s linear'
  },
  snapshotsList: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    bottom: '0',
    padding: '24px 24px 20px 24px',
    listStyle: 'none',
    justifyContent: 'start',
    flexWrap: 'nowrap',
    height: 90,
    top: 'calc(100% - 90px)',
    left: 0,
    margin: 0,
    overflow: 'auto hidden',
    '&$mobile': {
      padding: theme.spacing['02'], // 8px
      position: 'absolute',
      width: 'calc(100% - 16px)',
      bottom: 0
    }
  },
  snapshotButton: {
    cursor: 'pointer',
    display: 'inline-flex',
    borderRadius: '6px',
    alignItems: 'center',
    textAlign: 'center',
    boxSizing: 'border-box',
    flexDirection: 'column',
    border: `dashed 1px ${theme.color.background.yellow.main}`,
    padding: '12px',
    width: 160,
    height: 90,
    '&:hover': {
      backgroundColor: theme.color.overlay.hovered
    }
  },
  disabled: {
    opacity: 0.5,
    cursor: 'default',
    '&:hover': {
      backgroundColor: 'inherit'
    }
  },

  '@keyframes fade': {
    from: {
      opacity: 0
    },
    to: {
      opacity: 1
    }
  }
}));

export const SnapshotsList: React.FunctionComponent<SnapshotsListProps> = (
  props
) => {
  const {
    onClickSnapshot,
    onClickThumbnail,
    canCapture = false,
    snapshots = [],
    error = undefined
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.helperText}>
        <Typography colorVariant="02">
          Snapshots will be saved to the parent folder
        </Typography>
      </div>

      <ul className={classes.snapshotsList}>
        {snapshots.map((item, index) => {
          const loading = item.processing;
          const failed = error !== undefined;
          return (
            <li className={classes.listItem} key={index}>
              {loading && (
                <div className={classes.loadingOverlay}>
                  <Loader />
                </div>
              )}
              {failed && (
                <MaybeTooltip title={'error'} disabled={false}>
                  <div className={classes.loadingOverlay}>
                    <Icon
                      icon={'error_fill'}
                      customColor={getThemeColor('color.background.critical')}
                      size={SizeVariants.md}
                    />
                  </div>
                </MaybeTooltip>
              )}
              <div className={classes.imageContainer}>
                <img
                  src={item.display_image ? item.display_image : item.thumbnail}
                  alt="snapshot"
                  onClick={() => onClickThumbnail(item.captured_at)}
                  className={classes.image}
                />
              </div>
            </li>
          );
        })}
        <span
          className={`${classes.snapshotButton} ${
            !canCapture ? classes.disabled : ''
          }`}
          onClick={onClickSnapshot}
        >
          <Icon
            icon="camera"
            size={SizeVariants.md}
            customColor={
              canCapture
                ? getThemeColor('color.background.yellow.main')
                : undefined
            }
          />
          <Typography
            customColor={canCapture ? '#ffd423' : ''}
            colorVariant={canCapture ? '01' : '03'}
          >
            Save current frame as new image
          </Typography>
        </span>
      </ul>
    </div>
  );
};
