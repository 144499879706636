import { useCurrentWorkspace } from '@cube3/state/src/redux/components/Administration/withCurrentWorkspace';
import { useResourceList__ALPHA } from '@cube3/state/src/redux/components/Hooks/useResourceList';
import { useAccountsUser } from '../../../WorkspaceTeamsView/hooks/useAccountsUser';
import { useMemo } from 'react';

/** Hook to retrieve all the accounts that has `Admin` role
 * @returns the accounts with injected user info (full_name,profile_picture, email_address...)
 */
export const useWorkspaceAdmins = (excludeAccounts: string | string[] = []) => {
  const [workspaceId] = useCurrentWorkspace();

  const accounts = useResourceList__ALPHA({
    resourceType: 'workspace',
    resourceId: workspaceId,
    edgeType: 'account',
    edgeLabel: 'accounts',
    strategy: 'prefer-cache'
  });
  const workspaceRoles = useResourceList__ALPHA({
    resourceType: 'workspace',
    resourceId: workspaceId,
    edgeType: 'role',
    edgeLabel: 'roles',
    strategy: 'prefer-cache'
  });

  const adminRoleId = workspaceRoles.resources?.find((r) => r.is_admin)?.id;

  const excluded = useMemo(() => {
    return accounts.resources?.filter(
      (acc) =>
        acc.relationships.role.id !== adminRoleId || // non admins
        (typeof excludeAccounts === 'string'
          ? excludeAccounts === acc.id
          : excludeAccounts.includes(acc.id))
    );
  }, [excludeAccounts, accounts.resources, adminRoleId]);

  const admins = useAccountsUser(accounts.resources, excluded);

  return admins;
};
