import * as React from 'react';
import { useState } from 'react';
import {
  countWords,
  getLongestWord,
  getWords,
  reverseObject,
  reverseString,
  TextsType,
  wordsToString
} from '../../truncateHelper';
import { fitStrings } from './fitStrings';
import { getAvailableWidth } from './getAvailableWidth';
import { generateCacheKey } from './generateCacheKey';
import { getWidthOfText } from './getWidthOfText';
import { getStyles } from './getStyles';

interface InterfaceUseTruncateProps {
  str: string;
  fontsLoaded?: boolean;
  widthLimit?: number;
  lines: number;
  suffixComponentRef?: React.RefObject<HTMLSpanElement>;
  highlightedText?: string;
  cachePrefix?: string;
}

const cachedTruncated = {};

export const useTruncateText = (props: InterfaceUseTruncateProps) => {
  const [stringsArray, setstringsArray] = React.useState<string[]>([]);
  //const stringsArray = React.useRef<string[]>([]);
  const containerRef = React.useRef<HTMLDivElement>(null);

  const {
    str,
    fontsLoaded,
    widthLimit,
    lines,
    suffixComponentRef,
    highlightedText,
    cachePrefix
  } = props;

  /** truncate a text to display on a single line */
  const truncateText = React.useCallback(
    (fullStr: string, maxWidth: number, font) => {
      const ellipsis = '…';
      const textWidth = getWidthOfText(fullStr, font);

      if (textWidth > maxWidth) {
        let frontStr = '';
        let backStr = '';
        let newStr = '';
        for (let i = 0; i < fullStr.length; i++) {
          frontStr += fullStr[i];
          backStr += fullStr[fullStr.length - i - 1];
          newStr = frontStr + ellipsis + backStr;

          if (getWidthOfText(newStr, font) > maxWidth) {
            do {
              backStr = backStr.slice(0, -1);
              newStr = frontStr + ellipsis + backStr;
            } while (
              getWidthOfText(newStr, font) > maxWidth &&
              backStr.length > 0
            );
            break;
          }
        }

        return frontStr + ellipsis + reverseString(backStr);
      }
      return fullStr;
    },
    []
  );

  /**HIGHLIGHT */
  /** truncate a text to display in the highlight */
  const truncateTextHighlight = React.useCallback(
    (fullStr: string, maxWidth: number, font, delimiters = [' ', '_', '.']) => {
      const textWidth = getWidthOfText(fullStr, font);
      /**
       * check if the highlighted text is in string
       * does the highlighted text fit the container
       * if it does we want to add more context to it
       */
      if (textWidth < maxWidth) {
        return fullStr;
      } else {
        const ellipsis = '…';

        const highlightStr = highlightedText;
        const highlightExp = new RegExp(`(${highlightStr})`, 'i');

        // prevent parts[i] is `undefined` issue
        const parts = ['', '', ''].map((p, idx) => {
          const splitted = fullStr?.split(highlightExp);
          return splitted[idx] || p;
        });

        if (!parts) {
          return '';
        }
        if (parts?.length != 3) {
          console.error('More than three parts, need to be fiexed');
        }

        if (parts[1]) {
          // highlight expression is matched

          if (getWidthOfText(parts[1], font) > maxWidth) {
            // we can ignore part 0 and 2, because even just the highlighted text won't fit
            // we should remove letters from the end of the string
            return fitStrings({
              fromEnd: parts[1],
              maxWidth,
              ellipsis,
              getWidthOfText: (text) => getWidthOfText(text, font),
              delimiters
            });
          } else {
            return fitStrings({
              fromStart: parts[0],
              fromEnd: parts[2],
              required: parts[1],
              maxWidth,
              ellipsis,
              getWidthOfText: (text) => getWidthOfText(text, font),
              delimiters
            });
          }
        } else {
          // nothing to highlight (when would this happen?)
          // we should remove letters from the end of the string
          return fitStrings({
            fromEnd: parts[0],
            maxWidth,
            ellipsis,
            getWidthOfText: (text) => getWidthOfText(text, font),
            delimiters
          });
        }
      }
    },
    [highlightedText]
  );

  /** truncate a text to display on multiple lines
   * - convert full string to an array of strings where each line fits into the container
   * - word-break: break-word
   */
  const truncateTextMultiple = React.useCallback(
    (words: string[], maxWidth: number, font) => {
      const ellipsis = '…';

      /* - truncate full string to display on multiple lines:
       * the first row: add words from start -> end
       * the remaining rows: add words from end -> start
       */
      const firstRow: string[] = [];
      /** store the remaining rows in reverse order  */
      const remainingRows: TextsType = {};

      for (let row = 0; row < lines; row++) {
        let startIndex = countWords(remainingRows);
        const newRow = [];

        // generate the first row
        if (row === 0) {
          for (let w = 0; w < words.length; w++) {
            words[w] && firstRow.push(words[w]);
            // break if the newRow width > maxWidth
            if (getWidthOfText(wordsToString(firstRow), font) > maxWidth) {
              do {
                firstRow.pop();
              } while (
                getWidthOfText(wordsToString(firstRow), font) > maxWidth
              );
              break;
            }
          }
        } else {
          // generate the remaining rows
          const restWords = words.slice(firstRow.length).reverse();
          if (restWords.length === 0) break;

          for (let w = 0; w < restWords.length - startIndex; w++) {
            const word = restWords[w + startIndex];
            word && newRow.push(word);
            if (getWidthOfText(wordsToString(newRow), font) > maxWidth) {
              do {
                newRow.pop();
                startIndex = Math.max(0, startIndex - 1);
              } while (getWidthOfText(wordsToString(newRow), font) > maxWidth);
              remainingRows[row] = newRow.reverse();
              break;
            }

            // if the loop is done and `newRow`'s width < maxWidth, should save the `newRow`
            if (w + startIndex === restWords.length - 1 && newRow.length > 0) {
              remainingRows[row] = newRow.reverse();
              break;
            }
          }
        }
      }

      const remainingRowsLength = Object.keys(remainingRows).length;
      const combined = {
        ...remainingRows,
        [remainingRowsLength + 1]: firstRow
      };
      const allRows = reverseObject(combined);

      /** covert the texts object to an array of strings
       * - add ellipsis if
       *    - it is the last row
       *    - the fullStr has been truncated or the last row width > container width
       */
      const stringsArray = Object.keys(allRows).reduce(
        (acc: string[], k: string) => {
          if (
            !!allRows[Number(k) - 1] &&
            !allRows[Number(k) + 1] &&
            (countWords(allRows) < words.length ||
              getWidthOfText(wordsToString(allRows[k]) + ellipsis, font) >
                maxWidth)
          ) {
            do {
              allRows[k] = allRows[k].slice(1);
            } while (
              getWidthOfText(wordsToString(allRows[k]) + ellipsis, font) >
              maxWidth
            );

            return [...acc, ellipsis + wordsToString(allRows[k])];
          }
          return [...acc, wordsToString(allRows[k])];
        },
        []
      );
      //console.log('all lines:', stringsArray);

      return stringsArray;
    },
    [lines]
  );

  const [, startTransition] = React.useTransition();

  // try to detect containerWidths when component rerenders
  const [containerWidth, setContainerWidth] = useState(null);

  React.useLayoutEffect(() => {
    const widthOfWidestLetter = containerRef.current
      ? getWidthOfText('W', getStyles(containerRef.current).font)
      : 0;

    const isSingleWord = str && str.split(' ').length === 1;

    const fullStr = str ? str.toString().trim() : '';
    const style = getStyles(containerRef.current);
    /** if the end-of-line space is too small for a letter, the letter wraps to the next line, this width should be in the calculation */
    const maxWidth = getAvailableWidth(
      containerRef.current,
      style.padding,
      isSingleWord ? 0 : widthOfWidestLetter,
      suffixComponentRef?.current,
      widthLimit
    );

    const longestWord = getLongestWord(fullStr);
    let truncated: string[];

    // used for search
    if (highlightedText) {
      truncated = [truncateTextHighlight(fullStr, maxWidth, style.font)];
    }
    // single line truncate
    else if (lines === 1) {
      truncated = [truncateText(fullStr, maxWidth, style.font)];
    }
    // edge case to test char by char if there are unbreakable words
    else if (getWidthOfText(longestWord, style.font) > maxWidth) {
      truncated = truncateTextMultiple(fullStr.split(''), maxWidth, style.font);
    }
    // multi line truncate
    else {
      truncated = truncateTextMultiple(getWords(fullStr), maxWidth, style.font);
    }

    setstringsArray(truncated);
  }, [containerWidth, str, fontsLoaded]);

  // observe the container's size and trigger updates when its size changes
  React.useLayoutEffect(() => {
    const element = containerRef.current;
    if (!element) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const newWidth = entry.target.clientWidth;
        startTransition(() => setContainerWidth(newWidth));
      }
    });
    startTransition(() => setContainerWidth(element.clientWidth));

    resizeObserver.observe(element);
    return () => {
      resizeObserver.unobserve(element);
      resizeObserver.disconnect();
    };
  }, []);

  const cacheKey = generateCacheKey(`${cachePrefix}-${containerWidth}`, str);
  React.useEffect(() => {
    // store last truncated value before unmount
    return () => {
      if (str && stringsArray.length) {
        cachedTruncated[cacheKey] = stringsArray;
      }
    };
  }, [stringsArray]);

  return {
    containerRef,
    stringsArray:
      !stringsArray.length && cachedTruncated[cacheKey]
        ? cachedTruncated[cacheKey]
        : stringsArray
  };
};

export default useTruncateText;
